export function pageChanged() {}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}
export function mathchIdEncode(userMatchId = "") {
  var fantasyMatchCodeStr = userMatchId;
  //  +'-FantasyMatchCode';
  return btoa(fantasyMatchCodeStr);
}

export function mathchIdDecode(userMatchId = "") {
  var fantasyMatchCodeStr = atob(userMatchId);
  return fantasyMatchCodeStr;
  //  .replace("-FantasyMatchCode","");
}

export function encode(id = "") {
  var idCodeStr = id + "CRICKETINC";
  return btoa(idCodeStr);
}

export function decode(id = "") {
  var idCodeStr = atob(id);
  return idCodeStr.replace("CRICKETINC", "");
}

/***
 * Function to format the fantasy match name fetched from the server
 * to required format.
 * @parameters:
 * prevString: Fantasy match name eg. RCB_GL_09AUG
 */
export function changeString(prevString) {
  prevString = prevString.toLowerCase();
  let reg = prevString.match(/[a-z]{2,}|[0-9]+/g);
  if (!reg.includes("vs")) {
    reg.splice(1, 0, "vs");
  }
  reg.splice(3, 0, "-");
  // console.log('patterns joined together : ',reg.join(' '))
  return reg.join(" ");
}

/***
 * function which will return the duration after which a
 * particular match will start
 *
 * @parameters:
 * oldDate: UTC DateTime referring to the date on which match will begin.
 *
 */
export function changeDate(oldDate, newDate = null) {
  let diff = getDifferenceDate(oldDate, newDate);
  let seconds = Math.floor((diff / 1000) % 60);
  let minutes = Math.floor((diff / 1000 / 60) % 60);
  let hours = Math.floor(diff / 1000 / 3600);
  // console.log(hours+'h '+minutes+'m '+seconds+'s ');
  return hours + ":" + minutes + ":" + seconds;
}

export function getDifferenceDate(oldDate, newDate = null) {
  if (newDate == null) {
    newDate = new Date();
  } else {
    newDate = new Date(newDate);
  }
  let editDate = new Date(oldDate);
  let diff = editDate.getTime() - newDate.getTime();
  return diff;
}
export function getDate(DateStr) {
  var newDate = new Date(DateStr);
  return (
    newDate.getDate() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getFullYear()
  );
}

export function getTime(DateStr) {
  var newDate = new Date(DateStr);
  return (
    newDate.getHours() + ":" + newDate.getMinutes() + ":" + newDate.getSeconds()
  );
}

export function getDayMonth(DateStr) {
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  var newDate = new Date(DateStr);
  return (
    newDate.getDate() +
    " " +
    month[newDate.getMonth()] +
    " " +
    newDate.getFullYear()
  );
}

export function getDateByFormate(DateStr, formate = "") {
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  var newDate = new Date(DateStr);
  if (formate == "dateTime") {
    //23 July 2020 12:30PM
    if (newDate.getMinutes() < 10) var Minutes = "0" + newDate.getMinutes();
    else var Minutes = newDate.getMinutes();
    if (newDate.getHours() < 12) {
      if (newDate.getHours() < 10) var Hours = "0" + newDate.getHours();
      else Hours = "0" + newDate.getHours();
      var format12H = "AM";
    } else {
      var Hours = newDate.getHours();
      Hours = Hours - 12;
      var format12H = "PM";
    }

    var time = Hours + ":" + Minutes + format12H;
    return (
      newDate.getDate() +
      " " +
      month[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      time
    );
  } else if (formate == "MM YY") {
    return month[newDate.getMonth() + 1] + " " + newDate.getFullYear();
  } else if (formate === "curent_datetime") {
    var newDate = new Date();
    var time = Hours + ":" + Minutes + format12H;
    return (
      newDate.getDate() +
      " " +
      month[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      time
    );
  } else {
    return (
      newDate.getDate() +
      " " +
      month[newDate.getMonth()] +
      " " +
      newDate.getFullYear()
    );
  }
}

export function getDateTime(DateStr) {
  var newDate = new Date(DateStr);

  var timeStr =
    newDate.getHours() +
    ":" +
    newDate.getMinutes() +
    ":" +
    newDate.getSeconds();

  return (
    newDate.getDate() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getFullYear() +
    " " +
    timeStr
  );
}

export function getCurrentDateTimeStr() {
  var newDate = new Date();

  var timeStr =
    newDate.getHours() + "" + newDate.getMinutes() + "" + newDate.getSeconds();

  return (
    newDate.getDate() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getFullYear() +
    "-" +
    timeStr
  );
}
// export function getDateString(oldDate){
//     var months =

//     return `${new Date(oldDate).getDate()} ${months[new Date(oldDate).getMonth()]}`;
// }
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export function getDateTimeYMD(DateStr) {
  var newDate = new Date(DateStr);

  var timeStr = newDate.getHours() + ":" + newDate.getMinutes();

  return (
    newDate.getFullYear() +
    "-" +
    (newDate.getMonth() + 1) +
    "-" +
    newDate.getDate() +
    " " +
    timeStr
  );
}
