import { ENV, SERVICE } from "../config/app";

import {
  get as getAxios,
  post as postAxios,
  multipart as multipartAxios,
  deleteCall as deleteCallAxios,
} from "./apiHandler";

const endPoint = ENV + SERVICE.REPORT;

const get = (url, { data, accessToken } = {}) =>
  getAxios(url, { data, accessToken, endPoint });

const post = (url, { data, accessToken } = {}) =>
  postAxios(url, { data, accessToken, endPoint });

const multipart = (url, { data, accessToken }) =>
  multipartAxios(url, { data, accessToken, endPoint });

export default class REPORT {
  static getLeadDistributionReport(data) {
    const request = post("getLeadDistributionReport", { data });
    return request;
  }
}
