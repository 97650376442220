import ReactDOM from "react-dom";
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Button from "react-bootstrap/Button";
import $ from "jquery";
import * as Appconfig from "../helpers/config";
import * as common_helper from "../helpers/lms_common";
const Axios = require("axios");

export default class ImageCropModel extends PureComponent {
  state = {
    uploadSuccess: false,
    src: null,
    crop: {
      unit: "%",
      width: 30,
      aspect: 1 / 1,
    },
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const imgRes = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );

      //resolve({fileUrl:this.fileUrl,blobData:blob});
      // const croppedImageUrl=imgRes.fileUrl;
      //const blobData=imgRes.blobData;
      this.setState({ base64ImageStr: imgRes });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    // As Base64 string
    const base64Image = canvas.toDataURL("image/jpeg");
    return base64Image;

    /*
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {

          console.log('blob',blob);
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve({fileUrl:this.fileUrl,blobData:blob});
        },
        'image/jpeg',
        1
      );
    }); */
  }
  componentDidUpdate(prevProps) {
    if (this.props.modalId !== prevProps.modalId) {
      console.log("modalId", this.props.modalId);
      console.log("modelId prev", prevProps.modalId);

      this.setState({ src: "" });
      //this.receivedData();
    }
  }

  async handleClickSave() {
    console.log("save");
    this.receivedData(this.state.base64ImageStr);
  }

  // start code
  receivedData = async (blobData) => {
    const formData = new FormData();
    formData.append("dataFile", blobData);
    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
    }
    let self = this;
    Axios({
      method: "post",
      url: Appconfig.api_base_url + "profile/updateprofileimg",
      headers: {
        Authorization: "Bearer " + token,
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        file: formData,
        blobData: blobData,
      },
    })
      .then((response) => {
        self.setState({ src: "", uploadSuccess: true });
        if (response.status === 1) {
          self.setState({ profile_image: response.profile_image });
        } else {
          self.setState({ apiError: response.message });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // end code

  render() {
    const { crop, croppedImageUrl, src } = this.state;
    return (
      <Modal show={this.props.modalIsOpen} onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update profile Image</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="App">
            <div>
              {this.state.uploadSuccess === true ? (
                <p>Profile image has been updated successfully. </p>
              ) : (
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.onSelectFile}
                />
              )}
            </div>
            {src && (
              <ReactCrop
                src={src ? src : ""}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
            {croppedImageUrl && (
              <img
                alt="Crop"
                style={{ maxWidth: "100%" }}
                src={croppedImageUrl}
              />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.closeModal}>
            Close
          </Button>
          {this.state.uploadSuccess === false ? (
            <Button
              variant="primary"
              type="submit"
              onClick={this.handleClickSave.bind(this)}
            >
              Save
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
