import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerformerListItem from "../dashboard/PerformerListItem";

class TopPerformer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topPerformer: this.props.topPerformer,
      PerformerlistArray: "",
      apiError: false,
      error: null,
    };
  }
  render() {
    return (
      <div className="col-lg-6 mb-4">
        <div className="card h-100 hover-shadow p-4">
          <h2 className="mb-4 h4">Top 5 Performer</h2>
          <div className="performer-head d-flex border-bottom pb-2 mb-1 bold">
            <div className="col col-author pl-0">Name</div>
            <div className="col col-progress">Converted Leads</div>
            <div className="col col-number text-right pr-0">Assigned</div>
          </div>

          <div className="top-performer performer-list text-primary-dark">
            {this.state.topPerformer
              ? this.state.topPerformer.map((PerformerCard, i) => {
                  return (
                    <div>
                      {parseInt(this.state.topPerformer[i].total_lead) > 0 ? (
                        <PerformerListItem
                          key={i}
                          id={this.state.topPerformer[i].name}
                          name={this.state.topPerformer[i].full_name}
                          converted_leads={(
                            (this.state.topPerformer[i].completed_lead * 100) /
                            this.state.topPerformer[i].total_lead
                          ).toFixed(2)}
                          assigned_leads={this.state.topPerformer[i].total_lead}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    );
  }
}

export default TopPerformer;
