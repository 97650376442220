import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";

export default class LeadProcessStageEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateId: parseInt(props.match.params.id),
      errors: { lead_process_stage_name: "", status: "" },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      apiError: false,
      redirectToList: false,
      is_loader: true,
    };

    this.stateInput = React.createRef();
    this.statusInput = React.createRef();

    this.handleStateNameChange = this.handleStateNameChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    let self = this;
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(
        Appconfig.api_base_url +
          "lead_process_stage/edit/" +
          this.state.stateId,
        {
          method: "GET",
          headers: { Authorization: "Bearer " + token },
        }
      )
        .then((res) => res.json())
        .then(
          async (result) => {
            console.log("result", result);
            if (result.status === 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 403) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 1) {
              this.setState(
                {
                  lead_process_stage_name: result.data.lead_process_stage_name,
                  status: result.data.status,
                },
                () => {}
              );
            }
            this.setState({ is_loader: false });
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }
  handleStateNameChange(event) {
    this.setState({ lead_process_stage_name: event.target.value });
  }
  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    if (!this.state.lead_process_stage_name.trim()) {
      formIsValid = false;
      errors["lead_process_stage_name"] = "The stage name field is required.";
      this.fnameInput.current.focus();
    }

    if (this.state.status === "") {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var userInfo = localStorage.getItem("lmsuser");
      userInfo = JSON.parse(userInfo);
      var token = "";
      if (userInfo != "undefined" && userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
      if (token) {
        var token = userInfo.token;
        var settings = {
          url:
            Appconfig.api_base_url +
            "lead_process_stage/edit/" +
            this.state.stateId,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            lead_process_stage_name: this.state.lead_process_stage_name.trim(),
            status: this.state.status,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          //localStorage.setItem("lmsuser", JSON.stringify(response.token));
          console.log("response", response);
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been updated successfully");
            self.setState({ redirectToList: true });
            //window.location.href=Appconfig.site_url+'/lead_process_stage';
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/lead_process_stage";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
      }
    } else {
      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.stateInput.current.focus();
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? (
          <Redirect to={"/lead_process_stage"} />
        ) : (
          ""
        )}
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/lead_process_stage" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">
              Edit Lead Stage :{parseInt(this.state.stateId)}
            </h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Lead Stage Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lead_process_stage_name"
                        maxlength="100"
                        placeholder="Enter lead stage name"
                        value={this.state.lead_process_stage_name}
                        onChange={this.handleStateNameChange}
                        ref={this.stateInput}
                      />
                      <span className="error">
                        {this.state.errors["lead_process_stage_name"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control"
                        data-size="7"
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>

                  <div className="pt-md-5 pt-4 text-center">
                    <Link
                      to="/lead_process_stage"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
