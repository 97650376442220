import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";

export default class RoleAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role_name: "",
      role_slug: "",
      selectedPermission: "",
      permissionList: "",
      errors: { role_name: "", password: "" },
      controllers: false,
      formIsValid: true,
      check: "",
    };

    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
    }
    if (token) {
      fetch(Appconfig.api_base_url + "role/add", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            var filterResult = {};
            var ActionsArr = [];
            result.data.rows.map((row, index) => {
              var ActionsObj = {};
              ActionsObj["action_id"] = row.id;
              ActionsObj["action_name"] = row.action_name;
              ActionsObj["action_label"] = row.action_label;
              ActionsObj["controller_id"] = row.admin_controller.id;
              ActionsObj["controller_name"] =
                row.admin_controller.controller_name;
              ActionsObj["controller_label"] =
                row.admin_controller.controller_label;
              if (!ActionsArr.hasOwnProperty("action" + row.controller_id)) {
                ActionsArr["action" + row.controller_id] = [];
              }
              ActionsArr["action" + row.controller_id].push(ActionsObj);
            });

            this.setState({ permissionList: ActionsArr }, () => {});
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $("#SelectAllRole").click(function () {
        $("#accordionRole input[type=checkbox]").prop(
          "checked",
          $(this).prop("checked")
        );
      });
      $("#CollapseAll").click(function () {
        if ($(this).data("closedAll")) {
          $(".collapse").collapse("show");
        } else {
          $(".collapse").collapse("hide");
        }
        $(this).data("closedAll", !$(this).data("closedAll"));
      });

      //$("#CollapseAll").data("closedAll",true);

      $("body").on("change", "input:checkbox[name=controller]", function () {
        //alert($('input:checkbox[name=controller]').is(':checked'))
        if ($("input:checkbox[name=controller]").is(":checked")) {
          $(this)
            .parent()
            .parent()
            .parent(".card")
            .find(".col")
            .each(function () {
              $(this).find("input[type=checkbox]").prop("checked", true);
            });
        } else {
          $(this)
            .parent()
            .parent()
            .parent(".card")
            .find(".col")
            .each(function () {
              $(this).find("input[type=checkbox]").prop("checked", false);
            });
        }
      });

      $("#submitButton").click(function () {
        var searchIDs = $("input[name=aid]:checked").map(function () {
          return $(this).val();
        });
        var permissionObj = {};
        var ActionsArr = [];
        Object.keys(searchIDs).map(function (keyName, keyIndex) {
          var ActionRow;
          if (keyName != "length") {
            ActionRow = searchIDs[keyName];
          }
          console.log("ActionRow", ActionRow);
          if (ActionRow) {
            if (ActionRow.toString().indexOf("-") > 0) {
              var myArr = ActionRow.split("-");
              var controller = myArr[0];
              var action = myArr[1];
              if (!permissionObj.hasOwnProperty(controller)) {
                permissionObj[controller] = [];
              }
              permissionObj[controller].push(action);
            }
          }
        });

        var userInfo = localStorage.getItem("lmsuser");
        var token = "";
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
          token = userInfo.token;
        }
        console.log("token", token);
        var role_name = $("#role_name").val();
        var formIsValid = true;
        if (!role_name) {
          $("#role_name").next(".error").text("The role field is required");
          $("#role_name").focus();
          formIsValid = false;
        }
        if (formIsValid === true) {
          var settings = {
            url: Appconfig.api_base_url + "role/add",
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
              "Content-Type": "application/x-www-form-urlencoded",
            },
            dataType: "JSON",
            data: {
              role_name: role_name,
              role_permissions: JSON.stringify(permissionObj),
            },
          };

          $.ajax(settings).done(function (response) {
            if (response.status === 1) {
              window.location.href = Appconfig.site_url + "/roles";
            }
          });
        }
      }); // end click event
    }); // end document ready funtion
  } // end didmount
  render() {
    {
      var permissionList = "";

      if (typeof this.state.permissionList === "object")
        permissionList = this.state.permissionList;
    }
    return (
      <div className="container">
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/roles" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Add New User Role</h1>
          </header>

          <form>
            <div className="row">
              <div className="col-xl-6">
                <div className="card p-md-5 p-4 mb-4">
                  <div className="form-group">
                    <label>
                      Role Name<span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      name="role_name"
                      id="role_name"
                      placeholder="Enter role name"
                      className="form-control"
                    />
                    <span className="error"></span>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <header className="card-header py-3 d-sm-flex align-items-center bg-white">
                <div className="recourd-number bold mb-sm-0 mb-3">
                  Role Permission
                </div>
                <div className="ml-auto d-flex align-items-center">
                  <div className="btn-checkbox mr-3">
                    <input type="checkbox" name="" id="SelectAllRole" />
                    <label for="SelectAllRole">Select All</label>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-primary ml-sm-0 ml-auto"
                    id="CollapseAll"
                  >
                    Expand/Collapse All
                  </button>
                </div>
              </header>

              <div className="card-body p-0">
                <div className="accordion accordion-strip" id="accordionRole">
                  {typeof permissionList === "object"
                    ? Object.keys(permissionList).map(function (
                        keyName,
                        keyIndex
                      ) {
                        return (
                          <div className="card">
                            <div
                              className="card-header d-flex align-items-center"
                              id={"heading" + keyName}
                            >
                              <div className="btn-checkbox">
                                <input
                                  type="checkbox"
                                  value="NA"
                                  name="controller"
                                  id={"controller" + keyName}
                                />
                                <label for={"controller" + keyName}>
                                  {permissionList[keyName][0].controller_label}
                                </label>
                              </div>
                              <button
                                className="collapse-btn ml-auto collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={"#collapse" + keyName}
                                aria-expanded="false"
                                aria-controls={"collapse" + keyName}
                              >
                                <i className="fa fa-angle-down"></i>
                              </button>
                            </div>
                            <div
                              id={"collapse" + keyName}
                              className="collapse"
                              aria-labelledby={"heading" + keyName}
                            >
                              <div className="card-body role-group">
                                <div className="row mt-3">
                                  {typeof permissionList[keyName] === "object"
                                    ? permissionList[keyName].map(function (
                                        row,
                                        index
                                      ) {
                                        return (
                                          <div className="col mb-3">
                                            <div className="btn-checkbox">
                                              <input
                                                type="checkbox"
                                                name="aid"
                                                value={
                                                  row.controller_name +
                                                  "-" +
                                                  row.action_name
                                                }
                                                id={"action" + row.action_id}
                                              />
                                              <label
                                                for={"action" + row.action_id}
                                              >
                                                {row.action_label}
                                              </label>
                                            </div>
                                          </div>
                                        );
                                      })
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>

                <div className="pt-md-5 pt-4 pb-md-5 pb-4 text-center">
                  <Link
                    to="/roles"
                    className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                  >
                    Cancel
                  </Link>
                  <button
                    className="btn btn-lg btn-primary pl-4 pr-4"
                    id="submitButton"
                    type="button"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
