import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";

export default class productAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      product_id: parseInt(props.match.params.id),
      parentList: {},
      documentList: {},
      customer_id: [],
      errors: { product_name: "", parent_id: "", status: "" },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      apiError: false,
      is_loader: true,
      redirectToList: false,
      showDocumentInput: true,
    };

    this.nameInput = React.createRef();
    this.parentInput = React.createRef();
    this.statusInput = React.createRef();

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleParentChange = this.handleParentChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleDocumentChange = this.handleDocumentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    var token = Appconfig.getSessionToken();
    let self = this;
    if (token) {
      fetch(Appconfig.api_base_url + "product/edit/" + this.state.product_id, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            console.log("result", result);
            if (result.status === 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 403) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("bbbbbbbbbb");
                }
              );
            } else if (result.status === 1) {
              this.setState(
                {
                  parentList: result.parentList,
                  document_id: result.documentIds,
                  documentList: result.documentList,
                  product_name: result.data.product_name,
                  parent_id: result.data.parent_id,
                  status: result.data.status,
                },
                () => {
                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 300);
                  // console.log('parentList',typeof(self.state.parentList));
                }
              );
            }
            setTimeout(function () {
              self.setState({ is_loader: false });
            }, 300);
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleNameChange(event) {
    this.setState({ product_name: event.target.value });
  }
  //handleParentChange(event)            {    this.setState({parent_id: event.target.value});}
  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }

  handleParentChange(event) {
    if (parseInt(event.target.value) > 0) {
      this.setState(
        { parent_id: event.target.value, showDocumentInput: false },
        () => {
          setTimeout(function () {
            $("#doc-id").selectpicker("refresh");
          }, 300);
        }
      );
    } else {
      this.setState(
        { parent_id: event.target.value, showDocumentInput: true },
        () => {
          setTimeout(function () {
            $("#doc-id").selectpicker("refresh");
          }, 300);
        }
      );
    }
  }

  handleDocumentChange(event) {
    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    this.setState({ document_id: value }, () => {
      console.log("document_id", this.state.document_id);
    });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    //Form validation

    if (this.state.status === "") {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }
    if (!this.state.product_name.trim()) {
      formIsValid = false;
      errors["product_name"] = "The prodcut name field is required.";
      this.nameInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (token) {
        var settings = {
          url: Appconfig.api_base_url + "product/edit/" + this.state.product_id,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            product_name: this.state.product_name.trim(),
            parent_id: this.state.parent_id ? this.state.parent_id : 0,
            document_id: this.state.document_id,
            status: this.state.status,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been updated successfully");
            self.setState({ redirectToList: true });
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 403) {
            window.location.href = Appconfig.site_url;
          }
        }); // end ajax
      } else {
        window.location.href = Appconfig.site_url;
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.nameInput.current.focus();
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/products"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/products" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Edit Product</h1>
          </header>

          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            <header className="card-header pt-3 pb-3 bg-white">
              <h4 className="m-0 bold">Basic Details</h4>
            </header>
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Select Parent <span className="text-red"></span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.parent_id}
                        onChange={this.handleParentChange}
                        ref={this.roleInput}
                      >
                        <option value="">Select Parent</option>
                        {typeof this.state.parentList[0] === "object"
                          ? this.state.parentList.map((row) => {
                              return (
                                <option value={row.id}>
                                  {row.product_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["parent_id"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Product Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="product_name"
                        placeholder="Enter product name"
                        value={this.state.product_name}
                        onChange={this.handleNameChange}
                        ref={this.nameInput}
                      />
                      <span className="error">
                        {this.state.errors["product_name"]}
                      </span>
                    </div>
                    {this.state.showDocumentInput === true ? (
                      <div className="col-md-4 form-group">
                        <label for="">
                          Select Documents <span className="text-red"></span>
                        </label>

                        <select
                          className="form-control selectpicker"
                          title="Select Documents"
                          id="doc-id"
                          data-size="7"
                          multiple
                          value={this.state.document_id}
                          onChange={this.handleDocumentChange}
                          ref={this.documentInput}
                        >
                          <option value="">Select Document</option>
                          {typeof this.state.documentList[0] === "object"
                            ? this.state.documentList.map((row) => {
                                return (
                                  <option value={row.id}>
                                    {row.document_name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>

                        <span className="error">
                          {this.state.errors["parent_id"]}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-md-4 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>

                  <div className="pt-4 text-center">
                    <Link
                      to="/products"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
