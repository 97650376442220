import React, { Component } from "react";
import * as Appconfig from "../helpers/config";
import $ from "jquery";
import Donut from "./donutChart";
import DrilldownBarChart from "./DrilldownBarChart";
import { getChartLabelText } from "./utility";
import LayeredColumnChart from "./LayeredColumn";
import PerformanceList from "../components/dashboard/PerformanceList";
import LeadsCounterNew from "../components/dashboard/LeadsCounterNew";

class DashboardNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: "",
      stagePieChart: "",
      statusPieChart: "",
      channelPieChart: "",
      campaignPieChart: "",
      rejectedPieChart: "",
      productWiseStatusBar: "",
      provienceWiseStatusBar: "",
      productWiseLeadFundedBar: "",
      channelWiseLeadFundedBar: "",
      campaignWiseLeadFundedBar: "",
      lenderWiseLeadFundedBar: "",
      topPerformer: "",
      lowPerformer: "",
      showDashboard: true,
      apiError: false,
      error: null,
    };

    this.receivedData();
  }

  receivedData() {
    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
    }

    if (token) {
      var token = token;
      const configOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      this.setState({ is_loader: true });
      fetch(Appconfig.api_base_url + "dashboardNew/view", configOptions)
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                showDashboard: result.showDashboard,
                tickets: result.tickets,
                topPerformer: result.topPerformer,
                lowPerformer: result.lowPerformer,
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      fetch(
        Appconfig.api_base_url + "dashboardNew/viewStatusBar",
        configOptions
        //body: JSON.stringify({startDate: this.state.startDate, endDate: this.state.endDate}),
      )
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                showDashboard: result.showDashboard,
                statusBarData: result.statusBarData,
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      fetch(
        Appconfig.api_base_url + "dashboardNew/viewLoginData",
        configOptions
        //body: JSON.stringify({startDate: this.state.startDate, endDate: this.state.endDate}),
      )
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                showDashboard: result.showDashboard,
                loginData: result.loginData,
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      fetch(
        Appconfig.api_base_url + "dashboardNew/viewLeadfunder",
        configOptions
      )
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                showDashboard: result.showDashboard,
                leadFunderData: result.leadFunderData,
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      fetch(Appconfig.api_base_url + "dashboardNew/viewPie", configOptions)
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                showDashboard: result.showDashboard,
                pieData: result.tickets,
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
      fetch(
        Appconfig.api_base_url + "dashboardNew/viewPerformer",
        configOptions
      )
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                showDashboard: result.showDashboard,
                performanceData: result.performanceData,
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  componentDidMount() {
    $(document).ready(function () {});
  }
  render() {
    return (
      <main className="w-100 h-100">
        {this.state.showDashboard === true ? (
          <div className="container">
            <div className="dashboard-content">
              <div className="row">
                <div className="col-xl-12">
                  {this.state.tickets ? (
                    <LeadsCounterNew
                      tickets={this.state.tickets ? this.state.tickets : ""}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.pieData ? (
                <div className="row row-gap mb-3">
                  {this.state.pieData.map((_) => {
                    let key = Object.keys(_)[0];
                    return (
                      <Donut
                        key={key}
                        chartID={"Pie__" + key}
                        title={key}
                        data={_[key]}
                        labelField={"NAME"}
                        primaryValueField={"COUNT"}
                        secondaryValueField={"active_account"}
                      />
                    );
                  })}
                </div>
              ) : (
                ""
              )}

              <div className="row row-gap mb-3">
                {this.state.statusBarData
                  ? this.state.statusBarData.map((item, index) => {
                      let dataKey = Object.keys(item)[0];
                      return (
                        <DrilldownBarChart
                          key={index}
                          data={item[dataKey]}
                          labelText={getChartLabelText(
                            item[dataKey]?.[0] || {},
                            "NAME",
                            ["NAME", "CHILDREN", "CODE", "ID", "HEADER"]
                          )}
                          title={dataKey}
                          label={"Par"}
                          chartID={"_____CHsdasdasartsss" + index}
                          colors={["#982d57"]}
                          xField={"NAME"}
                          uniqueKey={"NAME"}
                          y1Field={"TOTAL"}
                          y2Field={"TOTAL"}
                          headerField={"NAME"}
                        />
                      );
                    })
                  : ""}
              </div>
              <div className="row row-gap mb-3">
                {this.state.leadFunderData
                  ? this.state.leadFunderData.map((item, index) => {
                      let dataKey = Object.keys(item)[0];
                      return (
                        <LayeredColumnChart
                          key={index}
                          data={item[dataKey]}
                          labelText={getChartLabelText(
                            item[dataKey]?.[0] || {},
                            "NAME",
                            ["NAME", "CHILDREN", "CODE", "ID", "HEADER"]
                          )}
                          title={dataKey}
                          label={"Par"}
                          chartID={"_____asddsa" + index}
                          colors={["#982d57"]}
                          xField={"NAME"}
                          uniqueKey={"NAME"}
                          valueYField={"LEADS"}
                          valueXField={"FUNDED"}
                          headerField={"NAME"}
                        />
                      );
                    })
                  : ""}
              </div>
              <div className="row row-gap mb-3">
                {this.state.loginData
                  ? this.state.loginData.map((item, index) => {
                      let dataKey = Object.keys(item)[0];
                      return (
                        <DrilldownBarChart
                          key={index}
                          data={item[dataKey]}
                          labelText={getChartLabelText(
                            item[dataKey]?.[0] || {},
                            "NAME",
                            ["NAME", "CHILDREN", "CODE", "ID", "HEADER"]
                          )}
                          title={dataKey}
                          chartID={"_____asdssss" + index}
                          xField={"NAME"}
                          uniqueKey={"NAME"}
                          headerField={"NAME"}
                          onlyValue
                          tooltipText={`[bold]{categoryX}[/] \n[bold]Login Count[/]: {VALUE.formatNumber('#.')}`}
                        />
                      );
                    })
                  : ""}
              </div>
              {this.state.performanceData ? (
                <div className="row row-gap">
                  {this.state.performanceData.map((item) => {
                    const title = Object.keys(item)[0];
                    let type = "top";
                    if (title?.toLowerCase().indexOf("bottom") > -1) {
                      type = "bottom";
                    }

                    return (
                      <PerformanceList
                        title={title}
                        data={item[title]}
                        type={type}
                      />
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <br /> <br /> <br />
                  <h4 className="text-center">
                    You don't have permission to access this data please contact
                    system administrator
                  </h4>
                  <br /> <br /> <br /> <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    );
  }
}

export default DashboardNew;
