import { get } from "lodash";
import sanitizeError from "./sanitizeError";

export const defaultErrorMessage = "Please try again";

/**
 *
 * @param {Error} error
 */
function handleErrors(error) {
  // console.log("Error", error);
  const message =
    sanitizeError(
      error.message,
      error?.response?.data?.message || defaultErrorMessage
    ) || defaultErrorMessage;
  //   const { message } = error;
  // console.log("Message", message);
  const code = error.code || null;
  // console.log("Code", code);
  const data = null;
  // console.log("Data", data);
  const status = get(error, "status", get(error, "response.status", 701));
  const errorObject = { message, code, data, status };
  //   if (__DEV__) {
  //   }

  return Promise.reject(errorObject);
}

export const uiError = (error) => (defaultMessage) => {
  const errorCodesToPrevent = [701, 702];

  if (
    error &&
    error.status &&
    error.message &&
    !errorCodesToPrevent.includes(Number(error.status))
  ) {
    return error.message;
  }

  return defaultMessage;
};

export default handleErrors;
