function existPermission(controller, actionKey) {
  var user_details = localStorage.getItem("lmsuser");
  var lms_usersession = JSON.parse(user_details);
  if (lms_usersession.hasOwnProperty("token")) {
    var role_permissions_Str = lms_usersession.user_role.role_permissions;
    var role_permissions = JSON.parse(role_permissions_Str);
    if (
      role_permissions[controller] !== null &&
      role_permissions[controller] != undefined
    ) {
      if (role_permissions[controller].includes(actionKey)) {
        return true;
      }
    }
  }
  return false;
}
export function getButton(
  BtnName = "add",
  path = "",
  icon_img = "",
  props = "",
  Link = "",
  self = "",
  loading
) {
  const uriPath = props.location.pathname;
  const uriPathArr = uriPath.split("/");
  var controllerArr = ["countries", "cities"];
  var controller = uriPathArr[1];

  if (controllerArr.includes(controller)) {
    controller = controller.substring(0, controller.length - 3);
    controller = controller + "ys";
    console.log("controller", controller);
  }
  if (
    controller === "lead_process_status" ||
    controller === "lead_process_stage"
  ) {
    controller = controller + "s";
  }
  if (uriPathArr[2] === undefined) {
    controller = controller.substring(0, controller.length - 1);
  }

  var isPermission = existPermission(controller, BtnName);

  if (isPermission === true && BtnName === "add") {
    return (
      <Link to={path} className="btn btn-primary btn-add mr-2">
        + <span>Add New</span>
      </Link>
    );
  }

  if (isPermission === true && BtnName === "edit") {
    return (
      <Link to={path} className="btn-icon btn-edit" title="Edit">
        <img src={icon_img} alt="edit" />
      </Link>
    );
  }

  if (isPermission === true && BtnName === "view") {
    return (
      <Link to={path} className="btn-icon btn-view" title="View">
        <img src={icon_img} alt="view" />
      </Link>
    );
  }

  if (isPermission === true && BtnName === "download_csv") {
    return (
      <button
        className="btn btn-outline-primary"
        onClick={self.handleClickCsvData}
      >
        <i className="fa fa-download"></i> Download CSV
      </button>
    );
  }
  if (isPermission === true && BtnName === "upload_csv") {
    return (
      <button
        className="btn btn-outline-primary"
        disabled={loading}
        onClick={() => {
          onIconClick("upload_csv");
        }}
      >
        <input
          type="file"
          style={{ display: "none" }}
          className="form-control-file filePadding"
          id={"upload_csv"}
          name={"upload_csv"}
          disabled={loading}
          onChange={self.onFileUpload}
        />
        <i className="fa fa-upload"></i> {loading ? "Uploading" : "Upload File"}
      </button>
    );
  }

  if (isPermission === true && BtnName === "updateStatus") {
    return isPermission;
  }
}

export const onIconClick = (elementId) => {
  const input = document.getElementById(elementId);

  if (input) {
    input.click();
  }
};
