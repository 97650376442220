import React from "react";
import logo_white from "../assets/img/Akal_logo.webp";
// import logo_white from "../assets/img/logo-white.png";
import avtar_img from "../assets/img/avtar.jpg";
import { Link, NavLink } from "react-router-dom";
import * as Appconfig from "../helpers/config";
import * as usersessionHelper from "../helpers/lms_usersession";
import $ from "jquery";
import { HeaderDropdown } from "./HeaderDropdown";

class Header extends React.Component {
  constructor(props) {
    super(props);
    console.log(props, "props");
    if (props.name) var pathname = props.name.substring(1);
    this.state = {
      user: "",
      error: null,
      isLoaded: false,
      pathName: window.location.pathname,
      path: pathname,
      activeDropMenu: false,
      loggin_user: usersessionHelper.isLoggedInUser(),
    };

    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);

    var token = Appconfig.getSessionToken();

    if (token) {
      // var token = token;
      fetch(Appconfig.api_base_url + "profile/view/sessionId", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.data) {
              this.setState({ user: result.data });
              console.log("result:", result.data);
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      //window.location.href=Appconfig.site_url;
    }

    // Header Menu Create Object
    var userInfo = localStorage.getItem("lmsuser");
    token = "";
    if (this.state.loggin_user) {
      if (userInfo != "undefined" && userInfo != "") {
        userInfo = JSON.parse(userInfo);
        if (userInfo.hasOwnProperty("token")) {
          var role_permissions_Str = userInfo.user_role.role_permissions;
          var headerMenu = JSON.parse(role_permissions_Str);
          this.state["headerNavList"] = headerMenu;
          var headerMenuList = {};
          let headerReportNavList = {};
          var groupMenu = {
            // Dashboards: ["role", "dashboard"],
            Reports: ["lead_distribution_report"],
            Settings: ["user", "role", "setting"],
            Masters: [
              "city",
              "state",
              "lead_process_status",
              "lead_process_stage",
              "zipcode",
              "document",
              "lender",
              "supplier",
              "mailtemplate",
            ],
          };
          Object.keys(headerMenu).map((keyName, keyIndex) => {
            if (headerMenu[keyName].includes("list")) {
              var name = "";
              var alias = "";

              if (keyName === "state") {
                name = "Province";
                alias = "states";
              } else if (keyName === "zipcode") {
                name = "Postal Code";
                alias = "zipcodes";
              } else if (keyName === "city") {
                name = "Cities";
                alias = "cities";
              } else if (keyName === "lead_distribution_report") {
                name = "Lead Distribution";
                alias = "lead-distribution";
              } else if (
                keyName === "lead_process_status" ||
                keyName === "lead_process_stage"
              ) {
                alias = keyName;
                name = keyName[0].toUpperCase() + keyName.slice(1);
              } else {
                name = keyName + "s";
                alias = keyName + "s";
                name = name[0].toUpperCase() + name.slice(1);
              }
              name = name.replace(/_/g, " ");
              var menuRow = { name: name, alias: alias };

              if (groupMenu.Settings.includes(keyName)) {
                if (!headerMenuList.hasOwnProperty("Settings"))
                  headerMenuList["Settings"] = [];
                headerMenuList["Settings"].push(menuRow);
              } else if (groupMenu.Masters.includes(keyName)) {
                if (!headerMenuList.hasOwnProperty("Masters"))
                  headerMenuList["Masters"] = [];
                headerMenuList["Masters"].push(menuRow);
              } else if (groupMenu.Dashboards?.includes(keyName)) {
                if (!headerMenuList.hasOwnProperty("Dashboards"))
                  headerMenuList["Dashboards"] = [];
                headerMenuList["Dashboards"].push(menuRow);
              } else if (groupMenu.Reports?.includes(keyName)) {
                // console.log("@@@@menuRow", menuRow, keyName);
                // if (!headerMenuList.hasOwnProperty("Reports"))
                //   headerMenuList["Reports"] = [];
                // headerMenuList["Reports"].push(menuRow);
              } else {
                if (!headerMenuList.hasOwnProperty("mainMenu"))
                  headerMenuList["mainMenu"] = [];
                headerMenuList["mainMenu"].push(menuRow);
              }
            }
          });
          /** for creating report menu after dashboard menu */
          if (
            headerMenu["lead_distribution_report"] &&
            headerMenu["lead_distribution_report"].includes("list")
          ) {
            headerReportNavList["Reports"] = [
              {
                name: "Lead Distribution",
                alias: "lead-distribution",
              },
            ];
          }
          this.state["headerReportNavList"] = headerReportNavList;
          this.state["headerMenuList"] = headerMenuList;
        }
      }
    }
  }

  handleLogoutClick = () => {
    localStorage.setItem("lmsuser", "");
    window.location.href = Appconfig.site_url;
  };

  handleMenuClick = (evt) => {
    var path = evt.target.getAttribute("data-path");
    console.log("click path", path);
    this.setState({ path: path }, () => {
      //this.props.callback(this.state.path)
      console.log("state path", this.state.path);
    });
  };

  componentDidMount() {
    $(document).ready(function () {
      $(".nav-collapse-btn, .backdrop").click(function () {
        $("body").toggleClass("is-open");
      });

      $(".main-link").click(function () {
        $("body").removeClass("is-open");
      });
    });
  }

  render() {
    var headerNavList = "";
    let headerReportNavList = "";
    if (typeof this.state.headerMenuList === "object")
      var headerNavList = this.state.headerMenuList;
    if (typeof this.state.headerReportNavList === "object")
      headerReportNavList = this.state.headerReportNavList;
    let userData = localStorage.getItem("lmsuser");
    if (userData) {
      userData = JSON.parse(userData);
    }
    return (
      <span>
        {this.state.loggin_user === true ? (
          <header id="header">
            <div className="backdrop">&nbsp;</div>
            <button className="nav-collapse-btn">
              <span></span>
            </button>
            <div className="container h-100 d-flex align-items-center">
              <Link
                to={"/dashboard"}
                className="navbar-brand-logo"
                style={{ width: 120 }}
              >
                <img src={logo_white} alt="logo" />
              </Link>

              <div className="ml-auto d-flex justify-content-end align-items-center">
                <nav id="navbar">
                  <ul className="nav navbar">
                    {userData?.roleId == 1 || userData?.roleId == 11 ? (
                      <HeaderDropdown
                        className="nav-item dropdown"
                        key={"dashboards"}
                        title={"Dashboards"}
                        path={this.state.path === "dashboards"}
                      >
                        <NavLink
                          key={"row.alias"}
                          to={"/dashboard"}
                          className="dropdown-item main-link"
                          data-path={"dashboard"}
                          onClick={this.handleMenuClick}
                        >
                          Dashboard
                        </NavLink>
                        <NavLink
                          key={"row.alias111"}
                          to={"/dashboard/hierarchy"}
                          className="dropdown-item main-link"
                          data-path={"dashboard/hierarchy"}
                          onClick={this.handleMenuClick}
                        >
                          Lead Distribution
                        </NavLink>
                      </HeaderDropdown>
                    ) : (
                      <li className="nav-item">
                        <NavLink
                          to={"/dashboard"}
                          className={
                            this.state.path === "dashboard"
                              ? "nav-link active"
                              : "nav-link main-link"
                          }
                          data-path={"dashboard"}
                          onClick={this.handleMenuClick}
                        >
                          Dashboard
                        </NavLink>
                      </li>
                    )}
                    {typeof headerReportNavList === "object"
                      ? Object.keys(headerReportNavList).map((keyName) => {
                          return keyName != "mainMenu" ? (
                            <li className="nav-item dropdown" key={keyName}>
                              <a
                                // href="javascript:void(0);"
                                className={
                                  headerReportNavList[keyName]
                                    .map((row) => {
                                      return row.alias;
                                    })
                                    .includes(this.state.path)
                                    ? "nav-link  dropdown-toggle active"
                                    : "nav-link  dropdown-toggle"
                                }
                                data-toggle="dropdown"
                              >
                                {keyName}
                              </a>

                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="navbarDropdown"
                              >
                                {typeof headerReportNavList[keyName] ===
                                "object"
                                  ? headerReportNavList[keyName].map((row) => {
                                      return (
                                        <NavLink
                                          key={row.alias}
                                          to={"/" + row.alias}
                                          className="dropdown-item main-link"
                                          data-path={row.alias}
                                          onClick={this.handleMenuClick}
                                        >
                                          {row.name}
                                        </NavLink>
                                      );
                                    })
                                  : ""}
                              </div>
                            </li>
                          ) : (
                            ""
                          );
                        })
                      : ""}

                    {typeof headerNavList["mainMenu"] === "object"
                      ? //Object.keys(headerNavList).map((keyName, keyIndex)=>{
                        headerNavList["mainMenu"].map((row) => {
                          return (
                            <li className="nav-item" key={row.alias}>
                              <NavLink
                                to={"/" + row.alias}
                                className={
                                  this.state.path === row.alias
                                    ? "nav-link active"
                                    : "nav-link main-link"
                                }
                                data-path={row.alias}
                                onClick={this.handleMenuClick}
                              >
                                {row.name}
                              </NavLink>
                            </li>
                          );
                        })
                      : ""}

                    {typeof headerNavList === "object"
                      ? Object.keys(headerNavList).map((keyName) => {
                          return keyName != "mainMenu" ? (
                            <HeaderDropdown
                              className="nav-item dropdown"
                              key={keyName}
                              title={keyName}
                              path={headerNavList[keyName]
                                .map((row) => {
                                  return row.alias;
                                })
                                .includes(this.state.path)}
                            >
                              {typeof headerNavList[keyName] === "object"
                                ? headerNavList[keyName].map((row) => {
                                    return (
                                      <NavLink
                                        key={row.alias}
                                        to={"/" + row.alias}
                                        className="dropdown-item main-link"
                                        data-path={row.alias}
                                        onClick={this.handleMenuClick}
                                      >
                                        {row.name}
                                      </NavLink>
                                    );
                                  })
                                : ""}
                            </HeaderDropdown>
                          ) : (
                            ""
                          );
                        })
                      : ""}
                  </ul>
                </nav>

                <div className="header-avtar dropdown">
                  <a
                    // href="javascript:void(0);"
                    className="d-flex align-items-center"
                    data-toggle="dropdown"
                  >
                    <img
                      src={
                        this.state.user.profile_img
                          ? Appconfig.api_base_url +
                            "uploads/profile_images/" +
                            this.state.user.profile_img
                          : avtar_img
                      }
                      alt="avtar image"
                      className="rounded-circle avtar-img"
                    />
                    <span className="avtar-title pl-2 text-white flex-fill d-md-block d-none text-truncate">
                      <small className="d-block">Welcome</small>
                      {this.state.user.first_name}
                    </span>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <NavLink
                      to={"/profile"}
                      className="dropdown-item"
                      data-path="profile"
                      onClick={this.handleMenuClick}
                    >
                      Profile
                    </NavLink>

                    <div className="dropdown-divider mb-0"></div>
                    <NavLink
                      to={"/applyleave"}
                      className="dropdown-item"
                      data-path="applyleave"
                      onClick={this.handleMenuClick}
                    >
                      Apply Leave
                    </NavLink>
                    <div className="dropdown-divider mb-0"></div>
                    <button
                      className="dropdown-item"
                      onClick={this.handleLogoutClick}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
        ) : (
          ""
        )}
      </span>
    );
  }
}

export default Header;
