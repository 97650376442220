import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useToast = () => {
  <ToastContainer
    limit={1}
    position="top-right"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />;
  const notify = (type, message) => {
    if (type === "success") {
      toast.success(message, {
        ToastContainer,
      });
    }

    if (type === "error") {
      toast.error(message, {
        ToastContainer,
      });
    }

    if (type === "warn") {
      toast.warn(message, {
        ToastContainer,
      });
    }

    if (type === "info") {
      toast.info(message, {
        ToastContainer,
      });
    }
  };
  return { notify };
};

export default useToast;
