import { getRenderComponent } from "../../pages/utility";

const Loader = ({ loading, Component, children, ...restProps }) => {
  return loading ? (
    <div className="loader-boxed">&nbsp;</div>
  ) : (
    getRenderComponent(Component || children, restProps)
  );
};
export const LoaderWrapper = ({
  loading,
  Component,
  children,
  ...restProps
}) => {
  return loading ? (
    <div
      style={{
        width: "100%",
        height: "100%",
        minWidth: 400,
        minHeight: 400,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <div className="loader-boxed">&nbsp;</div>
    </div>
  ) : (
    getRenderComponent(Component || children, restProps)
  );
};

export const withLoader = (Component) => {
  return (props) => {
    return <Loader {...props} />;
  };
};

export default Loader;
