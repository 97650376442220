import React, { Component } from "react";
import * as Appconfig from "../helpers/config";
import $ from "jquery";
import Donut from "./donutChart";
import DrilldownBarChart from "./DrilldownBarChart";
import { getChartLabelText } from "./utility";
import LayeredColumnChart from "./LayeredColumn";
import PerformanceList from "../components/dashboard/PerformanceList";
import LeadsCounterNew from "../components/dashboard/LeadsCounterNew";
import TreeChart from "./TreeChart";

class HierarchyDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      showDashboard: true,
      apiError: false,
      error: null,
    };

    this.receivedData();
  }

  receivedData() {
    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
    }

    if (token) {
      var token = token;
      const configOptions = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      this.setState({ is_loader: true });
      fetch(
        Appconfig.api_base_url + "dashboardNew/viewDashboardData/HT",
        configOptions
      )
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              function query(data) {
                let objData = {
                  showName: "AKAL",
                  ...data,
                  children: recurse(data.children),
                };

                return objData;
              }
              function recurse(obj) {
                if (Array.isArray(obj)) {
                  return obj.map((item) => {
                    let showName = item.name
                      ?.split(" ")
                      .map((item) => {
                        return item[0];
                      })
                      .filter((x) => x)
                      .join("");
                    return {
                      showName,
                      ...item,
                      children: recurse(item.children),
                    };
                  });
                } else {
                  return obj;
                }
              }
              this.setState({
                showDashboard: result.showDashboard,
                treeData: query(result.data),
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  render() {
    return (
      <main className="w-100 h-100">
        {this.state.showDashboard === true ? (
          <div className="container">
            <div className="dashboard-content">
              <div className="row">
                <div className="col-xl-12">
                  <TreeChart
                    title={"Lead Distribution Chart"}
                    chartID={"chatt_tsad"}
                    data={this.state.treeData}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <br /> <br /> <br />
                  <h4 className="text-center">
                    You don't have permission to access this data please contact
                    system administrator
                  </h4>
                  <br /> <br /> <br /> <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    );
  }
}

export default HierarchyDashboard;
