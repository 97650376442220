import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import CardWrapper from "./CardWrapper";
import { setIndianNumberFormat } from "./utility";
import { title } from "process";

function DrilldownBarChart(props) {
  //const chart = useRef(null);
  const {
    chartID,
    xField,
    data: chartData,
    uniqueKey = "REPORT_DATE",
    title,
    onlyValue,
    tooltipText = `[bold]{categoryX} ({TOTAL.formatNumber('#.')})[/] \n[bold]Warm[/]: {WARM.formatNumber('#.')} \n[bold]Hot[/]: {HOT.formatNumber('#.')} \n[bold]Cold[/]: {COLD.formatNumber('#.')}`,
  } = props;
  const [data, setData] = useState({
    container: { 1: { data: chartData, parentName: null } },
    level: 1,
  });
  const afterFirst = useRef(false);
  const refData = useRef(chartData);
  useEffect(() => {
    afterFirst.current = true;
    if (afterFirst.current && chartData != refData.current) {
      refData.current = chartData;
      updateData(chartData);
    }
  }, [chartData, data.level]);

  const updateData = (containerData) => {
    const { level, container } = data;
    if (!container) {
      return;
    }
    let i = 1;
    let containerUpdated = {};

    let updatedData = containerData;
    while (i <= level) {
      let parentName = container[i].parentName;
      if (parentName) {
        updatedData = updatedData?.filter((item, index) => {
          if (item[uniqueKey] === parentName) {
            return true;
          }
        })[0].CHILDREN;
        containerUpdated[i] = {};
        containerUpdated[i]["data"] = updatedData;
        containerUpdated[i]["parentName"] = parentName;
      } else {
        containerUpdated[i] = {};
        containerUpdated[i]["data"] = updatedData;
        containerUpdated[i]["parentName"] = null;
      }
      i++;
    }
    setData((data) => {
      return {
        ...data,
        container: containerUpdated,
      };
    });
  };

  const series = useRef({});
  const xAxis = useRef();
  useEffect(() => {
    xAxis.current?.data.setAll(data.container?.[data.level].data || []);
    series.current[1]?.data.setAll(data.container?.[data.level].data || []);
    series.current[2]?.data.setAll(data.container?.[data.level].data || []);
    series.current[3]?.data.setAll(data.container?.[data.level].data || []);
  }, [data, xAxis.current, series.current]);
  useLayoutEffect(() => {
    if (data) {
      var root = am5.Root.new(chartID);
      if (root) {
        root._logo.dispose();
      }
      root.setThemes([am5themes_Animated.new(root)]);
      setIndianNumberFormat(root);
      // Create chart
      // https://www.amcharts.com/docs/v5/charts/percent-charts/DrilldownBarChart-chart/
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "none",
          wheelY: "none",
        })
      );
      chart.zoomOutButton.set("forceHidden", true);
      chart
        .get("colors")
        .set("colors", [
          am5.color("#0091D5"),
          am5.color("#EA6A47"),
          am5.color("#DBAE58"),
          am5.color("#488A99"),
          am5.color("#AC3E31"),
          am5.color("#1C4E80"),
        ]);

      var colors = chart.get("colors");
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
      xRenderer.labels.template.setAll({
        rotation: -45,
        centerY: am5.p50,
        centerX: am5.p100,
        paddingRight: 15,
        oversizedBehavior: "truncate",
        maxWidth: 120,
        fontSize: 10,
        paddingLeft: 15,
      });
      var yRenderer = am5xy.AxisRendererY.new(root, {});
      yRenderer.labels.template.setAll({
        fontSize: 12,
      });
      xRenderer.grid.template.set("visible", false);

      xAxis.current = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          maxDeviation: 0.3,
          categoryField: xField,
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      xAxis.current.data.setAll(data);

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          maxDeviation: 0.3,
          renderer: yRenderer,
        })
      );

      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
          centerY: am5.p50,
          y: am5.p100,
        })
      );
      function makeSeries(name, fieldName, index, stacked) {
        series.current[index] = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            stacked: stacked,
            name: name,
            xAxis: xAxis.current,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: xField,
          })
        );

        series.current[index].columns.template.adapters.add(
          "stroke",
          (stroke, target) => {
            return chart
              .get("colors")
              .getIndex(series.current[index].columns.indexOf(target));
          }
        );
        series.current[index].columns.template.set("strokeOpacity", 0);
        series.current[index].columns.template.set(
          "fillGradient",
          am5.LinearGradient.new(root, {
            stops: [
              {
                brighten: -0.2,
              },
              {
                brighten: -0.1,
              },
              {
                brighten: 0,
              },
              {
                brighten: 0.1,
              },
              {
                brighten: -0.4,
              },
            ],
          })
        );

        series.current[index].columns.template.adapters.add(
          "stroke",
          function (stroke, target) {
            return chart
              .get("colors")
              .getIndex(series.current[index].columns.indexOf(target));
          }
        );
        if (onlyValue) {
          series.current[index].columns.template.setAll({
            cornerRadiusTL: 10,
            cornerRadiusTR: 10,
          });
        }
        series.current[index].columns.template.setAll({
          tooltipText,
          width: am5.percent(90),
          tooltipY: am5.percent(10),
          text: "{valueYWorking.formatNumber('#.')}",
        });

        series.current[index].columns.template.events.on("click", function (e) {
          const update = e.target.dataItem?.dataContext;
          if (update?.CHILDREN) {
            setData(({ container, level }) => {
              return {
                container: {
                  ...container,
                  [level + 1]: {
                    data: update?.CHILDREN,
                    parentName: update?.[uniqueKey],
                  },
                },
                level: level + 1,
              };
            });
          }
        });

        series.current[index].data.setAll(data);

        series.current[index].appear();
        if (!onlyValue) {
          legend.data.push(series.current[index]);
        }
      }
      if (onlyValue) {
        makeSeries("Login Count", "VALUE", 1, false);
      } else {
        makeSeries("Cold", "COLD", 1, false);
        makeSeries("Hot", "HOT", 2, true);
        makeSeries("Warm", "WARM", 3, true);
      }
      // Get series item by category
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      // series.current.appear(1000);
      chart.appear(1000, 100);
    }
    return () => {
      root.dispose();
    };
  }, [chartID]);
  const onBackPress = () => {
    setData(({ level, ...state }) => {
      return {
        ...state,
        level: level - 1 || 1,
      };
    });
  };
  const getBackLabel = useMemo(() => {
    let i = 1;
    let backLabel = null;
    while (i <= data.level) {
      backLabel = backLabel
        ? backLabel + " >> " + data.container?.[i].parentName
        : data.container?.[i].parentName;
      i++;
    }
    return backLabel;
  }, [data]);
  return (
    <CardWrapper
      width={"48%"}
      height={"500px"}
      // title={data.container?.[data.level].data?.[0]?.[headerField]}
      title={title}
      onBackPress={onBackPress}
      backLabel={getBackLabel}
      level={data.level}
    >
      <div
        id={chartID}
        style={{
          width: "100%",
          height: 420,
        }}
      ></div>
    </CardWrapper>
  );
}
export default DrilldownBarChart;
