import { forwardRef, useState } from "react";
const TextInput = forwardRef(
  (
    {
      title,
      field,
      className = "col-xl-12",
      onChange,
      value,
      required,
      maxlength,
      error,
      placeholder,
    },
    ref
  ) => {
    return (
      <div className={className} key={field}>
        <label for={field}>
          {title}
          {required ? <span className="text-red">*</span> : ""}
        </label>
        <input
          key={field}
          type="text"
          className="form-control"
          name={field}
          maxlength={maxlength}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          ref={ref}
          autoComplete="off"
        />
        <span className="error">{error}</span>
      </div>
    );
  }
);
export default TextInput;
