export const throwError = (response) => {
  const errorObject = {
    message: response.message,
    code: response.code,
    status: response.code,
  };

  throw errorObject;
};

/**
 * 403
 *
 */

// eslint-disable-next-line consistent-return
function handleSuccess(response) {
  //   if (__DEV__) {
  //   }
  const data = response.data || {};
  return response && response.data ? response.data : response;
}

export default handleSuccess;
