import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { useMediaQuery } from "react-responsive";

const objectDetail = {
  "Expected Revenue": 160,
  "Total Funded": 80,
  "Lender Submitted": 120,
  "Lender Submitted": 120,
  "Contacted > 1 min": 120,
  "Not closed > 90 days": 130,
};

const getNumber = (number) => {
  if (number.indexOf(",") < 0) {
    return Number(number)
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else return number;
};
const LeadsCounterItem = (props) => {
  const isLaptop = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });

  let ExpectedRevenue = String(props.number);
  if (ExpectedRevenue?.replaceAll(",", "") >= 1000) {
    ExpectedRevenue = ExpectedRevenue.replaceAll(",", "");
    ExpectedRevenue =
      (Number(ExpectedRevenue) / 1000)
        .toFixed(0)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "K";
  }

  return (
    <div className="col-xl col-sm-3 col-4 mb-xl-4 mb-2">
      <a
        href={props.link}
        className="card card-counter hover-shadow h-100 text-default p-3"
      >
        <div
          className="flex-fill d-flex title align-items-center"
          style={{
            marginBottom: 4,
            display: "flex",
            justifyContent: "center",
            width:
              objectDetail[props.title] && isLaptop
                ? objectDetail[props.title]
                : "auto",
          }}
        >
          <h4
            className="m-0"
            style={{
              fontSize: 12,
              display: "flex",
              justifyContent: "center",
            }}
          >
            {props.title == "Expected Revenue"
              ? "Expected Revenue(C$)"
              : props.title}
          </h4>
          {/*<i className="fa fa-angle-right ml-auto"></i>*/}
        </div>
        {props.title == "Expected Revenue" ? (
          <div
            className="d-flex align-items-end h-100 "
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h3 className="h1 m-0 text-primary-dark">{ExpectedRevenue}</h3>
          </div>
        ) : (
          <div
            className="d-flex align-items-end h-100"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <h3 className="h1 m-0  text-primary-dark">
              {getNumber(String(props.number))}
            </h3>
            <h5 className="medium text-secondary pl-2"></h5>
          </div>
        )}
      </a>
    </div>
  );
};

export default LeadsCounterItem;
