import React, { Component } from "react";
import { Link } from "react-router-dom";
class NotFoundPage extends Component {
  render() {
    return (
      <div className="container">
        <div className="dashboard-content">
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <header className="card-header pt-4 pb-4 bg-white text-center">
                  <h1 className="h3 mb-0">Page Not Found</h1>
                  <p></p>
                  <h4 className="m-0 bold">404 Error</h4>
                </header>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-12  text-center">
                      <Link to="/" className="btn btn-info">
                        Back to Home
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
