import { useEffect, useState } from "react";
import * as Appconfig from "../helpers/config";
import $ from "jquery";
const Dropdown = ({
  data,
  valueField,
  labelField,
  title,
  requestURL,
  field,
  className = "col-xl-12",
  onChange,
  value,
  error,
  required,
  multiSelect,
}) => {
  const [selectedValue, setSelectedValue] = useState(value || "");
  useEffect(() => {
    setSelectedValue(value || "");
  }, [value]);
  const [dropdownData, setDropdownData] = useState([]);
  const getDropdownData = async (Url) => {
    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
    }
    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + Url, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(async (result) => {
          if (result.status === 1) {
            setDropdownData(result.data?.rows);
            setTimeout(function () {
              $(".selectpicker").selectpicker("refresh");
            }, 100);
          } else if (result.status === 401) {
            window.location.href = Appconfig.site_url;
          }
        });
    } else {
      window.location.href = Appconfig.site_url;
    }
  };

  useEffect(() => {
    if (requestURL) {
      getDropdownData(requestURL);
    } else if (data) {
      setDropdownData(data);
      setTimeout(function () {
        $(".selectpicker").selectpicker("refresh");
      }, 100);
    }
  }, [data, requestURL]);
  return (
    <div className={className}>
      <div className="filter-item mb-2">
        <label className="text-muted" for={field}>
          {title} {required && <span className="text-red">*</span>}
        </label>
        <select
          defaultValue={selectedValue}
          className="form-control selectpicker"
          name={field}
          id={field}
          multiple={multiSelect}
          onChange={(e) => {
            if (multiSelect) {
              let value = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );
              setSelectedValue(value);
              onChange && onChange(value, field);
            } else {
              setSelectedValue(e.target.value);
              onChange && onChange(e.target.value, field);
            }
          }}
          value={selectedValue}
        >
          <option value="">{title}</option>
          {typeof dropdownData === "object"
            ? dropdownData.map((value) => {
                const isSelected = multiSelect
                  ? selectedValue.indexOf(value[valueField]) > -1
                  : value[valueField] === selectedValue;
                return (
                  <option value={value[valueField]} selected={isSelected}>
                    {value[labelField]}
                  </option>
                );
              })
            : ""}
        </select>
        <span className="error">{error}</span>
      </div>
    </div>
  );
};
export default Dropdown;
