import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5hierarchy from "@amcharts/amcharts5/hierarchy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import CardWrapper from "./CardWrapper";

function TreeChart(props) {
  const { chartID, title, data = [] } = props;
  useLayoutEffect(() => {
    var root = am5.Root.new(chartID);
    if (root) {
      root._logo.dispose();
    }
    if (data) {
      am5.ready(function () {
        root.setThemes([am5themes_Animated.new(root)]);
        var container = root.container.children.push(
          am5.Container.new(root, {
            width: am5.percent(100),
            height: am5.percent(100),
            layout: root.verticalLayout,
          })
        );

        var series = container.children.push(
          am5hierarchy.Tree.new(root, {
            singleBranchOnly: false,
            downDepth: 1,
            initialDepth: 5,
            topDepth: 0,
            valueField: "value",
            categoryField: "name",
            childDataField: "children",
            paddingBottom: 40,
            paddingTop: 60,
          })
        );

        // Disable circles
        series.circles.template.setAll({
          radius: 20,
          // forceHidden: true,
        });

        series.outerCircles.template.setAll({
          radius: 20,
          // forceHidden: true,
        });

        // ... except for central node
        // series.circles.template.adapters.add(
        //   "forceHidden",
        //   function (forceHidden, target) {
        //     return target.dataItem.get("depth") == 0 ? false : forceHidden;
        //   }
        // );

        // Set up labels
        series.labels.template.setAll({
          fill: am5.color(0xffffff),
          y: 0,
          text: "{showName}",
          oversizedBehavior: "truncate",
          fontSize: 12,
        });

        // Use adapter to leave central node label centered
        // series.labels.template.adapters.add("y", function (y, target) {
        //   return target.dataItem.get("depth") == 0 ? 0 : y;
        // });

        series.data.setAll([data]);
        series.set("selectedDataItem", series.dataItems[0]);
        // Make stuff animate on load
        series.appear(1000, 100);
      });
    }
    return () => {
      root.dispose();
    };
  }, [chartID, data]);
  return (
    <CardWrapper width={"48%"} height={"80vh"} title={title}>
      <div
        id={chartID}
        style={{
          width: "100%",
          height: "100%",
        }}
      ></div>
    </CardWrapper>
  );
}
export default TreeChart;
