import React, { Component } from "react";
// import logo from "../assets/img/logo.png";
import logo from "../assets/img/Akal_logo.webp";

import IntroCarousel from "../components/IntroCarousel";
import { Link } from "react-router-dom";
import $ from "jquery";

import request from "request";
import * as Appconfig from "../helpers/config";
import * as usersessionHelper from "../helpers/lms_usersession";
import Loader from "../components/loader";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: { username: "", password: "" },
      formIsValid: true,
      apiError: "",
      loggin_user: usersessionHelper.isLoggedInUser(),
      loading: false,
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    if (usersessionHelper.isLoggedInUser() === true) {
      window.location.href = Appconfig.site_url + "/dashboard";
    }
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    try {
      let login_type = "M";
      if (window.innerWidth > 1000) {
        login_type = "W";
      }
      // Boolean(isAndroid() || isIos() || isOpera() || isWindows())
      var errors = {};
      var formIsValid = true;
      //username
      this.setState({ loading: true });
      if (!this.state.username) {
        formIsValid = false;
        errors["username"] = "Username is required.";
      }
      //Password
      if (!this.state.password) {
        formIsValid = false;
        errors["password"] = "Password is required.";
      }
      this.setState({ errors: errors });
      if (formIsValid === true) {
        var settings = {
          url: Appconfig.api_base_url + "login",
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          dataType: "JSON",
          data: {
            //"email": "kirar84@gmail.com","password": "12345"
            login_type,
            email: this.state.username,
            password: this.state.password,
          },
        };
        let self = this;
        $.ajax(settings)
          .done(function (response) {
            if (response.status === 1) {
              localStorage.setItem(
                "lmsuser",
                JSON.stringify(response.userdata)
              );
              console.log("lmsuser", localStorage.getItem("lmsuser"));
              window.location.href = Appconfig.site_url + "/dashboard";
            } else {
              self.setState({ apiError: response.message });
            }
            self.setState({ loading: false });
          })
          .catch((err) => {
            self.setState({ apiError: "Something went wrong" });
            self.setState({ loading: false });
          });
      }
    } catch (e) {
      console.log("@@@@@@ERROR:::", e);
    }
  }

  componentDidMount() {
    $(document).ready(function () {});
  }
  render() {
    return (
      <div className="d-lg-flex flex-row-reverse login-wrapper">
        <div className="hp-banner bg-secondary col-xl-7 col-lg-6 p-0 d-md-flex align-items-center">
          <div className="w-100 text-center text-white">
            <IntroCarousel />
          </div>
        </div>
        <div className="col-xl-5 col-lg-6 p-lg-0 col-form bg-white">
          <div className="row align-items-stretch h-100">
            <div className="col-12 pt-4 pb-4">
              <div className="brand-logo">
                <Link to={"/"}>
                  <img className="login-logo" src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            <div className="col-12">
              <form onSubmit={this.handleSubmit}>
                <div className="login-form">
                  <header className="mb-4">
                    {this.state.apiError ? (
                      <div className="alert alert-danger">
                        <strong>Error:</strong> {this.state.apiError}
                      </div>
                    ) : (
                      ""
                    )}
                    <h1 className="bold h2 text-black">Login</h1>
                    <p className="text-muted">
                      Welcome to get back to LMS Solutions, <br />
                      please enter your login details.
                    </p>
                  </header>
                  <div className="form-group mb-3 input-user">
                    <label for="InputUser">Username</label>
                    <input
                      type="text"
                      placeholder="User Name"
                      className="form-control"
                      value={this.state.username}
                      onChange={this.handleUsernameChange}
                    />
                    <span className="error">
                      {this.state.errors["username"]}
                    </span>
                  </div>
                  <div className="form-group mb-3 input-pass">
                    <label for="InputPass">Password</label>
                    <input
                      type="password"
                      placeholder="Password"
                      className="form-control"
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                    />
                    <span className="error">
                      {this.state.errors["password"]}
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="btn-checkbox">
                      <input type="checkbox" id="signedIn" name="" value="" />
                      <label for="signedIn">Keep me signed in</label>
                    </div>
                    <Link
                      to="/forgot-password"
                      className="text-primary fgt-pass ml-auto"
                    >
                      <u>Forgot Password?</u>
                    </Link>
                  </div>
                  <div className="pt-5">
                    <button
                      type="submit"
                      className="btn btn-lg btn-primary btn-block"
                      disabled={this.state.loading}
                    >
                      <Loader
                        loading={this.state.loading}
                        Component={<p>Log in</p>}
                      />
                      {/* {this.state.loading ? (
                      ) : (
                        <p>Log in</p>
                      )} */}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-12 text-muted text-center d-flex align-items-end mt-4">
              <div className="w-100 pb-3 pt-3 border-top">
                &copy;2020-21 LMS Solution powered by F4Tek Inc. All Rights
                Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
