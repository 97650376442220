import React, { Component } from "react";
import { Link } from "react-router-dom";
import LeadsCounterItem from "../dashboard/LeadsCounterItem";

class LeadsCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: this.props.tickets,
      LeadsArray: [],
      apiError: false,
      error: null,
    };
    var ticketData = this.props.tickets;
    console.log("ticketData", ticketData);
    const LeadsArray = [
      {
        id: 1,
        title: "Total Allocated",
        number: ticketData.AllLeads,
        link: "/leads",
      },
      {
        id: 2,
        title: "Total Rejected",
        number: ticketData.rejectedLeads,
        link: "/leads?stage=7",
      },
      {
        id: 3,
        title: "Total Funded",
        number: ticketData.FundedLeads,
        link: "/leads?stage=8",
      },
      {
        id: 4,
        title: "Expected Revenue",
        number: ticketData.total_revenue,
        link: "/leads?stage=8",
      },

      {
        id: 5,
        title: "Lender Submitted",
        number: ticketData.lenderSubmission,
        link: "/leads?stage=6",
      },

      {
        id: 6,
        title: "Total Open",
        number: ticketData.openLeads,
        link: "/leads?lead=openLeads",
      },

      {
        id: 7,
        title: "New Allocated",
        number: ticketData.newLeads,
        link: "/leads?stage=1",
      },
      //{id : 6,
      //title : "Contacted < 1min",
      //number : ticketData.leadContactedIn1Minuts
      //},
      {
        id: 8,
        title: "Contacted > 1 min",
        number: ticketData.leadContactedBefore1Minuts,
        link: "/leads",
      },
      {
        id: 9,
        title: "Not closed > 90 days",
        number: ticketData.notClosedLead,
        link: "/leads?lead=leadNotClosed",
      },
    ];

    this.state["LeadsArray"] = LeadsArray;
  }

  render() {
    //~ const LeadslistArray = LeadsArray.map( (LeadsCard, i) => {
    //~ return <LeadsCounterItem key={i} id={LeadsArray[i].title}
    //~ title={LeadsArray[i].title}
    //~ number={LeadsArray[i].number}/>
    //~ })

    return (
      <div className="row row-gap h-100">
        {this.state.LeadsArray
          ? this.state.LeadsArray.map((LeadsCard, i) => {
              return (
                <LeadsCounterItem
                  key={i}
                  id={this.state.LeadsArray[i].title}
                  title={this.state.LeadsArray[i].title}
                  link={this.state.LeadsArray[i].link}
                  number={this.state.LeadsArray[i].number}
                />
              );
            })
          : ""}
      </div>
    );
  }
}

export default LeadsCounter;
