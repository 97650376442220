import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

//chart type
import CardWrapper from "./CardWrapper";

function LayeredColumnChart(props) {
  //const chart = useRef(null);
  const {
    chartID,
    xField,
    y1Field,
    y2Field,
    data: chartData = [],
    uniqueKey = "REPORT_DATE",
    height,
    labelText,
    valueYField = "DUE_AMOUNT",
    valueXField = "COLLECTION_AMOUNT",
    categoryXField = "NAME",
    headerField,
    title,
  } = props;
  const [data, setData] = useState({
    container: { 1: { data: chartData, parentName: null } },
    level: 1,
  });
  const afterFirst = useRef(false);
  const refData = useRef(chartData);
  useEffect(() => {
    afterFirst.current = true;
    if (afterFirst.current && chartData != refData.current) {
      refData.current = chartData;
      updateData(chartData);
    }
  }, [chartData, data.level]);

  const series0 = useRef();
  const series1 = useRef();
  const xAxis = useRef();
  const yAxis = useRef();
  const yToolTip = useRef(labelText);

  useEffect(() => {
    xAxis.current?.data.setAll(data.container?.[data.level]?.data || []);
    yAxis.current?.data.setAll(data.container?.[data.level]?.data || []);
    series0.current?.data.setAll(data.container?.[data.level]?.data || []);
    series1.current?.data.setAll(data.container?.[data.level]?.data || []);
    yToolTip.current?.setAll({
      labelText: labelText,
    });
  }, [data, xAxis.current, series0.current, series1.current, labelText]);

  useEffect(() => {});
  const updateData = (containerData) => {
    const { level, container } = data;
    let i = 1;
    let containerUpdated = {};

    let updatedData = containerData;
    while (i <= level) {
      let parentName = container[i].parentName;
      if (parentName) {
        updatedData = updatedData?.filter((item, index) => {
          if (item[uniqueKey] === parentName) {
            return true;
          }
        })[0].CHILDREN;
        containerUpdated[i] = {};
        containerUpdated[i]["data"] = updatedData;
        containerUpdated[i]["parentName"] = parentName;
      } else {
        containerUpdated[i] = {};
        containerUpdated[i]["data"] = updatedData;
        containerUpdated[i]["parentName"] = null;
      }
      i++;
    }
    setData((data) => {
      return {
        ...data,
        container: containerUpdated,
      };
    });
  };

  useLayoutEffect(() => {
    //var root = am5.Root.new("chartdiv2");
    var root = am5.Root.new(chartID);
    if (root) {
      root._logo.dispose();
    }

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);
    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/LineChart-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
      })
    );
    chart
      .get("colors")
      .set("colors", [am5.color("#02578f"), am5.color("#e0e1e8")]);

    var yRenderer = am5xy.AxisRendererY.new(root, {});
    yRenderer.labels.template.setAll({
      fontSize: 12,
    });
    // yRenderer.grid.template.set("visible", false);
    var xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
    xRenderer.labels.template.setAll({
      rotation: -45,
      centerY: am5.p50,
      centerX: am5.p100,
      paddingRight: 15,
      oversizedBehavior: "truncate",
      maxWidth: 80,
      // paddingLeft: 15,
      fontSize: 10,
    });
    xRenderer.grid.template.set("visible", false);
    chart.zoomOutButton.set("forceHidden", true);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    xAxis.current = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "NAME",
        renderer: xRenderer,
        // tooltip: am5.Tooltip.new(root, {
        //   themeTags: ["axis"],
        //   animationDuration: 200,
        // }),
      })
    );
    // const { data } = props;
    // xAxis.current.data.setAll(data);
    yToolTip.current = am5.Tooltip.new(root, {
      labelText: labelText,
      // draggable: true,
      // height: "auto",

      // opacity: 1,
      // getFillFromSprite: false,
      // getStrokeFromSprite: true,
      // getLabelFillFromSprite: true,
      // autoTextColor: false,
      // centerY: 35,
      // scrollY: true,
    });

    // yToolTip.current.get("background").setAll({ fill: am5.color("#fff") });

    yAxis.current = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: yRenderer,
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    series0.current = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Income",
        xAxis: xAxis.current,
        yAxis: yAxis.current,
        valueYField,
        valueXField,
        categoryXField: "NAME",
        clustered: false,
        tooltip: yToolTip.current,
      })
    );
    series0.current.columns.template.setAll({
      width: am5.percent(50),
    });

    // series0.current.data.setAll(data);

    // "fill", [
    //   am5.color(0x095256),
    //   am5.color(0x5aaa95),
    //   am5.color(0x86a873),
    //   am5.color(0xbb9f06),
    //   am5.color(0x084f8c),
    // ]);

    // xToolTip.updateBackgroundColor()
    series1.current = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Income",
        xAxis: xAxis.current,
        yAxis: yAxis.current,
        valueXField: valueYField,
        valueYField: valueXField,
        categoryXField,
        clustered: false,
        // tooltip: xToolTip,
      })
    );

    series1.current.columns.template.setAll({
      width: am5.percent(30),
      // tooltipY: 0,
    });

    series0.current.columns.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
    });
    series1.current.columns.template.setAll({
      cornerRadiusTL: 10,
      cornerRadiusTR: 10,
    });
    // series1.current.data.setAll(data);
    // xRenderer.grid.template.set("visible", false);

    var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
    series1.current.columns.template.events.on("click", function (e) {
      // handleHover(e.target.dataItem);
      const update = e.target.dataItem?.dataContext;
      if (update?.CHILDREN) {
        setData(({ container, level }) => {
          return {
            container: {
              ...container,
              [level + 1]: {
                data: update?.CHILDREN,
                parentName: update?.[uniqueKey],
              },
            },
            level: level + 1,
          };
        });
      }
      // updateData();
    });
    let c = "G";
    series0.current.columns.template.adapters.add(
      "fill",
      function (fill, target) {
        let color = target.dataItem?.dataContext?.COLOR_IDENTITY || c;
        if (color === "G") {
          return am5.color("#069A8E");
        } else if (color === "O") {
          return am5.color("#FFBC80");
        } else if (color === "R") {
          return am5.color("#FF6363");
        }
        return fill;
      }
    );
    series1.current.columns.template.adapters.add(
      "fill",
      function (fill, target) {
        let color = target.dataItem?.dataContext?.COLOR_IDENTITY || c;
        if (color === "G") {
          return am5.color("#E4E9BE");
        } else if (color === "O") {
          return am5.color("#E0D8B0");
        } else if (color === "R") {
          return am5.color("#FAD4D4");
        }
        return fill;
      }
    );

    series0.current.columns.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            brighten: -0.6,
          },
          {
            brighten: -0.4,
          },
          {
            brighten: -0.2,
          },
          {
            brighten: 0,
          },
          {
            brighten: -0.4,
          },
        ],
      })
    );
    series1.current.columns.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            brighten: -0.6,
          },
          {
            brighten: -0.4,
          },
          {
            brighten: -0.2,
          },
          {
            brighten: 0,
          },
          {
            brighten: -0.4,
          },
        ],
      })
    );
    series0.current.columns.template.events.on("click", function (e) {
      // handleHover(e.target.dataItem);
      const update = e.target.dataItem?.dataContext;
      if (update?.CHILDREN) {
        setData(({ container, level }) => {
          return {
            container: {
              ...container,
              [level + 1]: {
                data: update?.CHILDREN,
                parentName: update?.[uniqueKey],
              },
            },
            level: level + 1,
          };
        });
      }
      // updateData();
    });

    series0.current.columns.template.setAll({
      tooltipY: am5.percent(10),
    });
    series1.current.columns.template.setAll({
      tooltipY: am5.percent(10),
    });
    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);
    series0.current.appear();
    series1.current.appear();

    return () => {
      root.dispose();
    };
  }, [chartID]);
  const getBackLabel = useMemo(() => {
    let i = 1;
    let backLabel = null;
    while (i <= data.level) {
      backLabel = backLabel
        ? backLabel + " >> " + data.container?.[i]?.parentName
        : data.container?.[i]?.parentName;
      i++;
    }
    return backLabel;
  }, [data]);
  const onBackPress = () => {
    setData(({ level, ...state }) => {
      return {
        ...state,
        level: level - 1 || 1,
      };
    });
  };
  return (
    <CardWrapper
      width={"48%"}
      // title={data?.container?.[data.level]?.data?.[0]?.[headerField]}
      title={title}
      height={"500px"}
      onBackPress={onBackPress}
      backLabel={getBackLabel}
      level={data.level}
    >
      <div
        id={chartID}
        style={{
          width: "100%",
          height: 450,
          maxHeight: 450,
        }}
      ></div>
    </CardWrapper>
  );
}
export default LayeredColumnChart;
