import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import edit_icon from "../../assets/img/edit-icon.svg";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: [],
      country_name: "",
      lead_assign_method_is: "",
      totalUsers: 0,
      isLoaded: true,
      is_loader: true,
      token: "",
      flashMessage: Appconfig.getFlashMessage(),
    };

    var token = Appconfig.getSessionToken();

    if (token) {
      fetch(Appconfig.api_base_url + "setting", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.data) {
              setTimeout(() => {
                this.setState({ flashMessage: "" });
                localStorage.setItem("successMsg", "");
              }, 2000);

              let lead_assign_method_iss = "";
              if (result.data.lead_assign_method === 1) {
                lead_assign_method_iss = "Round Robin";
              } else if (result.data.lead_assign_method === 2) {
                lead_assign_method_iss = "Priority";
              }

              console.log("pr", lead_assign_method_iss);
              this.setState({
                setting: result.data,
                lead_assign_method_is: lead_assign_method_iss,
                country_name: result.country_name,
                is_loader: false,
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      //window.location.href=Appconfig.site_url;
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <h1 className="h3 mb-0">Setting</h1>
          </header>
          {this.state.flashMessage ? this.state.flashMessage : ""}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <header className="card-header d-flex align-items-center py-3 bg-white">
                  <h4 className="m-0 bold">Website</h4>
                  <Link
                    to={"/setting/edit"}
                    className="btn-icon btn-view ml-auto"
                    title="Update"
                  >
                    <img src={edit_icon} alt="Update" />
                  </Link>
                </header>

                <div className="card-body">
                  <div className="row">
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Name</label>
                      <h5 className="m-0">
                        {this.state.setting.name ? this.state.setting.name : ""}
                      </h5>
                    </div>

                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Email</label>
                      <h5 className="m-0">
                        {this.state.setting.email
                          ? this.state.setting.email
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Contact No</label>
                      <h5 className="m-0">
                        {this.state.setting.mobile
                          ? this.state.setting.mobile
                          : ""}
                      </h5>
                    </div>

                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Lead Assign Method</label>
                      <h5 className="m-0">
                        {this.state.lead_assign_method_is
                          ? this.state.lead_assign_method_is
                          : ""}
                      </h5>
                    </div>

                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Lead Priority</label>
                      <h5 className="m-0">
                        {this.state.setting.lead_priority
                          ? this.state.setting.lead_priority
                          : ""}
                      </h5>
                    </div>

                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Default Country</label>
                      <h5 className="m-0">
                        {this.state.country_name ? this.state.country_name : ""}
                      </h5>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
