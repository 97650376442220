import React, { createContext } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "bootstrap-select/dist/js/bootstrap-select.min.js";
import "bootstrap-select/dist/css/bootstrap-select.min.css";

import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js";

import "bootstrap-datetime-picker/css/bootstrap-datetimepicker.min.css";
import "bootstrap-datetime-picker/js/bootstrap-datetimepicker.min.js";

import "./assets/css/style.css";
import "./index.css";
import FilterWrapper from "./FilterContext";
import { ToastContainer } from "react-toastify";

ReactDOM.render(
  //<React.StrictMode>
  <BrowserRouter>
    {/* <div className="loader">&nbsp;</div> */}
    <FilterWrapper>
      <ToastContainer />
      <App />
    </FilterWrapper>
  </BrowserRouter>,
  //</React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
