import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import * as Appconfig from "../../helpers/config";
import * as common_helper from "../../helpers/lms_common";
import ReactPaginate from "react-paginate";
import * as checkBtnPermission from "../../helpers/checkBtnPermission";

import view_icon from "../../assets/img/view-icon.svg";
import edit_icon from "../../assets/img/edit-icon.svg";

export default class LeadProcessStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateList: [],
      totalStates: 0,
      offset: 0,
      perPage: Appconfig.perPage,
      currentPage: 1,
      pageCount: 0,

      filterKeyword: "",
      filterStartDate: "",
      filterEnddate: "",

      isLoaded: true,
      is_loader: true,
      flashMessage: Appconfig.getFlashMessage(),
      apiError: "",
    };
    this.handleClickCsvData = this.handleClickCsvData.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.receivedData();
    // Get city List
  }

  // hendel Serch
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        currentPage: 1,
        offset: 0,
        is_loader: true,
        filterKeyword: e.target.elements.filterKeyword.value,
        filterStatus: e.target.elements.filterStatus.value,
      },
      () => {
        this.receivedData();
      }
    );
  };

  // pagination
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
  };

  // get data
  receivedData() {
    var token = Appconfig.getSessionToken();

    if (token) {
      fetch(Appconfig.api_base_url + "lead_process_status/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offset: this.state.offset,
          perPage: this.state.perPage,
          filterKeyword: this.state.filterKeyword,
          filterStatus: this.state.filterStatus,
        }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                stateList: result.data.rows,
                totalStates: result.data.count,
                pageCount: Math.ceil(result.data.count / this.state.perPage),
              });
              setTimeout(() => {
                this.setState({ flashMessage: "" });
                localStorage.setItem("successMsg", "");
              }, 2000);
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
            this.setState({ is_loader: false });
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleStatusChange(event) {
    const target = event.target;
    const Id = target.value;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var updateList = [];
    var newStatus = this.state.stateList.map((row, index) => {
      if (row.id == parseInt(Id)) {
        if (row.status == 1) var newStatus = 0;
        else var newStatus = 1;
        row.status = newStatus;
      }
      updateList.push(row);
    });

    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "lead_process_status/updateStatus/" + Id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          //"Content-Type": "application/json"
        },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status == 1) {
              var flashMessage = Appconfig.getFlashMessage(result.message);
              this.setState({
                stateList: updateList,
                flashMessage: flashMessage,
              });
              setTimeout(() => {
                this.setState({ flashMessage: "" });
                localStorage.setItem("successMsg", "");
              }, 3000);
            } else {
              this.receivedData();
              this.setState({ apiError: result.message });
              setTimeout(() => {
                this.setState({ apiError: false });
              }, 3000);
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    }
  }

  handleClickCsvData(event) {
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "lead_process_status/download_csv", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offset: 0,
          perPage: 9999,
          filterKeyword: this.state.filterKeyword,
          filterStatus: this.state.filterStatus,
        }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 0) {
              this.setState({ apiError: result.message });
              setTimeout(() => {
                this.setState({ apiError: false });
              }, 5000);
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              setTimeout(() => {
                this.setState({ apiError: false });
              }, 5000);
            } else if (result.data.count > 0) {
              var Items = result.data.rows;
              var statusKeyList = Appconfig.statusKeyList;
              console.log("Appconfig.statusKeyList", Appconfig.statusKeyList);
              const csvString = [
                ["Status Name", "Status", "Created Date"],
                ...Items.map((item) => [
                  item.lead_process_status_name,
                  statusKeyList[item.status],
                  common_helper.getDateTime(item.createdAt),
                ]),
              ]
                .map((e) => e.join(","))
                .join("\n");

              var data = new Blob([csvString], { type: "text/csv" });
              var csvURL = window.URL.createObjectURL(data);
              var tempLink = document.createElement("a");
              tempLink.href = csvURL;
              var timestr = common_helper.getCurrentDateTimeStr();
              tempLink.setAttribute(
                "download",
                "process_status_list" + timestr + ".csv"
              );
              tempLink.click();
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $("select").selectpicker();
      var today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );

      $(".input-daterange").datepicker({
        autoclose: true,
        endDate: "0d",
        format: "dd-mm-yyyy",
        maxViewMode: 2,
        todayHighlight: true,
      });
      $(
        ".filter-trigger, .search-btn, .btn-search-close, .filter-backdrop"
      ).click(function () {
        $("body").toggleClass("is-search");
      });
    });
  }

  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <form onSubmit={this.handleSubmit}>
            <header className="head-title mb-3 d-flex align-items-center">
              <a href="/lead_process_status" className="btn-back">
                <i className="fa fa-caret-left"></i>
              </a>
              <h1 className="h3 mb-0">Lead Status</h1>
              <button
                type="button"
                className="btn btn-secondary d-xl-none ml-auto filter-trigger"
              >
                Filter Search <i class=" fa fa-search ml-2"></i>
              </button>
              <div className="filter-group ml-auto d-xl-flex align-items-center">
                <div className="select-filter filter-email mr-xl-3">
                  <label className="d-block text-muted m-0" for="FilterLeads">
                    Search
                  </label>
                  <input
                    type="text"
                    name="filterKeyword"
                    className="form-control"
                    Placeholder="Search By Name"
                  />
                </div>

                <div className="select-filter mr-xl-3">
                  <label className="d-block text-muted m-0" for="FilterStatus">
                    Select Status
                  </label>
                  <select
                    className="selectpicker"
                    name="filterStatus"
                    id="FilterStatus"
                    value={this.state.value}
                  >
                    <option value="">Select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
                <div className="filter-search-btn">
                  <button className="search-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.144"
                      height="17.105"
                      viewBox="0 0 16.144 17.105"
                    >
                      <g transform="translate(-1041 -173)">
                        <g
                          transform="translate(1041 173)"
                          fill="none"
                          stroke="#050033"
                          stroke-width="1.5"
                        >
                          <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
                          <circle cx="7.5" cy="7.5" r="6.75" fill="none" />
                        </g>
                        <path
                          d="M1064.441,198.1l2.72,3.333"
                          transform="matrix(0.996, -0.087, 0.087, 0.996, -24.656, 81.065)"
                          fill="none"
                          stroke="#050033"
                          stroke-linecap="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                    <span class="d-xl-none">SEARCH</span>
                  </button>
                </div>
                <button type="button" className="btn-search-close">
                  &times;
                </button>
              </div>
              <div className="filter-backdrop"></div>
            </header>
          </form>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          {this.state.flashMessage ? this.state.flashMessage : ""}
          <div className="card">
            <header className="card-header py-3 d-sm-flex align-items-center bg-white">
              <div className="recourd-number bold mb-sm-0 mb-3">
                Total Records: {this.state.totalStates}
              </div>
              <div className="ml-auto">
                {checkBtnPermission.getButton(
                  "add",
                  "/lead_process_status/add",
                  edit_icon,
                  this.props,
                  Link,
                  this
                )}
                {checkBtnPermission.getButton(
                  "download_csv",
                  "/lead_process_status/download_csv",
                  "",
                  this.props,
                  Link,
                  this
                )}
              </div>
            </header>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-striped table-hover dashboard-table">
                  <thead className="bg-white">
                    <tr>
                      <th width="140" className="col-fixed">
                        Action
                      </th>
                      <th>Lead Status Name</th>
                      <th>Created on</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeof this.state.stateList[0] === "object" ? (
                      this.state.stateList.map((state, index) => {
                        return (
                          <tr>
                            <td className="nowrap col-fixed">
                              {checkBtnPermission.getButton(
                                "edit",
                                "./lead_process_status/edit/" + state.id,
                                edit_icon,
                                this.props,
                                Link,
                                this
                              )}
                            </td>
                            <td>
                              {state.lead_process_status_name != null
                                ? state.lead_process_status_name
                                : ""}
                            </td>

                            <td>{common_helper.getDate(state.createdAt)}</td>
                            {checkBtnPermission.getButton(
                              "updateStatus",
                              "",
                              "",
                              this.props,
                              Link,
                              this
                            ) ? (
                              <td>
                                <div className="switch-btn btn-checkbox">
                                  <input
                                    type="checkbox"
                                    name="status"
                                    checked={state.status ? true : false}
                                    id={"customSwitch" + index + 1}
                                    value={state.id}
                                    data-index={state.status}
                                    onChange={this.handleStatusChange}
                                  />
                                  <label for={"customSwitch" + index + 1}>
                                    &nbsp;
                                  </label>
                                </div>
                              </td>
                            ) : (
                              <td>{Appconfig.statusKeyList[state.status]}</td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="10">Record Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={
              "pagination justify-content-center flex-wrap mt-3"
            }
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}
