import React, { useContext } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import * as Appconfig from "../../helpers/config";
import * as common_helper from "../../helpers/lms_common";
import ReactPaginate from "react-paginate";
import * as checkBtnPermission from "../../helpers/checkBtnPermission";

import view_icon from "../../assets/img/view-icon.svg";
import edit_icon from "../../assets/img/edit-icon.svg";
import agent_icon from "../../assets/img/agent-icon.svg";

import AgentAssignModal from "../../components/leads/AgentAssignModel";
import Dropdown from "../dropdown";
import { FilterContext } from "../../FilterContext";
import useToast from "../../hooks/useToast";

class CampaignList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignList: [],
      totalCampaigns: 0,
      offset: 0,
      perPage: Appconfig.perPage,
      currentPage: 1,
      pageCount: 0,
      filterKeyword: "",
      filterSupplier: "",
      filterStartDate: "",
      filterEnddate: "",
      flashMessage: Appconfig.getFlashMessage(),
      isLoaded: true,
      is_loader: true,
      apiError: "",
      ...(props?.globalFilters ? props.globalFilters : {}),
    };
    this.handleClickCsvData = this.handleClickCsvData.bind(this);
    this.receivedData();
  }

  // hendel Serch
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        currentPage: 1,
        offset: 0,
        is_loader: true,
        filterKeyword: e.target.elements.filterKeyword.value,
        filterSupplier: e.target.elements.filterSupplier.value,
        filterStartDate: e.target.elements.filterStartDate.value,
        filterEndDate: e.target.elements.filterEndDate.value,
      },
      () => {
        this.receivedData();
      }
    );
  };

  // pagination
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
  };

  // get data
  receivedData() {
    var token = Appconfig.getSessionToken();
    this.props.setGlobalFilters &&
      this.props.setGlobalFilters({
        offset: this.state.offset,
        perPage: this.state.perPage,
        filterKeyword: this.state.filterKeyword,
        filterSupplier: this.state.filterSupplier,
        filterStartDate: this.state.filterStartDate,
        filterEndDate: this.state.filterEndDate,
      });
    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "campaign/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offset: this.state.offset,
          perPage: this.state.perPage,
          filterKeyword: this.state.filterKeyword,
          filterSupplier: this.state.filterSupplier,
          filterStartDate: this.state.filterStartDate,
          filterEndDate: this.state.filterEndDate,
        }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            setTimeout(() => {
              this.setState({ flashMessage: "" });
              localStorage.setItem("successMsg", "");
            }, 2000);

            if (result.status === 1) {
              this.setState({
                is_loader: false,
                campaignList: result.data.rows,
                totalCampaigns: result.data.count,
                pageCount: Math.ceil(result.data.count / this.state.perPage),
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleClickCsvData(event) {
    var token = Appconfig.getSessionToken();

    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "campaign/download_csv", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offset: 0,
          perPage: 9999,
          filterKeyword: this.state.filterKeyword,
          filterSupplier: this.state.filterSupplier,
          filterStartDate: this.state.filterStartDate,
          filterEndDate: this.state.filterEndDate,
        }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 0) {
              this.setState({ apiError: result.message });
              setTimeout(() => {
                this.setState({ apiError: false });
              }, 5000);
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              setTimeout(() => {
                this.setState({ apiError: false });
              }, 5000);
            } else if (result.data.count > 0) {
              var Items = result.data.rows;
              var statusKeyList = Appconfig.statusKeyList;
              const csvString = [
                [
                  "Campaign Name",
                  "Campaign Slug",
                  "Budget",
                  "Expected Lead",
                  "Status",
                  "Created Date",
                ],
                ...Items.map((item) => [
                  item.campaign_name,
                  item.campaign_slug,
                  item.budget,
                  item.expected_lead,
                  statusKeyList[item.status],
                  common_helper.getDateTime(item.createdAt),
                ]),
              ]
                .map((e) => e.join(","))
                .join("\n");

              var data = new Blob([csvString], { type: "text/csv" });
              var csvURL = window.URL.createObjectURL(data);
              var tempLink = document.createElement("a");
              tempLink.href = csvURL;
              var timestr = common_helper.getCurrentDateTimeStr();
              tempLink.setAttribute(
                "download",
                "CampaignList" + timestr + ".csv"
              );
              tempLink.click();
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  componentDidMount() {
    let self = this;
    $(document).ready(function () {
      if (typeof self.state.cityList === "object") {
        $(".selectpicker").selectpicker();
      }

      var today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      $(".input-daterange").datepicker({
        autoclose: true,
        endDate: "0d",
        format: "dd-mm-yyyy",
        maxViewMode: 2,
        todayHighlight: true,
      });

      $(
        ".filter-trigger, .search-btn, .btn-search-close, .filter-backdrop"
      ).click(function () {
        $("body").toggleClass("is-search");
      });
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <form onSubmit={this.handleSubmit}>
            <header className="head-title mb-3 d-flex align-items-center">
              <a href="/campaigns" className="btn-back">
                <i className="fa fa-caret-left"></i>
              </a>
              <h1 className="h3 mb-0">All Campaigns</h1>
              <button
                type="button"
                className="btn btn-secondary d-xl-none ml-auto filter-trigger"
              >
                Filter Search <i class=" fa fa-search ml-2"></i>
              </button>
              <div className="filter-group ml-auto d-xl-flex align-items-center">
                <Dropdown
                  className="mr-xl-3"
                  value={this.state.filterSupplier}
                  key={"supplier_name"}
                  field={"filterSupplier"}
                  title={"Select Supplier"}
                  requestURL={"supplier/getActiveSuppliers"}
                  labelField={"supplier_name"}
                  valueField={"id"}
                  onChange={(value, field) => { this.setState({ [field]: value }) }}
                />
                <div className="select-filter filter-email mr-xl-3">
                  <label className="d-block text-muted m-0" for="FilterLeads">
                    Search Campaign
                  </label>
                  <input
                    value={this.state.filterKeyword}
                    type="text"
                    name="filterKeyword"
                    className="form-control"
                    Placeholder="Search By Campaign Name"
                    onChange={this.handleChange}


                  />
                </div>

                <div className="datepicker p-0 d-xl-flex align-items-center input-daterange">
                  <div className="select-filter mr-xl-3 dropdown-toggle">
                    <label
                      className="d-block text-muted m-0"
                      for="FilterDateFrom"
                    >
                      From Date
                    </label>
                    <input
                      type="text"
                      id="startDate"
                      name="filterStartDate"
                      className="form-control text-left date-input"
                      value={this.state.filterStartDate}
                      placeholder="DD-MM-YY"
                      onChange={this.handleChange}

                    />
                  </div>
                  <div className="select-filter dropdown-toggle mr-xl-3">
                    <label
                      className="d-block text-muted m-0"
                      for="FilterDateTo"
                    >
                      To Date
                    </label>
                    <input
                      type="text"
                      className="form-control text-left date-input"
                      id="endDate"
                      name="filterEndDate"
                      value={this.state.filterEndDate}
                      onChange={this.handleChange}
                      placeholder="DD-MM-YY"
                    />
                  </div>
                </div>
                <div className="filter-search-btn">
                  <button className="search-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.144"
                      height="17.105"
                      viewBox="0 0 16.144 17.105"
                    >
                      <g transform="translate(-1041 -173)">
                        <g
                          transform="translate(1041 173)"
                          fill="none"
                          stroke="#050033"
                          stroke-width="1.5"
                        >
                          <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
                          <circle cx="7.5" cy="7.5" r="6.75" fill="none" />
                        </g>
                        <path
                          d="M1064.441,198.1l2.72,3.333"
                          transform="matrix(0.996, -0.087, 0.087, 0.996, -24.656, 81.065)"
                          fill="none"
                          stroke="#050033"
                          stroke-linecap="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                    <span class="d-xl-none">SEARCH</span>
                  </button>
                </div>
                <button type="button" className="btn-search-close">
                  &times;
                </button>
              </div>
              <div className="filter-backdrop"></div>
            </header>
          </form>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          {this.state.flashMessage ? this.state.flashMessage : ""}
          <div className="card">
            <header className="card-header py-3 d-sm-flex align-items-center bg-white">
              <div className="recourd-number bold">
                Total Records: {this.state.totalCampaigns}
              </div>
              <div className="ml-auto mt-sm-0 mt-3">
                {checkBtnPermission.getButton(
                  "add",
                  "/campaign/add",
                  edit_icon,
                  this.props,
                  Link,
                  this
                )}
                {checkBtnPermission.getButton(
                  "download_csv",
                  "/campaign/download_csv",
                  "",
                  this.props,
                  Link,
                  this
                )}
              </div>
            </header>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-striped table-hover dashboard-table">
                  <thead className="bg-white">
                    <tr>
                      <th className="col-fixed">Action</th>
                      <th>Campaign Name</th>
                      <th>Campaign Slug</th>
                      <th>Budget</th>
                      <th>Tracking Number</th>
                      <th>Start Date</th>
                      <th>Supplier</th>
                      <th>Status</th>
                      <th>Created on</th>
                    </tr>
                  </thead>
                  <tbody>
                    {typeof this.state.campaignList[0] === "object" ? (
                      this.state.campaignList.map((campaign, index) => {
                        return (
                          <tr>
                            <td className="nowrap col-fixed">
                              {checkBtnPermission.getButton(
                                "view",
                                "./campaign/view/" + campaign.id,
                                view_icon,
                                this.props,
                                Link,
                                this
                              )}
                              {checkBtnPermission.getButton(
                                "edit",
                                "./campaign/edit/" + campaign.id,
                                edit_icon,
                                this.props,
                                Link,
                                this
                              )}
                            </td>
                            <td>{campaign.campaign_name}</td>
                            <td>{campaign.campaign_slug}</td>
                            <td>{campaign.budget}</td>
                            <td>{campaign.tracking_number}</td>
                            <td>{campaign.campaign_start_date}</td>
                            <td>{campaign.supplier?.supplier_name}</td>
                            <td>{Appconfig.statusKeyList[campaign.status]}</td>
                            <td>{common_helper.getDate(campaign.createdAt)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="10">Record Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination justify-content-center mt-3"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}


const Campaigns = (props) => {
  const { globalFilters, setGlobalFilters } = useContext(FilterContext);
  const { notify } = useToast();
  return (
    <CampaignList
      {...props}
      notify={notify}
      globalFilters={globalFilters}
      setGlobalFilters={setGlobalFilters}
    />
  );
};
export default Campaigns;
