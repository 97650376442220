import React from "react";

export const setSeriesColors = (series, am5) => {
  series
    .get("colors")
    .set("colors", [
      am5.color("#488A99"),
      am5.color("#AC3E31"),
      am5.color("#1C4E80"),
      am5.color("#DBAE58"),
      am5.color("#EA6A47"),
      am5.color("#0091D5"),
    ]);
};
// let colors = [
//   am5.color("#0D4C5B"),
//   am5.color("#791914"),
//   am5.color("#194a7c"),
//   am5.color("#94671E"),
//   am5.color("#86A873"),
//   am5.color("#A42A1C"),
//   am5.color("#021244"),
// ];

export const setSeriesColorsLite = (series, am5) => {
  series
    .get("colors")
    .set("colors", [
      am5.color("#0D4C5B"),
      am5.color("#791914"),
      am5.color("#194a7c"),
      am5.color("#94671E"),
      am5.color("#86A873"),
      am5.color("#021244"),
      am5.color("#A42A1C"),
    ]);
};

// #F1F1F1, #202020, #7E909A, #1C4E80, #A5D8DD, #EA6A47, #0091D5

export const setIndianNumberFormat = (root) => {
  root.numberFormatter.setAll({
    intlLocales: "hi",
    numberFormat: "#,###.00",

    // Group only into M (millions), and B (billions)
    bigNumberPrefixes: [
      { number: 1e6, suffix: "M" },
      { number: 1e9, suffix: "B" },
    ],

    // Do not use small number prefixes at all
    smallNumberPrefixes: [],
  });
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

export const getChartLabelText = (data, titleField, excludeFields) => {
  let str = `[bold]{${titleField}}[/]`;

  Object.keys(data).forEach((item) => {
    let newItem = capitalizeFirstLetter(item);
    if (excludeFields.indexOf(item) < 0) {
      let name = newItem.split("_").join(" ");
      str += `\n[bold]${name}[/] : {${item}}`;
    }
  });

  return str;
};

export const getRenderComponent = (Component, props) => {
  if (React.isValidElement(Component)) {
    return React.cloneElement(Component, props);
  } else if (typeof Component === "function") {
    if (Component.prototype && Component.prototype.isReactComponent) {
      return <Component {...props} />;
    } else {
      return Component(props);
    }
  }
  return Component;
};
