export function getLoggedInUser(props = "") {
  var common_component = [
    "profile",
    "profile_edit",
    "setting",
    "dashboard",
    "applyleave",
  ];
  const uriPath = props.location.pathname;
  const uriPathArr = uriPath.split("/");
  var controllerArr = ["countries", "cities"];
  var controller = uriPathArr[1];

  if (controllerArr.includes(controller)) {
    controller = controller.substring(0, controller.length - 3);
    controller = controller + "ys";
    console.log("controller", controller);
  }

  if (common_component.includes(controller)) {
    var actionKey = "list";
  } else if (uriPathArr[2] === undefined) {
    var actionKey = "list";

    if (
      controller === "lead_process_status" ||
      controller === "lead_process_stage"
    ) {
      controller = controller + "s";
    }
    controller = controller.substring(0, controller.length - 1);
  } else {
    var actionKey = uriPathArr[2];
  }

  var lms_usersession = {};
  var user_details = localStorage.getItem("lmsuser");
  console.log("user_details", user_details);

  try {
    lms_usersession = JSON.parse(user_details);

    //~ console.log('lms_usersession',lms_usersession);
    //~ return true;
    if (lms_usersession.hasOwnProperty("token")) {
      var current_timestamp = Math.floor(Date.now());
      var timestamp_diff =
        current_timestamp - parseInt(lms_usersession.timeStamp);

      if (timestamp_diff > 24 * 3600 * 1000) lms_usersession = {};
      else {
        console.log("lms_usersession", lms_usersession);
        lms_usersession.timeStamp = Math.floor(Date.now());

        localStorage.setItem("lmsuser", JSON.stringify(lms_usersession));

        var role_permissions_Str = lms_usersession.user_role.role_permissions;
        var role_permissions = JSON.parse(role_permissions_Str);

        if (common_component.includes(controller)) {
          return lms_usersession;
        }
        if (role_permissions[controller] !== null) {
          if (role_permissions[controller].includes(actionKey)) {
            console.log("permission", "allow");
            return lms_usersession;
          } else {
            console.log("permission", "denied");
            //return false;
          }
        } else {
          //return lms_usersession;
          return false;
        }

        //if(lms_usersession.user_role.role_permissions);
      }
    } else {
      //console.log('permission','denied');
      return false;
    }
  } catch (ex) {
    //console.error(ex);
    lms_usersession = {};
  }

  if (lms_usersession.hasOwnProperty("token")) return lms_usersession;
  else return false;
}

export function isLoggedInUser() {
  var lms_usersession = {};
  var user_details = localStorage.getItem("lmsuser");

  console.log("user_details", user_details);
  if (
    user_details != "undefined" &&
    user_details != null &&
    user_details != ""
  ) {
    lms_usersession = JSON.parse(user_details);
    if (lms_usersession.hasOwnProperty("token")) {
      var current_timestamp = Math.floor(Date.now());
      console.log("timestamp_current", current_timestamp);
      console.log("timestamp_usersession", lms_usersession.timeStamp);
      var timestamp_diff =
        current_timestamp - parseInt(lms_usersession.timeStamp);
      console.log("timestamp_diff", timestamp_diff);
      //24*3600*1000 one dat  // 10 mint 600*1000 //15.222.153.229
      if (timestamp_diff > 2 * 3600 * 1000) {
        localStorage.setItem("lmsuser", JSON.stringify(lms_usersession));
        return false;
      } else {
        return true;
      }
      return true;
    }
  }
  return false;
}

export function logoutUser() {
  var lms_usersession = {};
  localStorage.setItem("lmsuser", JSON.stringify(lms_usersession));
}
