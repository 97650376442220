import { api_base_url } from "../helpers/config";
import { get, post } from "../services/apiHandler";
// import {Cookie} from '../utility';
// import metadata from './metadata.json';
const metadata = {};
const APP_NAME = "IMS";
const BASE_URL = "https://demo-api.f4tek.com";
const defaultURL = api_base_url || BASE_URL + "/";
const defaultAppMode = "dev";
let configData = {} || metadata[process.env.REACT_APP_MODE || defaultAppMode];

const SERVICE = {
  AUTH: "", //-> auth/
  REPORT: "report/",
  MASTER: "ims-master/",
  PAYMENT: "payment-service",
  LOS: "los/",
};
window.checkData = (data) => {
  // data
  //   .then((res) => {
  //     console.log("@@@@@@@@@@@@", res);
  //   })
  //   .catch((e) => {
  //     console.log("@@@@catcgh", e);
  //   if (e.status == 401 || e.status == 701) {
  //     Cookie.erase('token');
  //     // window.location.reload();
  //   }
  // });
};
let APP_VERSION = `${configData.buildMajor}.${configData.buildMinor}.${configData.buildRevision}`;
let DEPLOY_TIME = configData.deployTime;
let ENV = process.env.REACT_APP_PROD_URL || defaultURL;
let APP_MODE = process.env.REACT_APP_MODE || defaultAppMode;

const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;
const commonGetApi = ({ url, data }) => get(url, { data, endPoint: ENV });
const commonPostApi = ({ url, data }) => post(url, { data, endPoint: ENV });
export {
  SERVICE,
  ENV,
  APP_VERSION,
  DEPLOY_TIME,
  APP_MODE,
  MAP_API_KEY,
  commonGetApi,
  commonPostApi,
  APP_NAME,
};
