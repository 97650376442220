import React from "react";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Button from "react-bootstrap/Button";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import * as common_helper from "../../helpers/lms_common";

export default class AgentAssignModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leadId: this.props.modalId,
      lead: [],
      agent: [],
      errors: { lead_assign_to: "" },
    };

    this.agentInput = React.createRef();
    this.handleAgentChange = this.handleAgentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleAgentChange(event) {
    this.setState({ lead_assign_to: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    if (!this.state.lead_assign_to) {
      formIsValid = false;
      errors["lead_assign_to"] = "The agent field is required.";
      this.agentInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var userInfo = localStorage.getItem("lmsuser");
      userInfo = JSON.parse(userInfo);
      var token = "";
      if (userInfo != "undefined" && userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
      if (token) {
        var token = userInfo.token;
        var settings = {
          url: Appconfig.api_base_url + "lead/saveAgent/" + this.props.modalId,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            lead_assign_to: this.state.lead_assign_to,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          //localStorage.setItem("lmsuser", JSON.stringify(response.token));
          console.log("response", response);
          if (response.status === 1) {
            self.setState({ redirectToList: true });
            window.location.href = Appconfig.site_url + "/leads";
          } else if (response.status === 0) {
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/leads";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
      }
    } else {
      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.receivedData();
  }

  componentDidUpdate(prevProps) {
    console.log("prev", prevProps);
    console.log("last", this.props.modalId);
    //if((this.props.modalId !== prevProps.modalId))
    if (this.props.modalId !== prevProps.modalId) {
      this.receivedData();
    }
  }

  receivedData() {
    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined") {
      userInfo = JSON.parse(userInfo);
      token = userInfo.token;
    }

    var leadId = parseInt(this.props.modalId);

    if (token && leadId > 0) {
      console.log("ld", leadId);
      console.log("tk", token);
      var token = token;
      console.log("url", Appconfig.api_base_url + "lead/assignAgent/" + leadId);
      fetch(Appconfig.api_base_url + "lead/assignAgent/" + leadId, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                lead: result.data,
                agent: result.agent,
                lead_assign_to: result.data.lead_assign_to,
              });

              setTimeout(function () {
                $(".selectpicker").selectpicker("refresh");
              }, 500);

              console.log("assign:", this.state.lead);
              console.log("agent:", this.state.agent);
            } else if (result.status === 0) {
              this.setState({ apiError: result.message, isLoaded: false });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message, isLoaded: false });
              //window.location.href=Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
      //console.log('roles:',this.state.roles);
    }
  }

  render() {
    const { id } = this.state.lead;
    return (
      <Modal show={this.props.modalIsOpen} onHide={this.props.closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Lead Number - {parseInt(id) + 1000}</Modal.Title>
        </Modal.Header>
        <form onSubmit={this.handleSubmit}>
          <Modal.Body>
            <div class="form-group">
              <label className="d-block text-muted" for="FilterCity">
                Select Agent
              </label>
              <select
                className="form-control selectpicker"
                name="filterAgent"
                data-size="8"
                id="filterAgent"
                value={this.state.lead_assign_to}
                onChange={this.handleAgentChange}
                ref={this.agentInput}
              >
                {typeof this.state.agent === "object"
                  ? this.state.agent.map((ant) => {
                      return (
                        <option value={ant.user.id}>
                          {ant.user.full_name}
                        </option>
                      );
                    })
                  : ""}
              </select>
              <span className="error">
                {this.state.errors["lead_assign_to"]}
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.closeModal}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}
