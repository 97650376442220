import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";

export default class CityEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lenderId: parseInt(props.match.params.LenderId),
      categoryList: {},
      lender_category: "",
      lender_name: "",
      errors: { lender_name: "", lender_category: "", status: "" },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      apiError: false,
      is_loader: true,
      redirectToList: false,
    };

    //console.log('props.match.params',props.match.params);
    this.lenderInput = React.createRef();
    this.categoryInput = React.createRef();
    this.statusInput = React.createRef();

    this.handleLenderNameChange = this.handleLenderNameChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    let self = this;
    // get token
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "lender/edit/" + this.state.lenderId, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            console.log("result", result);
            if (result.status === 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 403) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 1) {
              this.setState(
                {
                  lender_name: result.data.lender_name,
                  lender_category: result.data.lender_category_id,
                  status: result.data.status,
                  categoryList: result.lender_categories,
                },
                () => {
                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 300);
                  console.log("categoryList2", typeof this.state.categoryList);
                }
              );
            }
            setTimeout(function () {
              self.setState({ is_loader: false });
            }, 300);
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }
  handleLenderNameChange(event) {
    this.setState({ lender_name: event.target.value });
  }
  handleCategoryChange(event) {
    this.setState({ lender_category: event.target.value });
  }
  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    if (!this.state.lender_name.trim()) {
      formIsValid = false;
      errors["lender_name"] = "The lender name field is required.";
      this.lenderInput.current.focus();
    }
    if (!this.state.lender_category) {
      formIsValid = false;
      errors["lender_category"] = "The Lender cateory field is required.";
      this.categoryInput.current.focus();
    }

    if (!this.state.status) {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (token) {
        var settings = {
          url: Appconfig.api_base_url + "lender/edit/" + this.state.lenderId,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            lender_name: this.state.lender_name.trim(),
            lender_category: this.state.lender_category,
            status: this.state.status,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been updated successfully");
            self.setState({ redirectToList: true });
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/lenders";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.lenderInput.current.focus();
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/lenders"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/lenders" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">
              Edit Lender :{parseInt(this.state.lenderId)}
            </h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Lender Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lender_name"
                        maxlength="100"
                        placeholder="Enter lender name"
                        value={this.state.lender_name}
                        onChange={this.handleLenderNameChange}
                        ref={this.lenderInput}
                      />
                      <span className="error">
                        {this.state.errors["lender_name"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Lender category<span className="text-red">*</span>
                      </label>

                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.lender_category}
                        onChange={this.handleCategoryChange}
                        ref={this.categoryInput}
                      >
                        <option value="">Select Province</option>
                        {typeof this.state.categoryList[0] === "object"
                          ? this.state.categoryList.map((row) => {
                              return (
                                <option value={row.id}>
                                  {row.lender_cat_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["lender_category"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>

                  <div className="pt-md-5 pt-4 text-center">
                    <Link
                      to="/cities"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
