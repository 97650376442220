import React, { useState, useEffect, useCallback } from "react";
import logo from "../assets/img/logo.png";
import IntroCarousel from "../components/IntroCarousel";
import { Link } from "react-router-dom";
import validate from "../helpers/validateInfo";

import * as Appconfig from "../helpers/config";

const Forgotpassword = () => {
  const [user, setUser] = useState({ email: "", thanks: false });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  let name, value;

  const handleInputs = (e) => {
    console.log(e);
    name = e.target.name;
    value = e.target.value;

    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(validate(user));
    setIsSubmitting(true);
  };

  const sendData = useCallback(async () => {
    const { email } = user;
    const res = await fetch(Appconfig.api_base_url + "forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });
    const data = await res.json();

    if (data.status === 0) {
      alert(data.message);
    } else {
      setUser({ email: "", thanks: true });
    }
  }, [user]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      sendData();
    }
  }, [errors, isSubmitting, sendData]);

  return (
    <>
      <div className="d-lg-flex flex-row-reverse login-wrapper">
        <div className="hp-banner bg-secondary col-xl-7 col-lg-6 p-0 d-md-flex align-items-center">
          <div className="w-100 text-center text-white">
            <IntroCarousel />
          </div>
        </div>
        <div className="col-xl-5 col-lg-6 p-lg-0 col-form bg-white">
          <div className="row align-items-stretch h-100">
            <div className="col-12 pt-4 pb-4">
              <div className="brand-logo">
                <Link to={"/"}>
                  <img className="login-logo" src={logo} alt="logo" />
                </Link>
              </div>
            </div>
            {user.thanks ? (
              <div className="col-12">
                <div className="login-form">
                  <h1 className="h2 text-black">Thank You</h1>
                  <p>Reset Password link has been sent your email address</p>
                </div>
              </div>
            ) : (
              <div className="col-12">
                <form onSubmit={handleSubmit}>
                  <div className="login-form">
                    <header className="mb-4">
                      <h1 className="h2 text-black">Forgot Password</h1>
                    </header>
                    <div className="form-group input-user">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        placeholder="Enter your email"
                        className="form-control"
                        value={user.email}
                        onChange={handleInputs}
                      />

                      {errors.email && (
                        <span className="error">{errors.email}</span>
                      )}
                    </div>

                    <div className="pt-4">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary btn-block"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )}
            <div className="col-12 text-muted text-center d-flex align-items-end mt-4">
              <div className="w-100 pb-3 pt-3 border-top">
              &copy;2020-21 LMS Solution powered by F4Tek Inc. All Rights Reserved.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
