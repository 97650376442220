import React from "react";
//import { Link } from 'react-router-dom'
export default class Footer extends React.Component {
  render() {
    return (
      <footer
        id="footer"
        className="w-100 text-muted pb-4 pt-4 text-center border-top"
      >
        &copy;2020-21 LMS Solution powered by F4Tek Inc. All Rights Reserved.
      </footer>
    );
  }
}
