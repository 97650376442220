import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";
import Dropdown from "../dropdown";
import TextInput from "../textInput";
export default class CampaignEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignId: parseInt(props.match.params.id),
      addMoreFormValue: [{ agent_id: "", lead_percentage: "" }],
      addMoreErrors: [{ agent_id: "", lead_percentage: "" }],
      agent: [],
      agentList: {},
      errors: {
        campaign_name: "",
        campaign_slug: "",
        budget: "",
        expected_lead: "",
        status: "",
        start_date: "",
        end_date: "",
        lpercentage: "",
        agent_id: "",
        supplier_id: "",
      },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      is_loader: true,
      apiError: false,
      redirectToList: false,
      selectedValue: [],
      selectedText: [],
      leadPercentageList: [],
    };

    this.campaignNameInput = React.createRef();
    this.campaignSlugInput = React.createRef();

    this.budgetInput = React.createRef();
    this.expectedLeadInput = React.createRef();
    this.startDateInput = React.createRef();
    this.endDateInput = React.createRef();
    this.statusInput = React.createRef();
    this.agentInput = React.createRef();
    this.leadPercentInput = React.createRef();

    this.handleCampaignNameChange = this.handleCampaignNameChange.bind(this);
    this.handleCampaignSlugChange = this.handleCampaignSlugChange.bind(this);

    this.handlebudgetChange = this.handlebudgetChange.bind(this);
    this.handleExpectedLeadChange = this.handleExpectedLeadChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleAgentChange = this.handleAgentChange.bind(this);

    this.handleAgentChange = this.handleAgentChange.bind(this);
    this.hendelLeadPercentChange = this.hendelLeadPercentChange.bind(this);

    this.addFormFields = this.addFormFields.bind(this);
    this.removeFormFields = this.removeFormFields.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    let self = this;
    // get token
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "campaign/edit/" + this.state.campaignId, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            console.log("result", result);
            if (result.status === 1) {
              let leadPercentageLists = [];
              let addMoreFormValue = [];
              let addMoreErrors = [];

              if (result.campaignAgentList) {
                result.campaignAgentList.map(function (row, index) {
                  leadPercentageLists[index] = {
                    agent_id: row.agent_id,
                    agent_percentage: row.lead_percentage,
                  };
                  addMoreFormValue[index] = {
                    agent_id: row.agent_id,
                    lead_percentage: row.lead_percentage,
                  };
                  addMoreErrors[index] = { agent_id: "", lead_percentage: "" };
                });
              }
              console.log("addMoreErrors", addMoreErrors);
              this.setState(
                {
                  campaign_name: result.data.campaign_name,
                  campaign_slug: result.data.campaign_slug,
                  budget: result.data.budget,
                  expected_lead: result.data.expected_lead,
                  start_date: result.data.campaign_start_date,
                  end_date: result.data.campaign_end_date,
                  status: result.data.status,
                  agentList: result.users,
                  tracking_number: result.data.tracking_number,
                  product_id: result.data.product_id,
                  sub_product_id: result.data.sub_product_id,
                  agent: result.campaignAgentIds,
                  supplier_id: result.data.supplier_id,
                  selectedValue: result.campaignAgentIds,
                  selectedText: result.campaignAgentNames,
                  lead_percentage: result.campaignAgentData,
                  leadPercentageList: leadPercentageLists,
                  addMoreFormValue: addMoreFormValue,
                  addMoreErrors: addMoreErrors,
                },
                () => {
                  console.log("addMoreErrors", this.state.addMoreErrors);
                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 500);
                }
              );
            } else if (result.status === 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {}
              );
            } else if (result.status === 403) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {}
              );
            }
            this.setState({ is_loader: false });
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleCampaignNameChange(event) {
    var campaign_name = event.target.value;
    campaign_name =
      campaign_name.charAt(0).toUpperCase() + campaign_name.slice(1);
    var campaign_slug = campaign_name.replace(/\s+/g, "_").toLowerCase();
    //this.setState({campaign_name: campaign_name, campaign_slug: campaign_slug});
    this.setState({ campaign_name: campaign_name });
  }
  handleCampaignSlugChange(event) {
    var campaign_slug = event.target.value;
    // campaign_slug =
    //   campaign_slug.charAt(0).toUpperCase() + campaign_slug.slice(1);
    this.setState({ campaign_slug: campaign_slug });
  }

  handlebudgetChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ budget: event.target.value });
    }
  }
  handleExpectedLeadChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ expected_lead: event.target.value });
    }
  }
  handleStartDateChange(event) {
    this.setState({ start_date: event.target.value });
  }
  handleEndDateChange(event) {
    this.setState({ end_date: event.target.value });
  }
  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }

  handleAgentChange_old(event) {
    this.setState({
      selectedValue: [].slice
        .call(event.target.selectedOptions)
        .map((item) => item.value),
    });
    this.setState({
      selectedText: [].slice
        .call(event.target.selectedOptions)
        .map((item) => item.text),
    });

    let value = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );

    let newlist = this.state.leadPercentageList.filter((o) => {
      if (value.indexOf(o.agent_id.toString()) !== -1) {
        return o;
      }
    });

    this.setState({ agent: value, leadPercentageList: newlist }, () => {
      console.log("ttts", this.state.leadPercentageList);
    });
  }

  addFormFields() {
    this.setState((prevState) => ({
      addMoreFormValue: [
        ...prevState.addMoreFormValue,
        { agent_id: 0, lead_percentage: 0 },
      ],
      addMoreErrors: [
        ...prevState.addMoreErrors,
        { agent_id: "", lead_percentage: "" },
      ],
    }));
  }

  removeFormFields(e) {
    let index = e.target.value;
    let addMoreFormValueArr = this.state.addMoreFormValue;
    let addMoreErrorsArr = this.state.addMoreErrors;
    if (index !== -1) {
      addMoreFormValueArr.splice(index, 1);
      addMoreErrorsArr.splice(index, 1);
      this.setState({
        addMoreFormValue: addMoreFormValueArr,
        addMoreErrors: addMoreErrorsArr,
      });
    }
  }

  hendelLeadPercentChange(index, e) {
    let addMoreFormValueArr = this.state.addMoreFormValue;
    let addMoreErrorsArr = this.state.addMoreErrors;
    let lead_percentage = 0;
    if (e.target.value != "") {
      lead_percentage = e.target.value;
    }
    addMoreFormValueArr[index][e.target.name] = parseInt(lead_percentage);

    var total = addMoreFormValueArr.reduce(
      (prev, next) => parseInt(prev) + parseInt(next[e.target.name]),
      0
    );

    if (total === 100) {
      addMoreErrorsArr.forEach((obj, index) => {
        if (obj.lead_percentage != "") {
          addMoreErrorsArr[index]["lead_percentage"] = "";
        }
      });
      this.setState({
        addMoreFormValue: addMoreFormValueArr,
        addMoreErrors: addMoreErrorsArr,
      });
    } else {
      addMoreErrorsArr[index]["lead_percentage"] =
        "The total lead percentage must be 100 %.";
      this.setState({
        addMoreFormValue: addMoreFormValueArr,
        addMoreErrors: addMoreErrorsArr,
      });
    }
  }

  handleAgentChange(index, e) {
    let addMoreFormValueArr = this.state.addMoreFormValue;
    console.log("addMoreFormValueArr", addMoreFormValueArr);
    let existAgent = addMoreFormValueArr.find((obj, i) => {
      if (parseInt(obj.agent_id) === parseInt(e.target.value)) {
        return true;
      } else {
        return false;
      }
    });
    console.log("existAgent", existAgent);
    let addMoreErrorsArr = this.state.addMoreErrors;
    addMoreFormValueArr[index][e.target.name] = parseInt(e.target.value);

    if (existAgent == undefined || existAgent == "undefined") {
      addMoreErrorsArr[index]["agent_id"] = "";
      this.setState({
        addMoreFormValue: addMoreFormValueArr,
        addMoreErrors: addMoreErrorsArr,
      });
    } else {
      addMoreErrorsArr[index]["agent_id"] =
        "This agent already selected, please try another.";
      this.setState({ addMoreErrors: addMoreErrorsArr });
    }
  }
  handleSupplierChange = (value) => {
    this.setState({ supplier_id: value });
  };

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    //Form validation
    let addMoreErrorsArr = this.state.addMoreErrors;

    let existError = addMoreErrorsArr.find((obj, index) => {
      if (obj.lead_percentage != "") {
        return true;
      }
      if (obj.agent_id != "") {
        return true;
      }
    });
    console.log("existError", existError);
    if (existError == undefined || existError == "undefined") {
      formIsValid = true;
    } else {
      formIsValid = false;
    }

    if (!this.state.campaign_name) {
      formIsValid = false;
      errors["campaign_name"] = "The campaign name field is required.";
      this.campaignNameInput.current.focus();
    }

    if (!this.state.campaign_slug) {
      formIsValid = false;
      errors["campaign_slug"] = "The campaign slug field is required.";
      this.campaignSlugInput.current.focus();
    }

    if (!this.state.budget) {
      formIsValid = false;
      errors["budget"] = "The budget field is required.";
      this.budgetInput.current.focus();
    }

    if (!this.state.expected_lead) {
      formIsValid = false;
      errors["expected_lead"] = "The expected lead field is required.";
      this.expectedLeadInput.current.focus();
    }

    if (!this.state.start_date) {
      formIsValid = false;
      errors["start_date"] = "The start date field is required.";
      this.startDateInput.current.focus();
    }

    if (!this.state.end_date) {
      formIsValid = false;
      errors["end_date"] = "The end date field is required.";
      this.endDateInput.current.focus();
    }

    if (!this.state.status) {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }

    this.setState({ errors: errors });
    console.log("formIsValid", formIsValid);
    console.log("errors", errors);
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (token) {
        var settings = {
          url:
            Appconfig.api_base_url + "campaign/edit/" + this.state.campaignId,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            ...this.state,
            agentAndLeadPercentage: this.state.addMoreFormValue,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          console.log("response", response);
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been updated successfully");
            self.setState({ redirectToList: true });
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            self.setState({ redirectToList: true });
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    $(".input-daterange").datepicker({
      autoclose: true,
      startDate: "0d",
      format: "yyyy-mm-dd",
      maxViewMode: 2,
      todayHighlight: true,
    });

    $("#start_date")
      .datepicker()
      .on("changeDate", (e) => {
        //  e here contains the extra attributes
        this.handleStartDateChange(e);
        this.handleEndDateChange(e);
      });

    $("#end_date")
      .datepicker()
      .on("changeDate", (e) => {
        //  e here contains the extra attributes

        this.handleEndDateChange(e);
      });
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/campaigns"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/campaigns" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Edit Campaign</h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            <header className="card-header py-3 bg-white">
              <div className="recourd-number bold">Basic Detail </div>
            </header>
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit} autoComplete="off">
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Campaign Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="campaign_name"
                        maxlength="100"
                        placeholder="Enter your campaign name"
                        value={this.state.campaign_name}
                        onChange={this.handleCampaignNameChange}
                        ref={this.campaignNameInput}
                        autoComplete="off"
                      />
                      <span className="error">
                        {this.state.errors["campaign_name"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Campaign Slug<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="campaign_slug"
                        maxlength="100"
                        placeholder="Enter your campaign slug"
                        value={this.state.campaign_slug}
                        onChange={this.handleCampaignSlugChange}
                        ref={this.campaignSlugInput}
                        autoComplete="off"
                        readOnly
                      />
                      <span className="error">
                        {this.state.errors["campaign_slug"]}
                      </span>
                    </div>
                    <div className="col-md-2 form-group">
                      <label for="">
                        Budget<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="budget"
                        maxlength="7"
                        placeholder="Enter Budget"
                        value={this.state.budget}
                        onChange={this.handlebudgetChange}
                        ref={this.budgetInput}
                        autoComplete="off"
                      />
                      <span className="error">
                        {this.state.errors["budget"]}
                      </span>
                    </div>

                    <div className="col-md-2 form-group">
                      <label for="">
                        Expected Lead <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="expected_lead"
                        maxlength="7"
                        placeholder="Enter expected lead"
                        value={this.state.expected_lead}
                        onChange={this.handleExpectedLeadChange}
                        ref={this.expectedLeadInput}
                        autoComplete="off"
                      />
                      <span className="error">
                        {this.state.errors["expected_lead"]}
                      </span>
                    </div>
                    <TextInput
                      title={"Tracking No."}
                      field="tracking_number"
                      placeholder="Enter Tracking No."
                      value={this.state.tracking_number}
                      onChange={(e) => {
                        this.setState({ tracking_number: e.target.value });
                      }}
                      // ref={this.budgetInput}
                      error={this.state.errors["tracking_number"]}
                      required={true}
                      className={"col-md-4 form-group"}
                    />
                    <Dropdown
                      title={"Product"}
                      field="product_id"
                      placeholder="Select Product"
                      requestURL={"product/getActiveProductList"}
                      valueField={"id"}
                      labelField={"product_name"}
                      value={this.state.product_id}
                      onChange={(e) => {
                        this.setState({
                          product_id: e,
                          sub_product_id: null,
                        });
                      }}
                      // ref={this.budgetInput}
                      error={this.state.errors["product_id"]}
                      className={"col-md-4 form-group"}
                    />
                    <Dropdown
                      dependentValue={this.state.product_id}
                      title={"Sub Product"}
                      field="sub_product_id"
                      placeholder="Select Sub Product"
                      requestURL={
                        "product/getSubproductByProductId/" +
                        this.state.product_id
                      }
                      valueField={"id"}
                      labelField={"product_name"}
                      value={this.state.sub_product_id}
                      onChange={(e) => {
                        this.setState({ sub_product_id: e });
                      }}
                      // ref={this.budgetInput}
                      error={this.state.errors["sub_product_id"]}
                      className={"col-md-4 form-group"}
                    />
                  </div>
                  <div className="row datepicker input-daterange">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Campaign Start Date<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        name="start_date"
                        id="start_date"
                        className="form-control text-left date-input"
                        placeholder="Campaign Start Date"
                        value={this.state.start_date}
                        onChange={this.handleStartDateChange}
                        ref={this.startDateInput}
                        autoComplete="off"
                      />
                      <span className="error">
                        {this.state.errors["start_date"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Campaign End Date<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        name="end_date"
                        id="end_date"
                        className="form-control text-left date-input"
                        placeholder="Campaign End Date"
                        value={this.state.end_date}
                        onChange={this.handleEndDateChange}
                        ref={this.endDateInput}
                        autoComplete="off"
                      />
                      <span className="error">
                        {this.state.errors["end_date"]}
                      </span>
                    </div>
                    <Dropdown
                      className="col-md-2"
                      key={"supplier_name"}
                      field={"supplier_id"}
                      title={"Select Supplier"}
                      value={this.state.supplier_id}
                      requestURL={"supplier/getActiveSuppliers"}
                      labelField={"supplier_name"}
                      valueField={"id"}
                      onChange={this.handleSupplierChange}
                    />
                    <span className="error">
                      {this.state.errors["supplier_id"]}
                    </span>
                    <div className="col-md-2 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>

                  <hr />
                  {this.state.addMoreFormValue
                    ? this.state.addMoreFormValue.map((element, index) => {
                        return (
                          <div className="row addmoregroup">
                            <div className="col-md-3 form-group">
                              <label for="">
                                Agent<span className="text-red">*</span>
                              </label>
                              <select
                                className="form-control"
                                name="agent_id"
                                data-size="7"
                                value={element.agent_id}
                                onChange={(e) =>
                                  this.handleAgentChange(index, e)
                                }
                              >
                                <option value="">Select Agent</option>
                                {typeof this.state.agentList[0] === "object"
                                  ? this.state.agentList.map((agent) => {
                                      return (
                                        <option value={agent.id}>
                                          {agent.full_name}
                                        </option>
                                      );
                                    })
                                  : ""}
                              </select>
                              <span className="error">
                                {this.state.addMoreErrors[index]["agent_id"]}
                              </span>
                            </div>
                            <div className="col-md-4 form-group">
                              <label for="">
                                Leads In Precentage
                                <span className="text-red">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="lead_percentage"
                                maxlength="4"
                                placeholder="Enter lead %"
                                value={element.lead_percentage}
                                onChange={(e) =>
                                  this.hendelLeadPercentChange(index, e)
                                }
                                ref={this.leadPercentInput}
                                autoComplete="off"
                              />
                              <span className="error">
                                {
                                  this.state.addMoreErrors[index][
                                    "lead_percentage"
                                  ]
                                }
                              </span>
                            </div>
                            {this.state.addMoreFormValue.length - 1 === index &&
                            index < 10 ? (
                              <div className="col-md-3 form-group">
                                <br />
                                <br />
                                <button
                                  className="btn btn-danger btn-sm pl-4 pr-4 removeBtn"
                                  value={index}
                                  onClick={this.removeFormFields}
                                  type="button"
                                >
                                  Remove
                                </button>
                                <button
                                  className="btn btn-primary btn-sm pl-4 pr-4 addMoreBtn"
                                  onClick={this.addFormFields}
                                  type="button"
                                >
                                  Add More
                                </button>
                              </div>
                            ) : (
                              <div className="col-md-3 form-group">
                                <br />
                                <br />
                                <button
                                  className="btn btn-danger btn-sm pl-4 pr-4 removeBtn"
                                  value={index}
                                  onClick={this.removeFormFields}
                                  type="button"
                                >
                                  Remove
                                </button>
                              </div>
                            )}
                          </div>
                        );
                      })
                    : ""}

                  <div className="p-5 text-center">
                    <Link
                      to="/campaigns"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
