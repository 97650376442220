import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import * as Appconfig from "../helpers/config";

/*import LeadsInProcess from '../components/dashboard/LeadsInProcess';
import LeadsChannelBased from '../components/dashboard/LeadsChannelBased';
import ProductReport from '../components/dashboard/ProductReport';
import ProductReport_Pie from '../components/dashboard/ProductReport_Pie';
import LocationByStatus_Pie from '../components/dashboard/LocationByStatus_Pie'; 
import TestBarGraph from '../components/dashboard/testBarGraph';
import TestBarGraph2 from '../components/dashboard/testBarGraph2';
*/
import LeadsCounter from "../components/dashboard/LeadsCounter";
import TopPerformer from "../components/dashboard/TopPerformer";
import BottomPerformer from "../components/dashboard/BottomPerformer";

// new Pie
import LeadStatusPie from "../components/dashboard/highchart/leadStatus_Pie";
import LeadCampaignsPie from "../components/dashboard/highchart/LeadCampaigns_Pie";
import LeadChannelsPie from "../components/dashboard/highchart/LeadChannels_Pie";
import LeadStagePie from "../components/dashboard/highchart/leadStage_Pie";
import LeadRejectedPie from "../components/dashboard/highchart/LeadRejectedwise_Pie";
// new Bar
import ProvinceWiseLeadStatusBar from "../components/dashboard/highchart/ProvinceWiseLeadStatus_Bar";
import ProductWiseLeadStatusBar from "../components/dashboard/highchart/ProductWiseLeadStatus_Bar";
import ChannelLeads_vs_LenderFundedBar from "../components/dashboard/highchart/ChannelLeads_vs_LenderFunded_Bar";
import ProductLeads_vs_LenderFundedBar from "../components/dashboard/highchart/ProductLeads_vs_LenderFunded_Bar";

import CampaignLeads_vs_LenderFundedBar from "../components/dashboard/highchart/CampaignLeads_vs_LenderFunded_Bar";
import LanderWise_LenderSubmitted_vs_FundedBar from "../components/dashboard/highchart/LanderWise_LenderSubmitted_vs_Funded_Bar";

import $ from "jquery";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: "",
      stagePieChart: "",
      statusPieChart: "",
      channelPieChart: "",
      campaignPieChart: "",
      rejectedPieChart: "",
      productWiseStatusBar: "",
      provienceWiseStatusBar: "",
      productWiseLeadFundedBar: "",
      channelWiseLeadFundedBar: "",
      campaignWiseLeadFundedBar: "",
      lenderWiseLeadFundedBar: "",
      topPerformer: "",
      lowPerformer: "",
      showDashboard: true,
      apiError: false,
      error: null,
    };

    this.receivedData();
  }

  receivedData() {
    var userInfo = localStorage.getItem("lmsuser");
    var token = "";
    if (userInfo != "undefined" && userInfo != "") {
      userInfo = JSON.parse(userInfo);
      if (userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
    }

    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "dashboard/view", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //body: JSON.stringify({startDate: this.state.startDate, endDate: this.state.endDate}),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState(
                {
                  showDashboard: result.showDashboard,
                  tickets: result.tickets,
                  stagePieChart: result.stagePieChart,
                  statusPieChart: result.statusPieChart,
                  channelPieChart: result.channelPieChart,
                  campaignPieChart: result.campaignPieChart,
                  rejectedPieChart: result.rejectedPieChart,
                  productWiseStatusBar: result.productWiseStatusBar,
                  provienceWiseStatusBar: result.provienceWiseStatusBar,

                  productWiseLeadFundedBar: result.productWiseLeadFundedBar,
                  channelWiseLeadFundedBar: result.channelWiseLeadFundedBar,
                  campaignWiseLeadFundedBar: result.campaignWiseLeadFundedBar,
                  lenderWiseLeadFundedBar: result.lenderWiseLeadFundedBar,

                  topPerformer: result.topPerformer,
                  lowPerformer: result.lowPerformer,
                },
                () => {
                  console.log("topPerformer:", this.state.topPerformer);
                  console.log("lowPerformer2:", this.state.lowPerformer);
                }
              );
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 403) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  componentDidMount() {
    $(document).ready(function () {});
  }
  render() {
    return (
      <main className="w-100 h-100">
        {this.state.showDashboard === true ? (
          <div className="container">
            <div className="dashboard-content">
              <div className="row">
                <div className="col-xl-12">
                  {this.state.tickets ? (
                    <LeadsCounter
                      tickets={this.state.tickets ? this.state.tickets : ""}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {this.state.statusPieChart ? (
                <div className="row row-gap mb-3">
                  <LeadStatusPie
                    statusPieChart={
                      this.state.statusPieChart ? this.state.statusPieChart : ""
                    }
                  />
                  <LeadChannelsPie
                    channelPieChart={
                      this.state.channelPieChart
                        ? this.state.channelPieChart
                        : ""
                    }
                  />
                  <LeadCampaignsPie
                    campaignPieChart={
                      this.state.campaignPieChart
                        ? this.state.campaignPieChart
                        : ""
                    }
                  />
                  <LeadStagePie
                    stagePieChart={
                      this.state.stagePieChart ? this.state.stagePieChart : ""
                    }
                  />

                  <LeadRejectedPie
                    rejectedPieChart={
                      this.state.rejectedPieChart
                        ? this.state.rejectedPieChart
                        : ""
                    }
                  />
                </div>
              ) : (
                ""
              )}

              {this.state.statusPieChart ? (
                <div className="row row-gap mb-3">
                  {this.state.provienceWiseStatusBar ? (
                    <ProvinceWiseLeadStatusBar
                      provienceWiseStatusBar={
                        this.state.provienceWiseStatusBar
                          ? this.state.provienceWiseStatusBar
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                  {this.state.productWiseStatusBar ? (
                    <ProductWiseLeadStatusBar
                      productWiseStatusBar={
                        this.state.productWiseStatusBar
                          ? this.state.productWiseStatusBar
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}

                  {this.state.channelWiseLeadFundedBar ? (
                    <ChannelLeads_vs_LenderFundedBar
                      channelWiseLeadFundedBar={
                        this.state.channelWiseLeadFundedBar
                          ? this.state.channelWiseLeadFundedBar
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}

                  {this.state.productWiseLeadFundedBar ? (
                    <ProductLeads_vs_LenderFundedBar
                      productWiseLeadFundedBar={
                        this.state.productWiseLeadFundedBar
                          ? this.state.productWiseLeadFundedBar
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                  {this.state.campaignWiseLeadFundedBar ? (
                    <CampaignLeads_vs_LenderFundedBar
                      campaignWiseLeadFundedBar={
                        this.state.campaignWiseLeadFundedBar
                          ? this.state.campaignWiseLeadFundedBar
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                  {this.state.campaignWiseLeadFundedBar ? (
                    <LanderWise_LenderSubmitted_vs_FundedBar
                      lenderWiseLeadFundedBar={
                        this.state.lenderWiseLeadFundedBar
                          ? this.state.lenderWiseLeadFundedBar
                          : ""
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {this.state.lowPerformer ? (
                <div className="row row-gap">
                  <TopPerformer
                    topPerformer={
                      this.state.topPerformer ? this.state.topPerformer : ""
                    }
                  />
                  <BottomPerformer
                    lowPerformer={
                      this.state.lowPerformer ? this.state.lowPerformer : ""
                    }
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="dashboard-content">
              <div className="row">
                <div className="col-md-12">
                  <br /> <br /> <br />
                  <h4 className="text-center">
                    You don't have permission to access this data please contact
                    system administrator
                  </h4>
                  <br /> <br /> <br /> <br /> <br /> <br />
                </div>
              </div>
            </div>
          </div>
        )}
      </main>
    );
  }
}

export default Dashboard;
