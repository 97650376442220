import { createContext, useCallback, useState } from "react";

export const FilterContext = createContext();

const FilterWrapper = ({ children }) => {
  const [filterObj, setFilterObj] = useState({});
  const handleSubmitForm = useCallback((values) => {
    setFilterObj({ [window.location.pathname]: values });
  }, []);

  return (
    <FilterContext.Provider
      value={{
        globalFilters: filterObj[window.location.pathname],
        setGlobalFilters: handleSubmitForm,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterWrapper;
