import React from "react";
import { Link, Redirect } from "react-router-dom";

import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";

export default class StateEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateId: parseInt(props.match.params.stateId),
      countryList: {},
      errors: { state_name: "", country_id: "", status: "" },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      is_loader: true,
      apiError: false,
      redirectToList: false,
    };

    this.stateInput = React.createRef();
    this.countryInput = React.createRef();
    this.statusInput = React.createRef();

    this.handleStateNameChange = this.handleStateNameChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    let self = this;
    // get token
    var token = Appconfig.getSessionToken();
    fetch(Appconfig.api_base_url + "state/edit/" + this.state.stateId, {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => res.json())
      .then(
        async (result) => {
          if (result.status === 0) {
            Appconfig.setFlashMessage("Record has been updated successfully");
            this.setState({ apiError: result.message, isLoaded: false }, () => {
              console.log("aaaaaaaaaaaa");
            });
          } else if (result.status === 403) {
            this.setState({ apiError: result.message, isLoaded: false }, () => {
              console.log("aaaaaaaaaaaa");
            });
          } else if (result.status === 1) {
            this.setState(
              {
                state_name: result.data.state_name,
                country_id: result.data.country_id,
                status: result.data.status,
                countryList: result.countries,
              },
              () => {
                setTimeout(function () {
                  $(".selectpicker").selectpicker("refresh");
                }, 1000);
                console.log("countryList2", typeof this.state.countryList);
              }
            );
          }
          setTimeout(function () {
            self.setState({ is_loader: false });
          }, 500);
        },
        (error) => {
          this.setState({
            isLoaded: false,
          });
        }
      );
  }
  handleStateNameChange(event) {
    this.setState({ state_name: event.target.value });
  }
  handleCountryChange(event) {
    this.setState({ country_id: event.target.value });
  }
  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    if (this.state.status === "") {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }
    if (!this.state.country_id) {
      formIsValid = false;
      errors["country_id"] = "The country field is required.";
      this.countryInput.current.focus();
    }
    if (!this.state.state_name.trim()) {
      formIsValid = false;
      errors["state_name"] = "The Province name field is required.";
      this.fnameInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var userInfo = localStorage.getItem("lmsuser");
      userInfo = JSON.parse(userInfo);
      var token = "";
      if (userInfo != "undefined" && userInfo.hasOwnProperty("token")) {
        token = userInfo.token;
      }
      if (token) {
        var token = userInfo.token;
        var settings = {
          url: Appconfig.api_base_url + "state/edit/" + this.state.stateId,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            state_name: this.state.state_name.trim(),
            country_id: this.state.country_id,
            status: this.state.status,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          //localStorage.setItem("lmsuser", JSON.stringify(response.token));
          console.log("response", response);
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been updated successfully");
            self.setState({ redirectToList: true });
            //window.location.href=Appconfig.site_url+'/states';
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/states";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.stateInput.current.focus();
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/states"} /> : ""}

        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/states" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">
              Edit Province :{parseInt(this.state.stateId)}
            </h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Province Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="state_name"
                        maxlength="100"
                        placeholder="Enter Province"
                        value={this.state.state_name}
                        onChange={this.handleStateNameChange}
                        ref={this.stateInput}
                      />
                      <span className="error">
                        {this.state.errors["state_name"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Country<span className="text-red">*</span>
                      </label>

                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.country_id}
                        onChange={this.handleCountryChange}
                        ref={this.countryInput}
                      >
                        <option value="">Select Country</option>
                        {typeof this.state.countryList[0] === "object"
                          ? this.state.countryList.map((country) => {
                              return (
                                <option value={country.id} key={country.id}>
                                  {country.country_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["country_id"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>

                  <div className="pt-md-5 pt-4 text-center">
                    <Link
                      to="/states"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
