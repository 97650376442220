import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../helpers/config";
import moment from "moment";
const leaveStatusKeyList = ["Pending", "Approved", "Rejected"];
export default class applyLeave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaveList: [],
      totalRecord: 0,
      flashMessage: false,
      errors: { startDate: "", end_date: "", comment: "" },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      is_loader: true,
      apiError: false,
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.receivedData();
  }
  // get leave list
  receivedData() {
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "user_leave/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ offset: 0, perPage: 20 }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            this.setState({ is_loader: false });
            if (result.status === 1) {
              this.setState({
                leaveList: result.data.rows,
                totalRecord: result.data.count,
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleStartDateChange(event) {
    console.log("event.target.value", event.target.value);
    this.setState({ start_date: event.target.value });
  }
  handleEndDateChange(event) {
    this.setState({ end_date: event.target.value });
  }
  handleCommentChange(event) {
    this.setState({ comment: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;
    //Form validation
    if (!this.state.start_date) {
      formIsValid = false;
      errors["start_date"] = "The Start Date field is required.";
    } else {
      var date = moment(this.state.start_date);
      var start_date = this.state.start_date;
      start_date = start_date.split("-").reverse().join("-");
      var date = moment(start_date);
      console.log("start_date", date);
      if (!date.isValid()) {
        formIsValid = false;
        errors["start_date"] = "Please enter valid date.";
      }
    }
    if (!this.state.end_date) {
      formIsValid = false;
      errors["end_date"] = "The End Date field is required.";
    } else {
      var date = moment(this.state.end_date);
      var end_date = this.state.end_date;
      end_date = end_date.split("-").reverse().join("-");
      var date = moment(end_date);
      console.log("end_date", date);
      if (!date.isValid()) {
        formIsValid = false;
        errors["end_date"] = "Please enter valid date.";
      }
    }
    if (!this.state.comment) {
      formIsValid = false;
      errors["comment"] = "The Description field is required.";
      //this.commentInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var userInfo = localStorage.getItem("lmsuser");
      userInfo = JSON.parse(userInfo);
      if (userInfo) {
        var token = userInfo.token;
        var settings = {
          url: Appconfig.api_base_url + "user_leave/add",
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            start_date: this.state.start_date.split("-").reverse().join("-"),
            end_date: this.state.end_date.split("-").reverse().join("-"),
            comment: this.state.comment,
            status: 0,
          },
        };
        let self = this;
        $.ajax(settings).done(function (response) {
          if (response.status === 1) {
            self.setState({
              start_date: "",
              end_date: "",
              comment: "",
              flashMessage: true,
            });
            setTimeout(() => {
              console.log("setTimeout");
              self.setState({ flashMessage: false });
            }, 2000);
            self.receivedData();
          } else if (response.status === 0) {
            var errorsObj = response.error;
            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;
                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/users";
          }
        }); // end ajax
      } else {
        //window.location.href=Appconfig.site_url;
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    //this.nameInput.current.focus()
    $(".input-daterange").datepicker({
      autoclose: true,
      startDate: "+1d",
      format: "dd-mm-yyyy",
      maxViewMode: 2,
      todayHighlight: true,
    });

    $("#start_date")
      .datepicker()
      .on("changeDate", (e) => {
        console.log("event", e);
        this.handleStartDateChange(e);
        this.handleEndDateChange(e);
      });

    $("#end_date")
      .datepicker()
      .on("changeDate", (e) => {
        this.handleEndDateChange(e);
      });
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <header className="head-title mb-md-5 mb-4 d-flex align-items-center">
            <h1 className="h3 mb-0"> Leaves</h1>
          </header>

          <div className="row">
            <div className="col-md-7">
              <div className="card">
                <header className="card-header py-3 d-sm-flex align-items-center bg-white">
                  <div className="recourd-number bold mb-sm-0 mb-3">
                    Total Records:{" "}
                    {this.state.totalRecord ? this.state.totalRecord : 0}
                  </div>
                  <div className="ml-auto"></div>
                </header>
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover dashboard-table v-middle">
                      <thead className="bg-white">
                        <tr>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Description</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {typeof this.state.leaveList[0] === "object" ? (
                          this.state.leaveList.map((leave, index) => {
                            return (
                              <tr>
                                <td>
                                  {leave.start_date ? leave.start_date : ""}
                                </td>
                                <td>{leave.end_date ? leave.end_date : ""}</td>
                                <td>{leave.comment ? leave.comment : ""}</td>
                                <td>{leaveStatusKeyList[leave.status]}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colspan="10">Record Not Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="card">
                <header className="card-header pt-3 pb-3 bg-white">
                  <div className="recourd-number bold">Apply Leave</div>
                  {this.state.flashMessage === true ? (
                    <div className="alert alert-success">
                      <strong>
                        Success : You leave has been applied successfully.
                      </strong>
                    </div>
                  ) : (
                    ""
                  )}
                </header>

                {this.state.isLoaded === true ? (
                  <div className="card-body">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row datepicker input-daterange">
                        <div className="col-md-6 form-group">
                          <label for="start_date">
                            Start Date<span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control date-input"
                            name="start_date"
                            id="start_date"
                            placeholder="Select Start Date"
                            value={this.state.start_date}
                            onChange={this.handleStartDateChange}
                          />
                          <span className="error">
                            {this.state.errors["start_date"]}
                          </span>
                        </div>
                        <div className="col-md-6 form-group">
                          <label for="end_date">
                            End Date<span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control date-input"
                            name="end_date"
                            id="end_date"
                            placeholder="Select End Date"
                            value={this.state.end_date}
                            onChange={this.handleEndDateChange}
                          />
                          <span className="error">
                            {this.state.errors["end_date"]}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label for="">
                            Description <span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="comment"
                            maxlength="200"
                            placeholder="Enter your Leave Description"
                            value={this.state.comment}
                            onChange={this.handleCommentChange}
                          />
                          <span className="error">
                            {this.state.errors["comment"]}
                          </span>
                        </div>
                      </div>

                      <div className="pt-md-5 pt-4 text-center">
                        <Link
                          to="/users"
                          className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                        >
                          Cancel
                        </Link>
                        <button
                          className="btn btn-lg btn-primary pl-4 pr-4"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
