import * as axiosMainInstance from "axios";
import handleSuccess from "./handleSuccess";
import handleErrors from "./handleError";
import * as Appconfig from "../../helpers/config";

// import {checkUserLoggedIn} from '../../redux/actions/AuthAction';
const checkUserLoggedIn = () => {
  return false;
};
const axios = axiosMainInstance.default.create({
  // withCredentials: true,
});
axios.interceptors.response.use(handleSuccess, handleErrors);

/**
 * @typedef BaseApiParams
 * @type {object}
 * @property {any} data - data to be sent through api
 * @property {string} accessToken - access token to be sent
 * @property {string} endPoint -
 */

/**
 * @typedef ApiResponse
 * @type {object}
 * @property {object} responseObject - can be null
 * @property {number} code
 * @property {string} message
 */

/**
 * @typedef MultipartExtraParam
 * @type {object}
 * @property {function} onUploadProgress
 */

/**
 * @typedef MultipartApiParams
 * @type {MultipartExtraParam & BaseApiParams}
 */

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise}
 */
export function post(url, { data, params, endPoint = "" }) {
  //   const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;
  const { cancel, cancelToken } = genCancelToken();
  console.log(`POST: ${endPoint}${url}`);

  const request = axios.post(endPoint + url, data, {
    params,
    cancelToken,
    headers: createHeader({}),
  });
  window.checkData(request);
  request.cancel = cancel;
  return request;
}
/**
 * @param {string} url - Url endpoint
 * @param {MultipartApiParams}
 * @param {string} endPoint - Optional base url
 * @return {Promise}
 */
export function multipart(
  url,
  { data, onUploadProgress = () => {}, endPoint }
) {
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;
  const { cancel, cancelToken } = genCancelToken();
  const body = createFileFormData(data);
  console.log(endPoint + url);
  const request = axios.post(endPoint + url, body, {
    cancelToken,
    headers: createHeader({
      accessToken,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
    onUploadProgress: (progressEvent) => {
      onUploadProgress(progressEvent);
    },
  });
  console.log("multipart---->", url, { data, onUploadProgress }, endPoint);
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function get(url, { data, endPoint = "" }) {
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;

  console.log(`GET::${endPoint}${url}`);
  const { cancel, cancelToken } = genCancelToken();
  const request = axios.get(endPoint + url, {
    cancelToken,
    headers: createHeader({ accessToken }),
    params: data,
  });
  window.checkData(request);
  request.cancel = cancel;
  return request;
}

export function getBlob(url, { data, endPoint = "" }) {
  const { cancelToken } = genCancelToken();
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(endPoint + url, {
        responseType: "blob",
        cancelToken,
        headers: createHeader({ accessToken }),
        params: data,
      });
      const reader = new window.FileReader();
      reader.readAsDataURL(response);
      reader.onload = function () {
        resolve(reader.result);
      };
    } catch (e) {
      reject(e);
    }
  });
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function deleteCall(url, { data, endPoint = "" }) {
  console.log("entered in delete for");
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;
  console.log(endPoint + url);
  const { cancel, cancelToken } = genCancelToken();
  const request = axios.delete(endPoint + url, {
    cancelToken,
    headers: createHeader({ accessToken }),
    data,
  });
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise}
 */
export function put(url, { data, params, endPoint = "" }) {
  console.log(endPoint + url);
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;
  const { cancel, cancelToken } = genCancelToken();
  const request = axios.put(endPoint + url, data, {
    params,
    cancelToken,
    headers: createHeader({ accessToken }),
  });
  window.checkData(request);
  request.cancel = cancel;
  return request;
}

/**
 * @param {string} url - Url endpoint
 * @param {BaseApiParams} apiParams - Api Parameters
 * @return {Promise<ApiResponse>}
 */
export function retryGet(url, { data: apiData, endPoint = "" }) {
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;
  const { retryConfig = {}, ...data } = apiData;
  const {
    retries,
    retryDelay,
    delayInitialRequest,
    passCondition,
    failCondition,
  } = {
    retries: 1000,
    retryDelay: 0,
    delayInitialRequest: false,
    passCondition: ({ code }) => code === 200,
    failCondition: ({ code }) => code === 404,
    ...retryConfig,
  };
  const { cancel, cancelToken } = genCancelToken();
  let retryAttemptsMade = 0;
  let response = {};
  const request = new Promise((resolve, reject) => {
    const timeoutRetry = (delay) => setTimeout(retryFunc, delay);
    async function retryFunc() {
      if (retryAttemptsMade < retries) {
        try {
          // eslint-disable-next-line no-plusplus
          retryAttemptsMade++;
          //if (__DEV__) console.log("Retry attempt :- ", retryAttemptsMade);
          response = await axios.get(endPoint + url, {
            cancelToken,
            headers: createHeader({ accessToken }),
            params: data,
          });
          if (passCondition(response)) {
            resolve(response);
          } else if (failCondition(response)) {
            reject(response);
          } else {
            timeoutRetry(retryDelay);
          }
        } catch (e) {
          reject(e);
        }
      } else {
        reject(response);
      }
    }
    timeoutRetry(delayInitialRequest ? retryDelay : 0);
  });
  window.checkData(request);
  request.cancel = cancel;
  return request;
}

export function createHeader({ headers = {} }) {
  let token = Appconfig.getSessionToken();
  const header = {
    // "Access-Control-Allow-Origin": "https://api-auth.parallelcap.in/",
    // "Access-Control-Allow-Headers":
    //   "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    // "Access-Control-Allow-Credentials": true,
    // Accept: "application/json",
    // "Content-Type": "application/json",
    // Cookie: `token=${Cookie.get("token")}`,
    Authorization: "Bearer " + token,
    ...headers,
  };
  return header;
}

function genCancelToken() {
  let cancel;
  const cancelToken = new axiosMainInstance.default.CancelToken((c) => {
    cancel = c;
  });
  return { cancel, cancelToken };
}

function createFileFormData(data) {
  const form = new FormData();
  if (typeof data === "object") {
    Object.keys(data).forEach((key) => {
      if (isFile(data[key])) {
        form.append(key, data[key]);
      } else {
        form.append(key, data[key]);
      }
    });
  }
  // form.append("applicationData", JSON.stringify(data.applicationData));
  // form.append("test", "test");
  // form.append("test2", "test2");
  return form;
}

function isFile(file) {
  if ("File" in window && file instanceof File) return true;
  else return false;
}

export function getPdf(url, { data, endPoint = "" }) {
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;

  const { cancel, cancelToken } = genCancelToken();

  const res = fetch(endPoint + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        return response && response.blob();
      }
      throw new Error("Error in document download");
    })
    .then((blob) => {
      return blob;
    });
  res.cancel = cancel;
  return res;
}

export function postPdf(url, { data, params, endPoint = "" }) {
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;
  const header = `Token ${accessToken}`;
  const { cancel, cancelToken } = genCancelToken();

  const res = fetch(endPoint + url, {
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: header,
    },
    redirect: "follow",
  })
    .then(async (response) => {
      if (response.status === 200) {
        return response && response.blob();
      }
      let result = await response.json();
      if (result.code === 400) {
        throw new Error(result.message);
      }
      throw new Error("Error in document download");
    })
    .then((blob) => {
      return blob;
    });
  res.cancel = cancel;
  return res;
}

export function postPdfWithError(
  url,
  { data, params, endPoint = "", onErrorDownload }
) {
  const accessToken = checkUserLoggedIn() && checkUserLoggedIn().authToken;
  const header = `Token ${accessToken}`;
  const { cancel, cancelToken } = genCancelToken();
  const res = fetch(endPoint + url, {
    method: "POST",
    body: data,
    headers: {
      "Content-Type": "application/json",
      Authorization: header,
    },
    redirect: "follow",
  })
    .then(async (response) => {
      if (response.status === 200 && !onErrorDownload) {
        return response && response.blob();
      }
      if (response.status === 417) {
        let ressss = await response.blob();
        return ressss;
      }
      let result = await response.json();
      if (result.code === 200) {
        return result;
      }
      if (result.code === 400) {
        throw new Error(result.message);
      }
      throw new Error("Error in document download");
    })
    .then((blob) => {
      return blob;
    });
  res.cancel = cancel;
  return res;
}
