import React, { Component } from "react";
import slide_1 from "../assets/img/slide_1.jpg";
import Carousel from "react-bootstrap/Carousel";

class IntroCarousel extends React.Component {
  render() {
    return (
      <Carousel className="carousel slide" touch={true} controls={false}>
        <Carousel.Item>
          <img src={slide_1} alt="slide" className="w-100" />
          <div className="slide-text">
            {/*
                        <h3>Lorem Ipsun</h3>
                        <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p>
                        */}
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img src={slide_1} alt="slide" className="w-100" />
          <div className="slide-text">
            {/*
                        <h3>Lorem Ipsun</h3>
                        <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs.</p>
                        */}
          </div>
        </Carousel.Item>
      </Carousel>
    );
  }
}

export default IntroCarousel;
