import React, { useContext } from "react";
import { Link } from "react-router-dom";

import Dropzone from "react-dropzone";
import $, { data } from "jquery";
import * as Appconfig from "../../helpers/config";
import * as common_helper from "../../helpers/lms_common";

import view_icon from "../../assets/img/view-icon.svg";
import delete_icon from "../../assets/img/delete-icon.svg";
import pdf_icon from "../../assets/img/file-pdf.png";
import file_icon from "../../assets/img/file-icon.png";

import close from "../../assets/img/close-outline.svg";
import checkmark from "../../assets/img/checkmark-outline.svg";
import AudioPlayer from "./audioPlayer";

import axios from "axios";
import { FilterContext } from "../../FilterContext";
//const history =useHistory();

const getTime = (duration) => {
  var timer = duration,
    minutes,
    seconds;
  minutes = parseInt(timer / 60, 10);
  seconds = parseInt(timer % 60, 10);

  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return minutes + ":" + seconds + " mins";
};

class LeadView extends React.Component {
  constructor(props) {
    super(props);

    this.onDrop = (files) => {
      this.setState({ files });
    };

    this.state = {
      nextLeadId: 0,
      prevLeadId: 0,
      lead: [],
      lead_docs: [],
      all_document: [],
      lead_status_log: [],
      leadId: props.match.params.leadId,
      statusList: "",
      stagingList: "",
      lenderList: "",
      lenderCatList: "",
      lendersByCat: "",
      status: "",
      lead_stage: "",

      rejection_reason: "",
      rejection_remark: "",
      client_contacted: "",
      client_response: "",
      appointment_datetime: "",
      client_meeting: "",
      comment: "",
      application_initiated: "",
      document_collection_status: "",
      lead_remark: "",
      lender_submission: "",
      lender_name: "",
      lender_stage: "",
      expected_revenue: "",
      expected_revenue_show: false,
      lender_category: "",
      lender_remark: "",
      errors: {
        status: "",
        lead_stage: "",
        comment: "",
        client_contacted: "",
        client_response: "",
        appointment_datetime: "",
        application_initiated: "",
        document_collection_status: "",
        lead_remark: "",
        lender_submission: "",
        lender_name: "",
        lender_category: "",
        lender_stage: "",
        lender_remark: "",
        rejection_reason: "",
        rejection_remark: "",
      },
      selectedFile: "",
      files: [],
      doc_name: "",
      fileError: { doc_name: "", doc_file: "" },
      apiError: "",
      isLoaded: true,
      is_loader: true,
      login_user_role_id: "",
      showAppointmentDate: false,
      showClientMeeting: false,
      showLeadDetail: false,
      showLenderDetail: false,
      showClientDetail: false,
      client_meeting_complete: false,
      lead_complete: false,
      client_contacted_disabled: false,
      client_response_disabled: false,
      appointment_datetime_disabled: false,
      client_meeting_disabled: false,
      comment_disabled_disabled: false,
      application_initiated_disabled: false,
      document_collection_status_disabled: false,
      lead_remark_disabled: false,
      lender_submission_disabled: false,
      lender_name_disabled: false,
      lender_stage_disabled: false,
      expected_revenue_disabled: false,
      lender_remark_disabled: false,
      submit_button_enable: true,
      reject_button_enable: true,
      reject_enabled: false,
      //application_initiated_status:false,
      client_contacted_status: false,
      audioUrl:
        "http://15.222.153.229:3001/uploads/audio/5698b965-c965-48c9-83a8-b7ae5b68ca1e_20220801T15%2010_UTC.wav",
    };

    this.statusInput = React.createRef();
    this.stageInput = React.createRef();

    this.clientContactedInput = React.createRef();
    this.clientResponseInput = React.createRef();
    this.adtInput = React.createRef();
    this.clientMeetingInput = React.createRef();
    this.commentInput = React.createRef();

    this.applicationInitiatedInput = React.createRef();
    this.documentCollectionInput = React.createRef();
    this.leadRemarkInput = React.createRef();

    this.lenderSubmissionInput = React.createRef();
    this.lenderNameInput = React.createRef();
    this.expectedRevenueInput = React.createRef();
    this.lenderStageInput = React.createRef();
    this.lenderCatInput = React.createRef();

    this.lenderRemarkInput = React.createRef();

    this.rejectionReasonInput = React.createRef();
    this.rejectionRemarkInput = React.createRef();

    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleStageChange = this.handleStageChange.bind(this);

    this.handleClientContactedChange =
      this.handleClientContactedChange.bind(this);
    this.handleClientResponseChange =
      this.handleClientResponseChange.bind(this);
    this.handleAppointmentDateTimeChange =
      this.handleAppointmentDateTimeChange.bind(this);
    this.handleClientMeetingChange = this.handleClientMeetingChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);

    this.handleApplicationInitiatedChange =
      this.handleApplicationInitiatedChange.bind(this);
    this.handleDocumentCollectionChange =
      this.handleDocumentCollectionChange.bind(this);
    this.handleLeadRemarkChange = this.handleLeadRemarkChange.bind(this);

    this.handleLenderSubmissionChange =
      this.handleLenderSubmissionChange.bind(this);

    this.handleLenderCatChange = this.handleLenderCatChange.bind(this);
    this.handleLenderNameChange = this.handleLenderNameChange.bind(this);
    this.handleExpectedRevenueChange =
      this.handleExpectedRevenueChange.bind(this);

    this.handleLenderStageChange = this.handleLenderStageChange.bind(this);
    this.handleLenderRemarkChange = this.handleLenderRemarkChange.bind(this);

    this.handleRejectionReasonChange =
      this.handleRejectionReasonChange.bind(this);
    this.handleRejectionRemarkChange =
      this.handleRejectionRemarkChange.bind(this);

    this.handleNextViewClick = this.handleNextViewClick.bind(this);
    this.handlePrevViewClick = this.handlePrevViewClick.bind(this);

    //this.handlePreviousViewClick     = this.handlePreviousViewClick.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    this.deleteImage = this.deleteImage.bind(this);

    this.handleReopen = this.handleReopen.bind(this);

    this.handleReject = this.handleReject.bind(this);

    //this.onFileChange     = this.onFileChange.bind(this);
    this.onFileTitleChange = this.onFileTitleChange.bind(this);
    this.hendelClickDelete = this.hendelClickDelete.bind(this);

    this.setState({ flashMessage: Appconfig.setFlashMessage("") });
    // Get Status List
    let self = this;
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "lead/statusList", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              console.log("abc", result.data.rows);
              self.setState({ statusList: result.data.rows }, () => {
                console.log("result status:", this.state.statusList);
                console.log("type of:", typeof this.state.statusList[0]);

                setTimeout(function () {
                  $("#status-id").selectpicker("refresh");
                }, 500);
              });
            } else if (result.status === 0) {
              self.setState({ apiError: result.message });
            } else if (result.status === 403) {
              self.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
            setTimeout(function () {
              self.setState({ is_loader: false });
            }, 400);
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }

    // Get Stage List
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "lead/stageList", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              self.setState({ stagingList: result.data.rows }, () => {
                console.log("result status:", self.state.stagingList);
                console.log("type of:", typeof self.state.stagingList[0]);

                setTimeout(function () {
                  $("#stage-id").selectpicker("refresh");
                }, 500);
              });
            } else if (result.status === 0) {
              self.setState({ apiError: result.message });
            } else if (result.status === 401) {
              self.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }

    // Get Lender  List
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "lead/lenderList", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({ lenderList: result.data.rows }, () => {
                //setTimeout(function(){ $('#lender_name').selectpicker('refresh'); }, 200);
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
    // Get Lender Category List
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "lead/lenderCatList", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({ lenderCatList: result.data.rows }, () => {
                setTimeout(function () {
                  $("#lender_category").selectpicker("refresh");
                }, 300);
              });
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }

    this.receivedData();
  }

  handleNextViewClick() {
    // console.log("this.state.nextLeadId", this.state.nextLeadId);
    this.props.history.push(`./${this.state.nextLeadId}`);
    // window.location.href = `${Appconfig.site_url}/lead/view/${this.state.nextLeadId}`;
    this.setState({ leadId: this.state.nextLeadId });
    this.receivedData(this.state.nextLeadId);
  }

  handlePrevViewClick() {
    // window.location.href = `${Appconfig.site_url}/lead/view/${this.state.prevLeadId}`;
    this.props.history.push(`./${this.state.prevLeadId}`);
    this.setState({ leadId: this.state.prevLeadId });
    this.receivedData(this.state.prevLeadId);
  }

  handleLenderCatChange(event) {
    var lender_category = event.target.value;
    var lendersByCat = [];
    this.setState({ lender_category: event.target.value });

    this.state.lenderList.map((lender) => {
      if (lender.lender_category_id == lender_category) {
        lendersByCat.push(lender);
      }
    });
    console.log("lendersByCat", lendersByCat);
    this.setState({ lendersByCat: lendersByCat });
    setTimeout(function () {
      $("#lender_name").selectpicker("refresh");
    }, 1000);
  }

  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }
  handleStageChange(event) {
    this.setState({ lead_stage: event.target.value });
  }

  handleClientContactedChange(event) {
    let client_contacted = !this.state.client_contacted_status
      ? "client_contacted"
      : "";

    this.setState({
      client_contacted: client_contacted,
      client_contacted_status: !this.state.client_contacted_status,
    });
    //this.setState({client_contacted: event.target.value});
  }
  handleClientResponseChange(event) {
    let willing_status =
      event.target.value === "Willing to Proceed" ? true : false;
    let self = this;
    this.setState(
      {
        client_response: event.target.value,
        showAppointmentDate: willing_status,
      },
      () => {
        setTimeout(function () {
          $("#appointment_datetime")
            .datetimepicker({
              startDate: "0d",
              //endDate:new Date(),
              format: "yyyy-mm-dd hh:ii",
              autoclose: true,
              todayBtn: true,
              //pickerPosition: "bottom-left",
              todayHighlight: true,
            })
            .on("changeDate", (e) => {
              //  e here contains the extra attributes
              self.handleAppointmentDateTimeChange(e);
            });
        }, 500);
      }
    );
  }
  handleAppointmentDateTimeChange(event) {
    this.setState({ appointment_datetime: event.target.value });
  }
  handleClientMeetingChange(event) {
    let client_meeting = !this.state.client_meeting_status
      ? "client_meeting"
      : "";
    this.setState({
      client_meeting: client_meeting,
      client_meeting_status: !this.state.client_meeting_status,
    });

    //this.setState({client_meeting: event.target.value});
  }
  handleCommentChange(event) {
    this.setState({ comment: event.target.value });
  }

  handleApplicationInitiatedChange(event) {
    let application_initiated = !this.state.application_initiated_status
      ? "application_initiated"
      : "";
    this.setState({
      application_initiated: application_initiated,
      application_initiated_status: !this.state.application_initiated_status,
    });
  }
  handleDocumentCollectionChange(event) {
    this.setState({ document_collection_status: event.target.value });
  }
  handleLeadRemarkChange(event) {
    this.setState({ lead_remark: event.target.value });
  }

  handleLenderSubmissionChange(event) {
    let lender_submission = !this.state.lender_submission_status
      ? "lender_submission"
      : "";
    this.setState({
      lender_submission: lender_submission,
      lender_submission_status: !this.state.lender_submission_status,
    });
    //this.setState({lender_submission: event.target.value});
  }
  handleLenderNameChange(event) {
    this.setState({ lender_name: event.target.value });
  }
  handleLenderStageChange(event) {
    if (event.target.value === "Funded") {
      this.setState({
        lender_stage: event.target.value,
        expected_revenue_show: true,
      });
    } else {
      this.setState({
        lender_stage: event.target.value,
        expected_revenue_show: false,
      });
    }
  }
  handleLenderRemarkChange(event) {
    this.setState({ lender_remark: event.target.value });
  }

  handleExpectedRevenueChange(event) {
    this.setState({ expected_revenue: event.target.value }, () => {
      console.log("expected_revenue", this.state.expected_revenue);
    });
  }

  handleRejectionReasonChange(event) {
    this.setState({ rejection_reason: event.target.value });
  }
  handleRejectionRemarkChange(event) {
    this.setState({ rejection_remark: event.target.value });
  }

  /*** Start File  Upload   ***/

  //onFileChange = event => {this.setState({ selectedFile: event.target.files[0] });};
  onFileTitleChange = (event) => {
    this.setState({ doc_name: event.target.value });
  };
  onFileUpload = () => {
    console.log("selectedFile", this.state.files.size);
    console.log("selectedFile2", this.state.files);
    var submitForm = true;
    var fileError = {};
    if (!this.state.doc_name) {
      fileError["doc_name"] = "Dodument title field is required";
      submitForm = false;
    }

    if (!this.state.files[0]) {
      fileError["doc_file"] = "Document file is required";
      submitForm = false;
    } else {
      var fileSize = this.state.files[0].size;
      fileSize = fileSize / 1000000; //file size in mbin mb

      console.log("fileSize", fileSize);
      if (fileSize.toFixed(1) > 50) {
        fileError["doc_file"] = "File size must be less then equal 5MB ";
        submitForm = false;
      }
    }

    this.setState({ fileError: fileError });
    if (submitForm === true) {
      const formData = new FormData();
      formData.append("doc_file", this.state.files[0]);
      formData.append("doc_name", this.state.doc_name);

      var token = Appconfig.getSessionToken();
      let self = this;
      if (token) {
        axios({
          method: "post",
          url: Appconfig.api_base_url + "lead/uploadfile/" + this.state.lead.id,
          data: formData,
          contentType: false,
          cache: false,
          processData: false,
          headers: { Authorization: "Bearer " + token },
        })
          .then(function (response) {
            if (response.data.status === 0) {
              console.log("response", response.data);
              self.setState({ fileError: { doc_file: response.data.message } });
            } else if (response.data.status === 1) {
              self.setState({ doc_name: "", files: [] });
              self.receivedData();
            }
            //handle success
            console.log("response2", response.data);
          })
          .catch(function (response) {
            //handle error
            console.log(response);
          });
      } else {
        window.location.href = Appconfig.site_url;
      }
    }
  };
  /*** Delete lead ducument   ***/
  hendelClickDelete(key) {
    var doc_id = key;
    var token = Appconfig.getSessionToken();
    var self = this;
    if (token) {
      axios({
        method: "get",
        url: Appconfig.api_base_url + "lead/deleteLeadDoc/" + doc_id,
        cache: false,
        processData: false,
        headers: { Authorization: "Bearer " + token },
      })
        .then(function (response) {
          self.receivedData();
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  deleteImage = (file, index) => {
    const myNewFiles = [...this.state.files]; // copy of Original State
    myNewFiles.splice(index, 1);
    this.setState({
      files: myNewFiles,
    });
  };

  handleSubmit(event) {
    var errors = {};

    console.log("handleSubmit");

    var formIsValid = true;
    /*if(!this.state.status){
		  formIsValid = false; 
		  errors["status"] = "The status field is required.";
		  this.statusInput.current.focus();
	}
	if(!this.state.lead_stage){
		  formIsValid = false; errors["lead_stage"] = "The lead stage field is required.";
		  this.stageInput.current.focus();
	}*/

    if (this.state.reject_button_enable === false) {
      if (!this.state.rejection_reason) {
        formIsValid = false;
        errors["rejection_reason"] = "The rejection reason field is required.";
        this.rejectionReasonInput.current.focus();
      }
      if (!this.state.rejection_remark) {
        formIsValid = false;
        errors["rejection_remark"] = "The rejection remark field is required.";
        this.rejectionRemarkInput.current.focus();
      }
    } else {
      if (!this.state.client_contacted) {
        formIsValid = false;
        errors["client_contacted"] = "The client contacted field is required.";
        this.clientContactedInput.current.focus();
      }
      if (!this.state.client_response) {
        formIsValid = false;
        errors["client_response"] = "The client response field is required.";
        this.clientResponseInput.current.focus();
      }

      if (
        this.state.client_response &&
        this.state.client_response === "Willing to Proceed" &&
        !this.state.appointment_datetime
      ) {
        formIsValid = false;
        errors["appointment_datetime"] =
          "The appointment datetime field is required.";
        this.adtInput.current.focus();
      }

      if (!this.state.comment) {
        formIsValid = false;
        errors["comment"] = "The comment field is required.";
        this.commentInput.current.focus();
      }

      if (
        this.state.client_meeting === "client_meeting" &&
        this.state.client_meeting_complete
      ) {
        if (!this.state.application_initiated) {
          formIsValid = false;
          errors["application_initiated"] =
            "The application initiated field is required.";
          this.applicationInitiatedInput.current.focus();
        }
        if (!this.state.document_collection_status) {
          formIsValid = false;
          errors["document_collection_status"] =
            "The document collection status field is required.";
          this.documentCollectionInput.current.focus();
        }
        if (!this.state.lead_remark) {
          formIsValid = false;
          errors["lead_remark"] = "The lead remark field is required.";
          this.leadRemarkInput.current.focus();
        }
      }

      if (
        this.state.application_initiated === "application_initiated" &&
        this.state.document_collection_status === "Completed" &&
        this.state.lead_complete
      ) {
        if (!this.state.lender_submission) {
          formIsValid = false;
          errors["lender_submission"] =
            "The lender submission field is required.";
          this.lenderSubmissionInput.current.focus();
        }

        if (!this.state.lender_category) {
          formIsValid = false;
          errors["lender_category"] = "The lender Category field is required.";
          this.lenderCatInput.current.focus();
        }
        if (!this.state.lender_name) {
          formIsValid = false;
          errors["lender_name"] = "The lender name field is required.";
          this.lenderNameInput.current.focus();
        }
        if (!this.state.lender_stage) {
          formIsValid = false;
          errors["lender_stage"] = "The lender stage field is required.";
          this.lenderStageInput.current.focus();
        }

        if (
          !this.state.expected_revenue &&
          this.state.lender_stage == "Funded"
        ) {
          formIsValid = false;
          errors["expected_revenue"] =
            "The expected revenue field is required.";
          this.expectedRevenueInput.current.focus();
        }

        if (!this.state.lender_remark) {
          formIsValid = false;
          errors["lender_remark"] = "The lender remark field is required.";
          this.lenderRemarkInput.current.focus();
        }
      }
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      setTimeout(() => {
        this.setState({ flashMessage: "" });
        localStorage.setItem("successMsg", "");
      }, 3000);

      if (token) {
        console.log("lender_category", this.state.lender_category);
        this.setState({ is_loader: true });
        var settings = {
          url:
            Appconfig.api_base_url + "lead/updateStatus/" + this.state.lead.id,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            rejection_reason: this.state.rejection_reason,
            rejection_remark: this.state.rejection_remark,
            client_contacted: this.state.client_contacted,
            client_response: this.state.client_response,
            appointment_datetime: this.state.appointment_datetime,
            client_meeting: this.state.client_meeting,
            comment: this.state.comment,
            application_initiated: this.state.application_initiated,
            document_collection_status: this.state.document_collection_status,
            lead_remark: this.state.lead_remark,
            lender_submission: this.state.lender_submission,
            lender_name: this.state.lender_name,
            expected_revenue: this.state.expected_revenue,
            lender_category: this.state.lender_category,
            lender_stage: this.state.lender_stage,
            lender_remark: this.state.lender_remark,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          //localStorage.setItem("lmsuser", JSON.stringify(response.token));
          console.log("response", response);
          self.setState({ is_loader: false });
          if (response.status === 1) {
            Appconfig.setFlashMessage(
              "Lead status has been updated successfully"
            );
            self.setState({ status: "", lead_stage: "", comment: "" }, () => {
              self.receivedData();
              setTimeout(function () {
                $(".selectpicker").selectpicker("refresh");
              }, 200);
            });
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/users";
          }
        }); // end ajax
      } else {
        window.location.href = Appconfig.site_url;
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  handleReject() {
    this.setState({ reject_button_enable: false, reject_enabled: true }, () => {
      setTimeout(function () {
        $("#rejection_reason").selectpicker("refresh");
      }, 100);
    });
  }

  handleReopen(event) {
    var errors = {};
    var formIsValid = true;

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();

      if (token) {
        var settings = {
          url:
            Appconfig.api_base_url + "lead/reopenStatus/" + this.state.lead.id,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            status: 7,
            lead_stage: this.state.lead.lead_stage,
            comment: "Reopen by admin",
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          //localStorage.setItem("lmsuser", JSON.stringify(response.token));
          console.log("response", response);
          if (response.status === 1) {
            self.setState({ status: "", lead_stage: "", comment: "" });
            self.receivedData();
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/users";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
        window.location.href = Appconfig.site_url;
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }
  }

  receivedData(nextPrevLeadId) {
    var token = Appconfig.getSessionToken();
    this.setState({ flashMessage: Appconfig.getFlashMessage() });

    var leadId = parseInt(this.state.leadId);
    if (nextPrevLeadId > 0) leadId = nextPrevLeadId;
    if (token && leadId > 0) {
      var token = token;
      this.setState({ is_loader: true, isLoaded: false });
      fetch(Appconfig.api_base_url + "lead/viewNew/" + leadId, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.props.globalFilters),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              let self = this;
              console.log("result:", result.data);
              //alert(result.data.rejection_reason);
              //alert(result.data.rejection_reason.length);

              var lender_category = result.data.lender_category_id;
              var lendersByCat = [];
              if (this.state.lenderList) {
                this.state.lenderList.map((lender) => {
                  if (lender.lender_category_id == lender_category) {
                    lendersByCat.push(lender);
                  }
                });
              }
              console.log("lendersByCat 1", lendersByCat);

              var property_value = parseInt(result.data.property_value);
              if (property_value > 0) {
                property_value = property_value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                console.log("property_value", property_value);
              }
              console.log("property_value 1", property_value);

              var loan_amount = parseInt(result.data.loan_amount);
              if (loan_amount > 0) {
                loan_amount = loan_amount
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                //loan_amount= (loan_amount).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');
              }
              console.log("loan_amount 1", loan_amount);

              this.setState(
                {
                  isLoaded: true,
                  lendersByCat: lendersByCat,
                  lead: result.data,
                  loan_amount: loan_amount,
                  property_value: property_value,
                  rejection_reason: result.data.rejection_reason,
                  rejection_remark: result.data.rejection_remark
                    ? result.data.rejection_remark
                    : "",
                  client_contacted: result.data.client_contacted
                    ? result.data.client_contacted
                    : "",
                  client_response: result.data.client_response
                    ? result.data.client_response
                    : "",
                  appointment_datetime: result.data.appointment_datetime
                    ? common_helper.getDateTimeYMD(
                        result.data.appointment_datetime
                      )
                    : "",
                  client_meeting: result.data.client_meeting,
                  // comment: result.data.client_remark
                  //   ? result.data.client_remark
                  //   : "",
                  comment:
                    result.data.client_meeting === "client_meeting"
                      ? result.data.client_remark
                      : "",
                  application_initiated: result.data.application_initiated,
                  document_collection_status:
                    result.data.document_collection_status,
                  // lead_remark: result.data.lead_remark,
                  lead_remark:
                    result.data.application_initiated ===
                      "application_initiated" &&
                    result.data.document_collection_status === "Completed"
                      ? result.data.lead_remark
                      : "",
                  // lead_remark: "",
                  lender_submission: result.data.lender_submission,
                  lender_name: result.data.lender_name,
                  expected_revenue: result.data.expected_revenue,
                  expected_revenue_show:
                    result.data.lender_stage === "Funded" ? true : false,
                  lender_category: result.data.lender_category_id,
                  lender_stage: result.data.lender_stage,
                  // lender_remark: result.data.lender_remark,
                  lender_remark:
                    result.data.lender_submission === "lender_submission" &&
                    result.data.lender_stage === "Funded"
                      ? result.data.lender_remark
                      : "",
                  lead_docs: result.lead_docs,
                  lead_status_logs: result.data.lead_status_logs,
                  client_contacted_status:
                    result.data.client_contacted === "client_contacted"
                      ? true
                      : false,
                  client_meeting_status:
                    result.data.client_meeting === "client_meeting"
                      ? true
                      : false,
                  application_initiated_status:
                    result.data.application_initiated ===
                    "application_initiated"
                      ? true
                      : false,
                  lender_submission_status:
                    result.data.lender_submission === "lender_submission"
                      ? true
                      : false,
                  showAppointmentDate:
                    result.data.client_response === "Willing to Proceed"
                      ? true
                      : false,
                  showClientMeeting:
                    result.data.client_response === "Willing to Proceed" &&
                    result.data.appointment_datetime
                      ? true
                      : false,
                  showLeadDetail:
                    result.data.client_meeting === "client_meeting"
                      ? true
                      : false,
                  showLenderDetail:
                    result.data.application_initiated ===
                      "application_initiated" &&
                    result.data.client_meeting === "client_meeting" &&
                    result.data.document_collection_status === "Completed"
                      ? true
                      : false,
                  showClientDetail: true,
                  client_meeting_complete:
                    result.data.client_meeting === "client_meeting"
                      ? true
                      : false,
                  lead_complete:
                    result.data.application_initiated ===
                      "application_initiated" &&
                    result.data.document_collection_status === "Completed"
                      ? true
                      : false,
                  client_contacted_disabled:
                    result.data.client_meeting === "client_meeting"
                      ? true
                      : false,
                  client_response_disabled:
                    result.data.client_meeting === "client_meeting"
                      ? true
                      : false,
                  client_meeting_disabled:
                    result.data.client_meeting === "client_meeting"
                      ? true
                      : false,
                  comment_disabled:
                    result.data.client_meeting === "client_meeting"
                      ? true
                      : false,

                  application_initiated_disabled:
                    result.data.application_initiated ===
                      "application_initiated" &&
                    result.data.document_collection_status === "Completed"
                      ? true
                      : false,
                  document_collection_status_disabled:
                    result.data.application_initiated ===
                      "application_initiated" &&
                    result.data.document_collection_status === "Completed"
                      ? true
                      : false,
                  lead_remark_disabled:
                    result.data.application_initiated ===
                      "application_initiated" &&
                    result.data.document_collection_status === "Completed"
                      ? true
                      : false,
                  lender_submission_disabled:
                    result.data.lender_submission === "lender_submission" &&
                    result.data.lender_stage === "Funded"
                      ? true
                      : false,
                  lender_name_disabled:
                    result.data.lender_submission === "lender_submission" &&
                    result.data.lender_stage === "Funded"
                      ? true
                      : false,
                  lender_stage_disabled:
                    result.data.lender_submission === "lender_submission" &&
                    result.data.lender_stage === "Funded"
                      ? true
                      : false,
                  lender_remark_disabled:
                    result.data.lender_submission === "lender_submission" &&
                    result.data.lender_stage === "Funded"
                      ? true
                      : false,
                  expected_revenue_disabled:
                    result.data.lender_submission === "lender_submission" &&
                    result.data.lender_stage === "Funded"
                      ? true
                      : false,
                  lender_category_disabled:
                    result.data.lender_submission === "lender_submission" &&
                    result.data.lender_stage === "Funded"
                      ? true
                      : false,
                  submit_button_enable:
                    (result.data.client_meeting === "client_meeting" &&
                      result.data.application_initiated ===
                        "application_initiated" &&
                      result.data.document_collection_status === "Completed" &&
                      result.data.lender_submission === "lender_submission" &&
                      result.data.lender_stage === "Funded") ||
                    result.data.rejection_reason
                      ? false
                      : true,
                  all_document: result.all_document,
                  nextLeadId: result.nextLeadId,
                  prevLeadId: result.prevLeadId,

                  reject_button_enable:
                    (result.data.client_meeting === "client_meeting" &&
                      result.data.application_initiated ===
                        "application_initiated" &&
                      result.data.document_collection_status === "Completed" &&
                      result.data.lender_submission === "lender_submission" &&
                      result.data.lender_stage === "Funded") ||
                    result.data.rejection_reason
                      ? false
                      : true,
                  reject_enabled:
                    result.data.rejection_reason === null ||
                    result.data.rejection_reason.length <= 0
                      ? false
                      : true,
                },
                () => {
                  console.log("lendersByCat 2", this.state);

                  setTimeout(function () {
                    self.setState({ is_loader: false });
                  }, 500);
                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 500);

                  //$('.selectpicker').selectpicker('refresh');
                  //$('#lender_category').selectpicker('refresh');
                  //setTimeout(function(){ $('#stage-id').selectpicker('refresh'); }, 200);
                  //setTimeout(function(){ $('#lender_name').selectpicker('refresh'); }, 200);
                  //setTimeout(function(){ $('#lender_category').selectpicker('refresh'); }, 200);

                  if (result.data.client_meeting != "client_meeting") {
                    setTimeout(function () {
                      $("#appointment_datetime")
                        .datetimepicker({
                          startDate: "0d",
                          //endDate:new Date(),
                          format: "yyyy-mm-dd hh:ii",
                          autoclose: true,
                          todayBtn: true,
                          //pickerPosition: "bottom-left",
                          todayHighlight: true,
                        })
                        .on("changeDate", (e) => {
                          //  e here contains the extra attributes
                          self.handleAppointmentDateTimeChange(e);
                        });
                    }, 500);
                  }
                }
              );

              console.log("result 2:", result.data);
            } else if (result.status === 0) {
              this.setState({ apiError: result.message, isLoaded: false });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message, isLoaded: false });
              window.location.href = Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
      //console.log('roles:',this.state.roles);
    } else {
      //window.location.href=Appconfig.site_url;
    }
  }

  componentDidMount() {
    $("#appointment_datetime")
      .datetimepicker({
        startDate: "0d",
        //endDate:new Date(),
        format: "yyyy-mm-dd hh:ii",
        autoclose: true,
        todayBtn: true,
        //pickerPosition: "bottom-left",
        todayHighlight: true,
      })
      .on("changeDate", (e) => {
        //  e here contains the extra attributes
        this.handleAppointmentDateTimeChange(e);
      });
  }

  render() {
    const files = this.state.files.map((file, index) => (
      <li key={file.name} className="d-flex text-left">
        {file.name} - {file.size} bytes
        <div className="flex-fill pl-2 mb-2">
          <a
            className="btn btn-sm btn-primary"
            onClick={() => this.deleteImage(file, index)}
          >
            <i className="fa fa-trash"></i>
          </a>
        </div>
      </li>
    ));

    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/leads" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">
              LEAD NO -{" "}
              {this.state.leadId ? parseInt(this.state.leadId) + 1000 : ""}
            </h1>
            <div className="ml-auto">
              <button
                className="btn btn-sm btn-secondary mr-2"
                disabled={this.state.prevLeadId === 0 ? true : false}
                onClick={this.handlePrevViewClick}
              >
                <i className="fa fa-chevron-left mr-2"></i>Previous
              </button>
              <button
                className="btn btn-sm btn-secondary"
                disabled={this.state.nextLeadId === 0 ? true : false}
                onClick={this.handleNextViewClick}
              >
                Next <i className="fa fa-chevron-right ml-2"></i>
              </button>
            </div>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}

          <div>
            {this.state.isLoaded === true ? (
              <div className="row">
                <div className="col-xl-7">
                  <div className="card mb-4">
                    <header className="card-header pt-4 pb-4 bg-white">
                      <h4 className="m-0 bold">Product Detail</h4>
                    </header>
                    <div className="card-body">
                      <div className="row">
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Product Name</label>
                          <h5 className="m-0">
                            {typeof this.state.lead["pg"] === "object"
                              ? this.state.lead.pg.product_name
                              : ""}
                          </h5>
                        </div>
                        {typeof this.state.lead["spg"] === "object" ? (
                          this.state.lead.spg.product_name !== "No" ? (
                            <div className="mb-4 col-md-4">
                              <label className="text-muted">
                                Sub Product Name
                              </label>
                              <h5 className="m-0">
                                {typeof this.state.lead["spg"] === "object"
                                  ? this.state.lead.spg.product_name
                                  : ""}
                              </h5>
                            </div>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="card mb-4">
                    <header className="card-header pt-4 pb-4 bg-white">
                      <h4 className="m-0 bold">Customer Detail</h4>
                    </header>
                    <div className="card-body">
                      <div className="row">
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Name</label>
                          <h5 className="m-0">
                            {Appconfig.getName(this.state.lead)}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">
                            Email{" "}
                            <i
                              className={
                                this.state.lead.email_verify
                                  ? "fa fa-check text-green"
                                  : "fa fa-close text-red"
                              }
                            ></i>
                          </label>
                          <h5 className="m-0">
                            {this.state.lead.email_id
                              ? this.state.lead.email_id
                              : "Null"}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Country Code </label>
                          <h5 className="m-0">
                            {this.state.lead.country_code
                              ? this.state.lead.country_code
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">
                            Mobile Number{" "}
                            <i
                              className={
                                this.state.lead.mobile_verify
                                  ? "fa fa-check text-green"
                                  : "fa fa-close text-red"
                              }
                            ></i>
                          </label>
                          <h5 className="m-0">
                            {this.state.lead.contact_number
                              ? common_helper.formatPhoneNumber(
                                  this.state.lead.contact_number
                                )
                              : ""}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Date Of Birth</label>

                          <h5 className="m-0">
                            {this.state.lead.date_of_birth
                              ? this.state.lead.date_of_birth
                                  .split("-")
                                  .reverse()
                                  .join("-")
                              : "Null"}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Address</label>
                          <h5 className="m-0">
                            {this.state.lead.address
                              ? this.state.lead.address
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">City</label>
                          <h5 className="m-0">
                            {this.state.lead.city &&
                            typeof this.state.lead["city"] === "object"
                              ? this.state.lead.city.city_name
                              : "Null"}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Province</label>
                          <h5 className="m-0">
                            {this.state.lead.state &&
                            typeof this.state.lead["state"] === "object"
                              ? this.state.lead.state.state_name
                              : "Null"}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Postal Code</label>
                          <h5 className="m-0">
                            {this.state.lead.pincode
                              ? this.state.lead.pincode
                              : "Null"}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">SIN Number</label>
                          <h5 className="m-0">
                            {this.state.lead.sin_number
                              ? this.state.lead.sin_number
                              : "Null"}
                          </h5>
                        </div>
                        {/*
									<div className="mb-4 col-md-4">
										<label className="text-muted">Landmark</label>
										<h5 className="m-0">{(this.state.lead.landmark)?this.state.lead.landmark:''}, 
										</h5>
									</div> */}
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">
                            Driving License No.
                          </label>
                          <h5 className="m-0">
                            {this.state.lead.driving_license
                              ? this.state.lead.driving_license
                              : "Null"}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Loan Amount</label>
                          <h5 className="m-0">
                            {this.state.loan_amount
                              ? this.state.loan_amount
                              : "0"}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Previous Loan</label>
                          <h5 className="m-0">
                            {this.state.lead.previous_loan === "1"
                              ? "Yes"
                              : "No"}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">
                            Customer Comment{" "}
                          </label>
                          <h5 className="m-0">
                            {this.state.lead.comment
                              ? this.state.lead.comment
                              : "Null"}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Campaign Name</label>
                          <h5 className="m-0">
                            {this.state.lead.campaign_id
                              ? this.state.lead.campaign_id
                              : "Null"}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Source Of Lead</label>
                          <h5 className="m-0">
                            {this.state.lead.source_of_lead
                              ? this.state.lead.source_of_lead
                              : "Null"}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Source IP</label>
                          <h5 className="m-0">
                            {this.state.lead.source_ip
                              ? this.state.lead.source_ip
                              : "Null"}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Created</label>
                          <h5 className="m-0">
                            {common_helper.getDateTime(
                              this.state.lead.createdAt
                            )}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Last Updated</label>
                          <h5 className="m-0">
                            {common_helper.getDateTime(
                              this.state.lead.updatedAt
                            )}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-4">
                    <header className="card-header pt-4 pb-4 bg-white">
                      <h4 className="m-0 bold">Call Details</h4>
                    </header>
                    <div className="card-body">
                      <div className="row">
                        {this.state.lead.aws_contactDetails ? (
                          <div className="mb-12 col-md-12">
                            <table className="table">
                              <tr>
                                <th>Initiation Time</th>
                                <th>Agent Time</th>
                                <th>Disconnect Time</th>
                              </tr>
                              <tr>
                                <td>
                                  {common_helper.getDateTime(
                                    this.state.lead.aws_contactDetails
                                      ?.start_time
                                  )}
                                </td>
                                <td>
                                  {this.state.lead.aws_contactDetails
                                    ?.recording_duration
                                    ? getTime(
                                        this.state.lead.aws_contactDetails
                                          ?.recording_duration
                                      )
                                    : ""}
                                </td>
                                <td>
                                  {common_helper.getDateTime(
                                    new Date(
                                      new Date(
                                        this.state.lead.aws_contactDetails?.start_time
                                      ).getTime() +
                                        this.state.lead.aws_contactDetails
                                          ?.recording_duration *
                                          1000
                                    )
                                  )}
                                </td>
                              </tr>
                            </table>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="mb-12 col-md-12">
                          {this.state.lead.aws_contactId ? (
                            <AudioPlayer
                              contactId={this.state.lead.aws_contactId}
                              leadId={this.state.lead.id}
                              recording={
                                this.state.lead.aws_contactDetails?.recording
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-4">
                    <header className="card-header pt-4 pb-4 bg-white">
                      <h4 className="m-0 bold">Property Detail</h4>
                    </header>
                    <div className="card-body">
                      <div className="row">
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Property Address</label>
                          <h5 className="m-0">
                            {this.state.lead.property_address
                              ? this.state.lead.property_address
                              : "NA"}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Property Value</label>
                          <h5 className="m-0">
                            {this.state.property_value
                              ? this.state.property_value
                              : "NA"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-4">
                    <header className="card-header pt-4 pb-4 bg-white">
                      <h4 className="m-0 bold">Agent Detail</h4>
                    </header>
                    <div className="card-body">
                      <div className="row">
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Agent Name</label>
                          <h5 className="m-0">
                            {this.state.lead.user != null
                              ? this.state.lead.user.full_name
                              : ""}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Agent Email </label>
                          <h5 className="m-0">
                            {this.state.lead.user != null
                              ? this.state.lead.user.email_id
                              : ""}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">
                            Agent Contact Number
                          </label>
                          <h5 className="m-0">
                            {this.state.lead.user != null
                              ? common_helper.formatPhoneNumber(
                                  this.state.lead.user.contact_number
                                )
                              : ""}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card mb-4">
                    <header className="card-header pt-4 pb-4 bg-white">
                      <h4 className="m-0 bold">Documents</h4>
                    </header>

                    <div className="card-body">
                      <div className="form-group mb-3">
                        <select
                          className="form-control selectpicker"
                          name="doc_name"
                          data-size="7"
                          value={this.state.doc_name}
                          onChange={this.onFileTitleChange}
                        >
                          <option value="">Select Document Type</option>
                          {typeof this.state.all_document === "object"
                            ? this.state.all_document.map((all_d) => {
                                return <option value={all_d}>{all_d}</option>;
                              })
                            : ""}
                        </select>

                        <span className="error">
                          {this.state.fileError["doc_name"]}
                        </span>
                      </div>
                      <div className="form-group">
                        <Dropzone
                          onDrop={this.onDrop}
                          accept="image/*,application/pdf,.doc,.docx"
                          maxFiles={1}
                          maxSize={5242880 * 10}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div
                                {...getRootProps({
                                  className: "dropzone text-center drop-file",
                                })}
                              >
                                <input {...getInputProps()} name="lead_doc" />
                                <p>
                                  <img src={file_icon} alt="File" />
                                  <br />
                                  <span className="text-large d-block text-black">
                                    Drag 'n' drop file here, or
                                  </span>{" "}
                                  <u className="text-secondary">Browse</u>
                                  <br />
                                  <span className="text-muted">
                                    click to select files and allowed file type
                                    are image, pdf and doc
                                  </span>
                                </p>
                              </div>
                              <div>
                                <ul className="mt-2 p-0">{files}</ul>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                      <div className="text-center">
                        <span className="error">
                          {this.state.fileError["doc_file"]}
                        </span>
                        <div className="mt-3">
                          <button
                            type="submit"
                            class="btn btn-secondary"
                            onClick={this.onFileUpload}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-striped table-hover dashboard-table v-middle">
                          <thead className="bg-white">
                            <tr>
                              <th>File</th>
                              <th>Document Name</th>
                              <th>Uploaded Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {typeof this.state.lead_docs[0] === "object"
                              ? this.state.lead_docs.map((doc, index) => {
                                  return (
                                    <tr>
                                      <td width="100">
                                        {doc.file_type === "pdf" ? (
                                          <img
                                            src={pdf_icon}
                                            alt="PDF"
                                            width="24"
                                          />
                                        ) : (
                                          <img
                                            src={
                                              Appconfig.api_base_url +
                                              "uploads/leads/" +
                                              doc.doc_image
                                            }
                                            alt="file"
                                            width="24"
                                          />
                                        )}
                                      </td>
                                      <td>{doc.doc_name}</td>
                                      <td>
                                        {common_helper.getDate(doc.createdAt)}
                                      </td>
                                      <td className="nowrap">
                                        <a
                                          target="_blank"
                                          href={
                                            Appconfig.api_base_url +
                                            "uploads/leads/" +
                                            doc.doc_image
                                          }
                                          className="btn-icon btn-view"
                                          title="View"
                                        >
                                          <img src={view_icon} alt="view" />
                                        </a>
                                        {doc.is_deleted == 1 ? (
                                          <button
                                            className="btn-icon btn-view"
                                            title="Un use"
                                          >
                                            <img
                                              src={close}
                                              alt="Mark Use"
                                              data-id={doc.id}
                                              onClick={(e) => {
                                                if (
                                                  window.confirm(
                                                    "Are you sure you want to Marke Unuse this document."
                                                  )
                                                ) {
                                                  this.hendelClickDelete(
                                                    doc.id,
                                                    e
                                                  );
                                                }
                                              }}
                                            />
                                          </button>
                                        ) : (
                                          <button
                                            className="btn-icon btn-view"
                                            title="Use"
                                          >
                                            <img
                                              src={checkmark}
                                              alt="Mark Unuse"
                                              data-id={doc.id}
                                              onClick={(e) => {
                                                if (
                                                  window.confirm(
                                                    "Are you sure you want to Marke Unuse this document."
                                                  )
                                                ) {
                                                  this.hendelClickDelete(
                                                    doc.id,
                                                    e
                                                  );
                                                }
                                              }}
                                            />
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })
                              : ""}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5">
                  <div className="card">
                    <header className="card-header d-flex align-items-center pt-4 pb-4 bg-white">
                      <h4 className="m-0 bold">Lead Status</h4>
                      <div class="ml-auto">
                        {this.state.reject_button_enable === true ? (
                          <button
                            className="btn btn-primary"
                            onClick={this.handleReject}
                          >
                            Reject Lead
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </header>
                    {this.state.flashMessage ? this.state.flashMessage : ""}

                    <form onSubmit={this.handleSubmit}>
                      <div className="card-body pb-0">
                        <div className="row">
                          <div className="mb-sm-0 mb-3 col-sm-6">
                            <label for="">Lead Stage</label>
                            <select
                              className="form-control selectpicker"
                              name="lead_stage"
                              id="stage-id"
                              data-size="7"
                              value={this.state.lead.lead_stage}
                              onChange={this.handleStageChange}
                              ref={this.stageInput}
                              disabled
                            >
                              <option value="">Select lead stage</option>
                              {typeof this.state.stagingList === "object"
                                ? this.state.stagingList.map((stage_is) => {
                                    return (
                                      <option value={stage_is.id}>
                                        {stage_is.lead_process_stage_name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                            <span className="error">
                              {this.state.errors["lead_stage"]}
                            </span>
                          </div>

                          <div className="mb-sm-0 mb-3 col-sm-6">
                            <label for="">Lead Status</label>
                            <select
                              className="form-control selectpicker"
                              id="status-id"
                              name="status"
                              data-size="7"
                              value={this.state.lead.status}
                              onChange={this.handleStatusChange}
                              ref={this.statusInput}
                              disabled
                            >
                              <option>Select status</option>
                              {typeof this.state.statusList === "object"
                                ? this.state.statusList.map((status_is) => {
                                    return (
                                      <option value={status_is.id}>
                                        {status_is.lead_process_status_name}
                                      </option>
                                    );
                                  })
                                : ""}
                            </select>
                            <span className="error">
                              {this.state.errors["status"]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {this.state.reject_enabled === true ? (
                        <>
                          <div className="card-body pt-0 pb-0">
                            <h4 className="mt-0 mb-4">Rejection Detail</h4>
                            <div className="d-block">
                              <div className="row align-items-center">
                                <div className="col-sm-6 form-group">
                                  <select
                                    className="form-control selectpicker"
                                    name="rejection_reason"
                                    id="rejection_reason"
                                    data-size="7"
                                    value={this.state.rejection_reason}
                                    onChange={this.handleRejectionReasonChange}
                                    ref={this.rejectionReasonInput}
                                  >
                                    <option value="">
                                      Select Reject Reason
                                    </option>
                                    <option value="Incomplete details">
                                      Incomplete details
                                    </option>
                                    <option value="Not responding customers">
                                      Not responding customers
                                    </option>
                                    <option value="Failing on Credit Parameters set up by lenders">
                                      Failing on Credit Parameters set up by
                                      lenders
                                    </option>
                                    <option value="Out of Province leads">
                                      Out of Province leads
                                    </option>
                                    <option value="Product not available">
                                      Product not available
                                    </option>
                                    <option value="Others">Others</option>
                                  </select>
                                  <span className="error">
                                    {this.state.errors["rejection_reason"]}
                                  </span>
                                </div>
                              </div>

                              <div className="form-group">
                                <label for="">Rejection Remark</label>
                                <textarea
                                  name="rejection_remark"
                                  className="form-control"
                                  maxlength="250"
                                  placeholder="Enter your comment here..."
                                  value={this.state.rejection_remark}
                                  onChange={this.handleRejectionRemarkChange}
                                  ref={this.rejectionRemarkInput}
                                ></textarea>
                                <span className="error">
                                  {this.state.errors["rejection_remark"]}
                                </span>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      ) : (
                        <>
                          <div className="card-body pt-0 pb-0">
                            <h4 className="mt-0 mb-4">Client Details</h4>
                            <div className="d-block">
                              {this.state.showClientDetail === true ? (
                                <div className="row align-items-center">
                                  <div className="col-sm-6 form-group">
                                    <div className="btn-checkbox">
                                      <input
                                        type="checkbox"
                                        name="client_contacted"
                                        id="client_contacted"
                                        value="client_contacted"
                                        onChange={
                                          this.handleClientContactedChange
                                        }
                                        ref={this.clientContactedInput}
                                        checked={
                                          this.state.client_contacted_status
                                        }
                                        disabled={
                                          this.state.client_contacted_disabled
                                        }
                                      />
                                      <label for="client_contacted">
                                        Client Contacted
                                      </label>
                                    </div>
                                    <span className="error">
                                      {this.state.errors["client_contacted"]}
                                    </span>
                                  </div>
                                  <div className="col-sm-6 form-group">
                                    <select
                                      className="form-control selectpicker"
                                      name="client_response"
                                      id="client_response"
                                      data-size="7"
                                      value={this.state.client_response}
                                      onChange={this.handleClientResponseChange}
                                      ref={this.clientResponseInput}
                                      disabled={
                                        this.state.client_response_disabled
                                      }
                                    >
                                      <option value="">
                                        Select Client Response
                                      </option>
                                      <option value="Willing to Proceed">
                                        Willing to Proceed
                                      </option>
                                      <option value="Not Willing to Proceed">
                                        Not Willing to Proceed
                                      </option>
                                      <option value="Client Not Responding">
                                        Client Not Responding
                                      </option>
                                    </select>
                                    <span className="error">
                                      {this.state.errors["client_response"]}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="row align-items-center">
                                {this.state.showAppointmentDate === true ? (
                                  <div
                                    className="form-group col-sm-6"
                                    id="appointment_datetime_id"
                                  >
                                    <label for="">Appointment Date/Time</label>
                                    <input
                                      type="text"
                                      name="appointment_datetime"
                                      id="appointment_datetime"
                                      className="form-control date-input"
                                      placeholder="Select Appointment Date/Time"
                                      value={this.state.appointment_datetime}
                                      onChange={
                                        this.handleAppointmentDateTimeChange
                                      }
                                      ref={this.adtInput}
                                      autoComplete="off"
                                      readOnly
                                    />
                                    <span className="error">
                                      {
                                        this.state.errors[
                                          "appointment_datetime"
                                        ]
                                      }
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.showClientMeeting === true ? (
                                  <div
                                    className="form-group col-sm-6"
                                    id="client_meeting_id"
                                  >
                                    <label for="" className="d-sm-block d-none">
                                      &nbsp;
                                    </label>
                                    <div className="btn-checkbox">
                                      <input
                                        type="checkbox"
                                        name="client_meeting"
                                        id="client_meeting"
                                        value="client_meeting"
                                        onChange={
                                          this.handleClientMeetingChange
                                        }
                                        ref={this.clientMeetingInput}
                                        checked={
                                          this.state.client_meeting_status
                                        }
                                        disabled={
                                          this.state.client_meeting_disabled
                                        }
                                      />
                                      <label for="client_meeting">
                                        Client Meeting
                                      </label>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="form-group">
                                <label for="">Client Remark</label>
                                <textarea
                                  name="comment"
                                  className="form-control"
                                  maxlength="250"
                                  placeholder="Enter your comment here..."
                                  value={this.state.comment}
                                  onChange={this.handleCommentChange}
                                  ref={this.commentInput}
                                  readOnly={this.state.comment_disabled}
                                ></textarea>
                                <span className="error">
                                  {this.state.errors["comment"]}
                                </span>
                              </div>
                            </div>
                          </div>

                          <hr />
                          <div className="card-body pt-0 pb-0">
                            <h4 className="mt-0 mb-4">Lead Details</h4>
                            {this.state.showLeadDetail === true ? (
                              <div className="d-block" id="lead_detail_id">
                                <div className="row align-items-center">
                                  <div className="form-group col-sm-6">
                                    <div className="btn-checkbox">
                                      <input
                                        type="checkbox"
                                        name="application_initiated"
                                        id="application_initiated"
                                        value="application_initiated"
                                        onChange={
                                          this.handleApplicationInitiatedChange
                                        }
                                        ref={this.applicationInitiatedInput}
                                        checked={
                                          this.state
                                            .application_initiated_status
                                        }
                                        disabled={
                                          this.state
                                            .application_initiated_disabled
                                        }
                                      />
                                      <label for="application_initiated">
                                        Application Initiated
                                      </label>
                                    </div>
                                    <span className="error">
                                      {
                                        this.state.errors[
                                          "application_initiated"
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div className="form-group col-sm-6">
                                    <select
                                      className="form-control selectpicker"
                                      name="document_collection_status"
                                      data-size="7"
                                      value={
                                        this.state.document_collection_status
                                      }
                                      onChange={
                                        this.handleDocumentCollectionChange
                                      }
                                      ref={this.documentCollectionInput}
                                      disabled={
                                        this.state
                                          .document_collection_status_disabled
                                      }
                                    >
                                      <option value="">
                                        Select Document Collection Status
                                      </option>
                                      <option value="Not Initiated">
                                        Not Initiated
                                      </option>
                                      <option value="In Progress">
                                        In Progress
                                      </option>
                                      <option value="Completed">
                                        Completed
                                      </option>
                                    </select>
                                    <span className="error">
                                      {
                                        this.state.errors[
                                          "document_collection_status"
                                        ]
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label for="">Lead Remark</label>
                                  <textarea
                                    name="lead_remark"
                                    className="form-control"
                                    maxlength="250"
                                    placeholder="Enter your remark here..."
                                    value={this.state.lead_remark}
                                    onChange={this.handleLeadRemarkChange}
                                    ref={this.leadRemarkInput}
                                    readOnly={this.state.lead_remark_disabled}
                                  ></textarea>
                                  <span className="error">
                                    {this.state.errors["lead_remark"]}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <hr />
                          <div className="card-body pt-0 pb-0">
                            <h4 className="mt-0 mb-4">Lender Details</h4>
                            {this.state.showLenderDetail === true ? (
                              <div className="d-block" id="lender_detail_id">
                                <div className="form-group btn-checkbox">
                                  <input
                                    type="checkbox"
                                    name="lender_submission"
                                    id="lender_submission"
                                    value="lender_submission"
                                    onChange={this.handleLenderSubmissionChange}
                                    ref={this.lenderSubmissionInput}
                                    checked={
                                      this.state.lender_submission_status
                                    }
                                    disabled={
                                      this.state.lender_submission_disabled
                                    }
                                  />
                                  <label for="lender_submission">
                                    Lender Submission
                                  </label>
                                </div>
                                <span className="error">
                                  {this.state.errors["lender_submission"]}
                                </span>
                                <div className="row">
                                  <div className="form-group col-sm-6">
                                    <label for="">Lender Category</label>
                                    <select
                                      className="form-control selectpicker"
                                      name="lender_category"
                                      id="lender_category"
                                      data-size="7"
                                      value={this.state.lender_category}
                                      onChange={this.handleLenderCatChange}
                                      ref={this.lenderCatInput}
                                      disabled={
                                        this.state.lender_category_disabled
                                      }
                                    >
                                      <option value="">
                                        Select Lender Category
                                      </option>
                                      {typeof this.state.lenderCatList ===
                                      "object"
                                        ? this.state.lenderCatList.map(
                                            (cat) => {
                                              return (
                                                <option value={cat.id}>
                                                  {cat.lender_cat_name}
                                                </option>
                                              );
                                            }
                                          )
                                        : ""}
                                    </select>
                                    <span className="error">
                                      {this.state.errors["lead_stage"]}
                                    </span>
                                  </div>

                                  <div className="form-group col-sm-6">
                                    <label for="">Lender Name</label>
                                    <select
                                      className="form-control selectpicker"
                                      name="lender_name"
                                      data-live-search="true"
                                      id="lender_name"
                                      data-size="7"
                                      value={this.state.lead.lender_name}
                                      onChange={this.handleLenderNameChange}
                                      ref={this.lenderNameInput}
                                      autoComplete="nope"
                                      disabled={this.state.lender_name_disabled}
                                    >
                                      <option value="">
                                        Select Lender Name
                                      </option>
                                      {typeof this.state.lendersByCat ===
                                      "object"
                                        ? this.state.lendersByCat.map(
                                            (lenderData) => {
                                              return (
                                                <option
                                                  value={lenderData.lender_name}
                                                >
                                                  {lenderData.lender_name}
                                                </option>
                                              );
                                            }
                                          )
                                        : ""}
                                    </select>
                                    <span className="error">
                                      {this.state.errors["lender_name"]}
                                    </span>
                                  </div>

                                  <div className="form-group col-sm-6">
                                    <label for="">Lender Stage</label>
                                    <select
                                      className="form-control selectpicker"
                                      name="lender_stage"
                                      data-size="7"
                                      value={this.state.lender_stage}
                                      onChange={this.handleLenderStageChange}
                                      ref={this.lenderStageInput}
                                      disabled={
                                        this.state.lender_stage_disabled
                                      }
                                    >
                                      <option value="">
                                        Select Lender Stage
                                      </option>
                                      <option value="Declined">Declined</option>
                                      <option value="Approved">Approved</option>
                                      <option value="Broker Complete">
                                        Broker Complete
                                      </option>
                                      <option value="Funded">Funded</option>
                                    </select>
                                    <span className="error">
                                      {this.state.errors["lender_stage"]}
                                    </span>
                                  </div>
                                  {this.state.expected_revenue_show ? (
                                    <div className="form-group col-sm-6">
                                      <label for="">Expected Revenue</label>
                                      <input
                                        type="text"
                                        className="form-control is-currency"
                                        name="expected_revenue"
                                        maxlength="10"
                                        placeholder="Expected Revenue"
                                        value={this.state.expected_revenue}
                                        onChange={
                                          this.handleExpectedRevenueChange
                                        }
                                        ref={this.expectedRevenueInput}
                                        autoComplete="nope"
                                        readOnly={
                                          this.state.expected_revenue_disabled
                                        }
                                      />
                                      <span className="error">
                                        {this.state.errors["expected_revenue"]}
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div className="form-group">
                                  <label for="">Lender Remark</label>
                                  <textarea
                                    name="lender_remark"
                                    className="form-control"
                                    maxlength="250"
                                    placeholder="Enter your remark here..."
                                    value={this.state.lender_remark}
                                    onChange={this.handleLenderRemarkChange}
                                    ref={this.lenderRemarkInput}
                                    readOnly={this.state.lender_remark_disabled}
                                  ></textarea>
                                  <span className="error">
                                    {this.state.errors["lender_remark"]}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      )}

                      {this.state.submit_button_enable === true ? (
                        <>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-sm-6">
                                <button
                                  type="submit"
                                  className="btn btn-lg btn-block btn-primary"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      ) : (
                        ""
                      )}
                    </form>

                    <div>
                      <div className="card-body card-body-submission">
                        {this.state.lead_status_logs
                          ? this.state.lead_status_logs.map((s_log, index) => {
                              return (
                                <div className="subm-item mb-4">
                                  <div className="d-flex mb-3">
                                    <strong className="mr-2">Status:</strong>
                                    <div className="flex-fill">
                                      {s_log.lead_process_status != null
                                        ? s_log.lead_process_status
                                            .lead_process_status_name
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="d-flex mb-3">
                                    <strong className="mr-2">Stage:</strong>
                                    <div className="flex-fill">
                                      {s_log.lead_process_stage != null
                                        ? s_log.lead_process_stage
                                            .lead_process_stage_name
                                        : ""}
                                    </div>
                                  </div>
                                  <div className="d-flex mb-3">
                                    <strong className="mr-2">Comment:</strong>
                                    <div className="flex-fill">
                                      {s_log.admin_comment}.
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center pt-2">
                                    <div className="text-muted">
                                      {common_helper.getDateTime(
                                        s_log.createdAt
                                      )}
                                    </div>
                                    <div className="text-danger ml-auto">
                                      Posted by - {s_log.user.full_name}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

const LeadsView = (props) => {
  const { globalFilters, setGlobalFilters } = useContext(FilterContext);
  return (
    <LeadView
      {...props}
      globalFilters={globalFilters}
      setGlobalFilters={setGlobalFilters}
    />
  );
};
export default LeadsView;
