import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerformerListItem from "../../dashboard/PerformerListItem";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import * as Appconfig from "../../../helpers/config";
import $ from "jquery";
//http://jsfiddle.net/izothep/myork5sa/

import Highcharts from "./Highcharts";

class LeadChannels extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channelPieChart: this.props.channelPieChart,
      PerformerlistArray: "",
      apiError: false,
      error: null,
    };
  }

  componentDidMount() {
    let self = this;
    Highcharts.setOptions({
      lang: {
        drillUpText: "◁",
      },
    });
    // Create the chart
    if (self.state.channelPieChart.series) {
      Highcharts.chart("channel_container", {
        chart: {
          type: "pie",
          events: {
            drilldown: function (e) {
              var chart = this;
              this.setTitle({ text: "Channel Leads : " + e.point.name });
            },
            drillup: function (e) {
              this.setTitle({ text: "Channel Leads" });
            },
          },
        },
        title: {
          text: "Channel Leads",
          align: "left",
          style: {
            fontWeight: "bold",
            fontSize: "18px",
            fontFamily: "Poppins, sans-serif",
          },
        },
        subtitle: {
          text: "",
        },
        legend: {
          itemStyle: {
            fontWeight: "regular",
            fontFamily: "Poppins, sans-serif",
          },
          itemMarginBottom: 5,
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: { enabled: false },
            showInLegend: true,
          },
        },

        //plotOptions: { series: {dataLabels: {enabled: true,format: '{point.name}: {point.y:.1f}%'}}},

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <br>{point.percentage:.1f} %<br>value: {point.y}',
          backgroundColor: "rgba(255,255,255,0.9)",
          borderRadius: 6,
          borderColor: "#dddddd",
          style: { fontWeight: "regular", fontFamily: "Poppins, sans-serif" },
        },
        series: [
          {
            name: "Lead Channel",
            colorByPoint: true,
            data: self.state.channelPieChart.series,
          },
        ],
        drilldown: {
          drillUpButton: {
            relativeTo: "spacingBox",
            position: {
              y: 0,
              x: -5,
            },

            theme: {
              fill: "#eeeeee",
              "stroke-width": 1,
              stroke: "#eeeeee",
              width: 20,
              height: 20,
              r: 20,
              style: {
                color: "black",
              },
              states: {
                hover: {
                  fill: "#eeeeee",
                },

                select: {
                  stroke: "#eeeeee",
                  fill: "#eeeeee",
                },
              },
            },
          },
          series: self.state.channelPieChart.drilldownSeries,
        },
      });
    }
  }

  render() {
    return (
      <div className="col-md-4 mb-2">
        <div className="card h-100 hover-shadow p-4">
          {/* <h2 className="mb-0 h4">Channel Leads</h2>
                     <div className="performer-head d-flex border-bottom pb-2 mb-1 bold">
                        <header className="head-title  d-flex align-items-center">    
                        <div className="filter-group ml-auto d-xl-flex align-items-center">
                            <div className="datepicker p-0 d-xl-flex align-items-center input-daterange">
                                <div className="select-filter mr-xl-3 dropdown-toggle">
                                <label className="d-block text-muted m-0" for="FilterDateFrom">From Date</label>
                                <input type="text" id="startDate" name="filterStartDate" className="form-control text-left" value={this.state.value} placeholder="DD-MM-YY"/>
                                </div>
                                <div className="select-filter dropdown-toggle mr-xl-3">
                                <label className="d-block text-muted m-0" for="FilterDateTo">To Date</label>
                                <input type="text" className="form-control text-left" id="endDate" name="filterEndDate" value={this.state.value} placeholder="DD-MM-YY" />
                                </div>
                            </div>
                            </div>
                            <div className="filter-backdrop"></div>
                        </header>
                    </div>*/}
          <div id="channel_container"></div>
        </div>
      </div>
    );
  }
}

export default LeadChannels;
