import React, { useState } from "react";
import BackIconWhiteBg from "./backIconWhiteBg.png";
// import useMobileDetect from "../../hooks/useMobileDetect";

const CardWrapper = ({
  width = "auto",
  height = "auto",
  children,
  title,
  titleAlign = "center",
  onBackPress,
  backLabel,
  level,
  wrapperStyle = {},
  className,
}) => {
  // const { isMobile } = useMobileDetect();
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: 10,
        boxShadow: isHover
          ? "-6px 2px 23px -2px rgba(0,0,0,0.5)"
          : "0px 2px 0px 0px rgba(0,0,0,0.5)",
        height,
        // width,
        maxHeight: height,
        margin: "5px",
        marginBottom: 4,
        marginTop: 4,
        padding: "10px",
        paddingRight: "10px",
        transition: "all 0.5s",
        background: "#fff",
        ...wrapperStyle,
      }}
      className={"col-sm-1 col-md-12 " + className}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {level > 1 && (
          <div
            appearance={"primary"}
            style={{
              width: 25,
              alignItems: "center",
              display: "flex",
              height: 30,
              marginLeft: 30,
              marginTop: 4,
              cursor: "pointer",
            }}
            onClick={onBackPress}
          >
            <img style={{ width: 25, height: 25 }} src={BackIconWhiteBg} />
          </div>
        )}
        {title ? (
          <div
            style={{
              width: level && level > 1 ? "91%" : "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: titleAlign,
              fontSize: 14,
              fontWeight: "bold",
              textTransform: "capitalize",
            }}
          >
            {title}
          </div>
        ) : null}
      </div>
      {backLabel && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: titleAlign,
            fontSize: 14,
            fontWeight: "bold",
            // height: 30,
            marginBottom: 8,
            marginLeft: level && level > 1 ? 25 : 0,
          }}
        >
          {backLabel}
        </div>
      )}
      {children}
    </div>
  );
};

export default CardWrapper;
