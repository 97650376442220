import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";

export default class UserView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      totalUsers: 0,
      isLoaded: true,
      apiError: false,
      token: "",
    };

    var userInfo = localStorage.getItem("lmsuser");
    userInfo = JSON.parse(userInfo);
    var token = "";
    if (userInfo != "undefined" && userInfo.hasOwnProperty("token")) {
      token = userInfo.token;
    }

    var userId = props.match.params.userId;
    userId = parseInt(userId);
    if (token && userId > 0) {
      var token = token;
      fetch(Appconfig.api_base_url + "user/view/" + userId, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                userList: result.data.rows,
                totalUsers: result.data.count,
                pageCount: Math.ceil(result.data.count / this.state.perPage),
              });
              console.log("result:", result.data.rows);
            } else if (result.status === 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 403) {
              this.setState({ apiError: result.message }, () => {
                //~ setTimeout(()=>{
                //~ console.log("aaaaaaaaaaaa");
                //~ this.setState({apiError:false},()=>{
                //~ window.location.href=Appconfig.site_url;
                //~ });
                //~ },3000);
              });
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      //window.location.href=Appconfig.site_url;
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container">
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/users" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">User Detail</h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}

          <div className="row">
            {this.state.isLoaded === true ? (
              <div className="col-md-12">
                <div className="card mb-4">
                  <header className="card-header py-3 bg-white">
                    <h4 className="m-0 bold">
                      {this.state.user.full_name
                        ? this.state.user.full_name
                        : ""}
                    </h4>
                  </header>
                  <div className="card-body">
                    <div className="row">
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">Name</label>
                        <h5 className="m-0">
                          {this.state.user.full_name
                            ? this.state.user.full_name
                            : ""}
                        </h5>
                      </div>
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">Contact No</label>
                        <h5 className="m-0">
                          {this.state.user.contact_number
                            ? this.state.user.contact_number
                            : ""}
                        </h5>
                      </div>
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">Email</label>
                        <h5 className="m-0">
                          {this.state.user.email_id
                            ? this.state.user.email_id
                            : ""}
                        </h5>
                      </div>

                      <div className="mb-4 col-md-4">
                        <label className="text-muted">DOB</label>
                        <h5 className="m-0">
                          {this.state.user.dob ? this.state.user.dob : ""}
                        </h5>
                      </div>
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">Gender</label>
                        <h5 className="m-0">
                          {this.state.user.gender ? this.state.user.gender : ""}
                        </h5>
                      </div>
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">User Role</label>
                        <h5 className="m-0">
                          {typeof this.state.user["user_role"] === "object"
                            ? this.state.user["user_role"].role_name
                            : ""}
                        </h5>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">Country</label>
                        <h5 className="m-0">
                          {typeof this.state.user["country"] === "object"
                            ? this.state.user.country.country_name
                            : ""}
                        </h5>
                      </div>
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">State</label>
                        <h5 className="m-0">
                          {typeof this.state.user["state"] === "object"
                            ? this.state.user.state.state_name
                            : ""}
                        </h5>
                      </div>
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">City</label>
                        <h5 className="m-0">
                          {typeof this.state.user["city"] === "object"
                            ? this.state.user.city.city_name
                            : ""}
                        </h5>
                      </div>

                      <div className="mb-4 col-md-4">
                        <label className="text-muted">Status</label>
                        <h5 className="m-0">
                          {this.state.user.status === 1 ? "Active" : "Inactive"}
                        </h5>
                      </div>
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">Created date</label>
                        <h5 className="m-0">
                          {this.state.user.createdAt
                            ? this.state.user.createdAt
                            : ""}
                        </h5>
                      </div>
                      <div className="mb-4 col-md-4">
                        <label className="text-muted">last_login</label>
                        <h5 className="m-0">
                          {this.state.user.last_login
                            ? this.state.user.last_login
                            : "Null"}
                        </h5>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
