import React from "react";
import $ from "jquery";

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: props.error, showModal: false };
  }
  componentDidMount() {
    if (this.state.error) $(this.modal).modal("show");
    //$(this.modal).on('hidden.bs.modal', handleModalCloseClick);
  }
  componentWillUnmount() {
    $(this.modal).modal("hide");
    this.Setstate({ error: false });
  }
  render() {
    return (
      <div>
        <div
          className="modal fade"
          ref={(modal) => (this.modal = modal)}
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <p>{this.state.error}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
