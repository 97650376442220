import React from "react";
import { Link } from "react-router-dom";

import * as Appconfig from "../../helpers/config";
import $ from "jquery";
import TreeComponent from "../TreeComponent";
import REPORT from "../../services/report";
import moment from "moment";
import Dropdown from "../dropdown";

export default class leadDistrubutionReport extends React.Component {
  constructor(props) {
    super(props);
    this.keyObj = {};
    this.state = {
      data: [],
      filterEndDate: moment().format("DD-MM-YYYY"),
      filterStartDate: moment().format("01-MM-YYYY"),
      filterType: "supplier",
      isLoaded: true,
      is_loader: true,
      apiError: "",
    };
    this.receivedData();
  }

  // hendel Serch
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(
      {
        is_loader: true,
        filterStartDate: e.target.elements.filterStartDate.value,
        filterEndDate: e.target.elements.filterEndDate.value,
      },
      () => {
        this.receivedData();
      }
    );
  };

  // pagination

  // get data

  getRec = (data, obj) => {
    if (obj) {
      Object.keys(obj)?.map((key) => {
        if (key != "name" && key != "agent") {
          obj[key] = 0;
        }
      });
    }
    return data.map((item) => {
      if (item.agent) {
        this.getRec(item.agent, item);
        return item;
      } else {
        Object.keys(item).map((key) => {
          if (key != "name" && key != "agent") {
            this.keyObj[key] = 0;
            obj[key] += item[key];
          }
        });
        return item;
      }
    });
  };

  getDataMap = (data) => {
    const childData = [];
    const parentDataIndex = [];
    const dataParent = data.filter((item) => {
      if (!item.parentId) {
        parentDataIndex.push(item.id);
        return true;
      } else {
        childData.push(item);
        return false;
      }
    });
    const structure = [];
    let structureIndex = [];
    this.setState({ columnsData: childData[0] });
    childData.map((item) => {
      if (parentDataIndex.indexOf(item.parentId) > -1) {
        console.log("@@@@item.parentId", item.parentId);
        if (structureIndex.indexOf(item.parentId) > -1) {
          structure[structureIndex.indexOf(item.parentId)].items.push(item);
        } else {
          structureIndex.push(item.parentId);
          structure.push({
            ...dataParent[parentDataIndex.indexOf(item.parentId)],
            items: [item],
          });
        }
      }
    });
  };
  receivedData() {
    var token = Appconfig.getSessionToken();

    if (token) {
      REPORT.getLeadDistributionReport({
        fromDate: this.state.filterStartDate,
        toDate: this.state.filterEndDate,
        type: this.state.filterType,
      })
        .then(async (result) => {
          this.keyObj = {};
          this.getRec(result.data); //for calculating value of all agents
          if (result.status === 1) {
            this.setState({
              is_loader: false,
              data: this.getRec(result.data || []) || [],
              columnsData: this.keyObj,
            });
          } else if (result.status === 0) {
            this.setState({ apiError: result.message });
          } else if (result.status === 401) {
            this.setState({ apiError: result.message });
            window.location.href = Appconfig.site_url;
          }
        })
        .finally(() => {
          this.setState({
            is_loader: false,
          });
        });

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  componentDidMount() {
    let self = this;
    $(document).ready(function () {
      if (typeof self.state.cityList === "object") {
        $(".selectpicker").selectpicker();
      }

      $(".input-daterange").datepicker({
        autoclose: true,
        endDate: "0d",
        format: "dd-mm-yyyy",
        maxViewMode: 2,
        todayHighlight: true,
      });

      $(
        ".filter-trigger, .search-btn, .btn-search-close, .filter-backdrop"
      ).click(function () {
        $("body").toggleClass("is-search");
      });
    });
  }
  handleDropdownChange = (value) => {
    this.setState({ filterType: value });
  };
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <form onSubmit={this.handleSubmit}>
            <header className="head-title mb-3 d-flex align-items-center">
              <h1 className="h3 mb-0">Lead Distribution</h1>
              <button
                type="button"
                className="btn btn-secondary d-xl-none ml-auto filter-trigger"
              >
                Filter Search <i class=" fa fa-search ml-2"></i>
              </button>
              <div className="filter-group ml-auto d-xl-flex align-items-center">
                <Dropdown
                  value={this.state.filterType}
                  key={"filterType"}
                  field={"filterType"}
                  title={"Select Type"}
                  requestURL={
                    "misc/getRefrenceDomain/RD_LEAD_DISTRIBUTION_TYPE"
                  }
                  // multiSelect={true}
                  labelField={"label"}
                  valueField={"value"}
                  className={"mr-xl-3"}
                  onChange={this.handleDropdownChange}
                />
                <div className="datepicker p-0 d-xl-flex align-items-center input-daterange">
                  <div className="select-filter mr-xl-3 dropdown-toggle">
                    <label
                      className="d-block text-muted m-0"
                      for="FilterDateFrom"
                    >
                      From Date
                    </label>
                    <input
                      type="text"
                      id="startDate"
                      name="filterStartDate"
                      className="form-control text-left date-input"
                      value={this.state.filterStartDate}
                      placeholder="DD-MM-YY"
                    />
                  </div>
                  <div className="select-filter dropdown-toggle mr-xl-3">
                    <label
                      className="d-block text-muted m-0"
                      for="FilterDateTo"
                    >
                      To Date
                    </label>
                    <input
                      type="text"
                      className="form-control text-left date-input"
                      id="endDate"
                      name="filterEndDate"
                      value={this.state.filterEndDate}
                      placeholder="DD-MM-YY"
                    />
                  </div>
                </div>
                <div className="filter-search-btn">
                  <button className="search-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.144"
                      height="17.105"
                      viewBox="0 0 16.144 17.105"
                    >
                      <g transform="translate(-1041 -173)">
                        <g
                          transform="translate(1041 173)"
                          fill="none"
                          stroke="#050033"
                          stroke-width="1.5"
                        >
                          <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
                          <circle cx="7.5" cy="7.5" r="6.75" fill="none" />
                        </g>
                        <path
                          d="M1064.441,198.1l2.72,3.333"
                          transform="matrix(0.996, -0.087, 0.087, 0.996, -24.656, 81.065)"
                          fill="none"
                          stroke="#050033"
                          stroke-linecap="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                    <span class="d-xl-none">SEARCH</span>
                  </button>
                </div>
                <button type="button" className="btn-search-close">
                  &times;
                </button>
              </div>
              <div className="filter-backdrop"></div>
            </header>
          </form>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          {this.state.flashMessage ? this.state.flashMessage : ""}
          <div className="card" style={{ minHeight: window.innerHeight - 300 }}>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-striped table-hover dashboard-table">
                  <tbody>
                    <TreeComponent
                      data={this.state.data}
                      columnsData={this.state.columnsData}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const staticData = [
  {
    name: "Ajeet Gautam Team",
    agent: [
      { name: "Ajeet Gautam", "Private Mortgage": 2 },
      { name: "Amit Rana", "Private Mortgage": 1 },
      { name: "Barkha Batra", "Private Mortgage": 1 },
      { name: "Jignesh Patel", "Private Mortgage": 1 },
      { name: "Rohit Rana", "Private Mortgage": 1 },
    ],
  },
  {
    name: "Ali Yazdani Team",
    agent: [
      {
        name: "Ali Yazdani",
        HELOC: 1,
        "Private Mortgage": 1,
        "Lead Engineers Campaign": 7,
      },
      { name: "Amandeep Singh", HELOC: 1 },
      { name: "Ijlal Javed", HELOC: 1, Refinance: 1 },
      { name: "Nikunj Sharda", HELOC: 4, "Private Mortgage": 1 },
      { name: "Pawandeep Singh", HELOC: 1, "Private Mortgage": 1 },
    ],
  },
  {
    name: "Arnav Gupta Team",
    agent: [
      {
        name: "Arnav Gupta",
        HELOC: 8,
        Refinance: 1,
        "Private Mortgage": 5,
        "Facebook Ad Campaign": 12,
        "Lead Engineers Campaign": 6,
      },
    ],
  },
  {
    name: "AtmaRam Kubal Team",
    agent: [
      { name: "AtmaRam Kubal", "Lead Engineers Campaign": 1 },
      { name: "Gurpreet Dhaliwal", HELOC: 1 },
      { name: "Harminder Kaur", Manual: 5, "Facebook Ad Campaign": 50 },
      {
        name: "Parth Patel",
        HELOC: 3,
        Manual: 6,
        "Power of Sale": 3,
        "Second Mortgage": 1,
        "Private Mortgage": 11,
        "Facebook Ad Campaign": 25,
      },
      { name: "Poornima Chaba", HELOC: 2, "Facebook Ad Campaign": 25 },
      {
        name: "Pradip Kumar Maheshvari",
        HELOC: 4,
        "Power of Sale": 1,
        "Private Mortgage": 22,
        "Facebook Ad Campaign": 42,
      },
      {
        name: "Rabbiya Rizwan",
        HELOC: 4,
        "FBC Refinance": 1,
        "Power of Sale": 1,
        "Second Mortgage": 1,
        "Private Mortgage": 20,
        "Facebook Ad Campaign": 46,
      },
    ],
  },
  {
    name: "Bhavin Parekh Team",
    agent: [{ name: "Bhavin Parekh", "Private Mortgage": 9 }],
  },
  {
    name: "Davinder Powar Team",
    agent: [{ name: "Harman Dhaliwal", HELOC: 14 }],
  },
  {
    name: "Jagdeep Dhugga Team",
    agent: [{ name: "Jagdeep Dhugga", "Lead Engineers Campaign": 1 }],
  },
  {
    name: "Manvir Singh Team",
    agent: [
      { name: "Farhan Shaikh", HELOC: 1, "Private Mortgage": 3 },
      {
        name: "Manvir Singh",
        HELOC: 1,
        "Second Mortgage": 1,
        "Private Mortgage": 2,
      },
    ],
  },
  {
    name: "Muskan Muskan Team",
    agent: [
      {
        name: "Muskan Muskan",
        HELOC: 9,
        "Power of Sale": 6,
        "Shared Equity": 5,
        "Private Mortgage": 6,
        "Reverse Mortgage": 4,
        "Lead Engineers Campaign": 5,
      },
    ],
  },
  {
    name: "Parasdeep Singh Team",
    agent: [
      {
        name: "Parasdeep Singh",
        HELOC: 35,
        "Power of Sale": 11,
        "Shared Equity": 5,
        "Private Mortgage": 11,
        "Reverse Mortgage": 7,
        "Lead Engineers Campaign": 8,
      },
    ],
  },
  {
    name: "Pardeep Pabla Team",
    agent: [{ name: "Pardeep Pabla", HELOC: 3, "Lead Engineers Campaign": 3 }],
  },
  {
    name: "Parminder Bhatti Team",
    agent: [
      {
        name: "Hersh Dhillon",
        HELOC: 2,
        "Second Mortgage": 2,
        "Private Mortgage": 2,
      },
      {
        name: "Karan Walia",
        HELOC: 5,
        "Second Mortgage": 3,
        "Private Mortgage": 4,
      },
      {
        name: "Krishna Bhure",
        HELOC: 5,
        "Second Mortgage": 4,
        "Private Mortgage": 4,
      },
      {
        name: "Parminder Bhatti",
        HELOC: 13,
        "Radio Campaign": 27,
        "Second Mortgage": 5,
        "Private Mortgage": 8,
      },
    ],
  },
  {
    name: "Rashmeet Kaur Team",
    agent: [{ name: "Rashmeet Kaur", "Lead Engineers Campaign": 17 }],
  },
  {
    name: "Sheraz Shafiq Team",
    agent: [{ name: "Sheraz Shafiq", "Radio Campaign": 32 }],
  },
];
