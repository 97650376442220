import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../helpers/config";
import moment from "moment";

export default class ProfileEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rolesList: {},
      countryList: {},
      stateList: {},
      cityList: {},
      filterCityList: {},
      fields: {
        full_name: "",
        email_id: "",
        contact_number: "",
        role_id: "",
        country_id: "",
        state_id: "",
        city_id: "",
        gender: "",
        dob: "",
        state: "",
        status: "",
      },
      errors: {
        name: "",
        email_id: "",
        contact_number: "",
        role_id: "",
        country_id: "",
        state_id: "",
        city_id: "",
        gender: "",
        dob: "",
        status: "",
      },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      is_loader: true,
      apiError: false,
    };

    this.emailInput = React.createRef();
    this.nameInput = React.createRef();
    this.phoneInput = React.createRef();
    this.roleInput = React.createRef();
    this.countryInput = React.createRef();
    this.stateInput = React.createRef();
    this.cityInput = React.createRef();
    this.genderInput = React.createRef();
    this.dobInput = React.createRef();
    this.statusInput = React.createRef();

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleRoleChange = this.handleRoleChange.bind(this);

    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    let self = this;

    var userInfo = localStorage.getItem("lmsuser");
    userInfo = JSON.parse(userInfo);
    var token = "";
    if (userInfo != "undefined" && userInfo.hasOwnProperty("token")) {
      token = userInfo.token;
    }
    fetch(Appconfig.api_base_url + "profile/update", {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    })
      .then((res) => res.json())
      .then(
        async (result) => {
          console.log("result", result);
          if (result.status == 0) {
            this.setState({ apiError: result.message, isLoaded: false }, () => {
              console.log("aaaaaaaaaaaa");
            });
          } else if (result.status === 403) {
            this.setState({ apiError: result.message, isLoaded: false }, () => {
              console.log("aaaaaaaaaaaa");
            });
          } else if (result.status === 1) {
            console.log("countryList1", result.countries);
            console.log("countryList1", this.state.inputFocus);

            var filterCity = [];
            result.cities.map((city) => {
              if (city.state_id == result.data.state_id) {
                filterCity.push(city);
              }
            });
            console.log("filterCity", filterCity);

            var dob = result.data.dob,
              dob = dob.split("-").reverse().join("-");
            this.setState(
              {
                is_loader: false,
                name: result.data.full_name,
                email_id: result.data.email_id,
                contact_number: result.data.contact_number,
                role_id: result.data.role_id,
                country_id: result.data.country_id,
                state_id: result.data.state_id,
                city_id: result.data.city_id,
                gender: result.data.gender,
                status: result.data.status,
                dob: dob,
                countryList: result.countries,
                stateList: result.states,
                cityList: result.cities,
                filterCityList: filterCity,
                rolesList: result.roles,
              },
              () => {
                setTimeout(function () {
                  $(".selectpicker").selectpicker("refresh");
                }, 300);
                console.log("countryList2", typeof this.state.countryList);
              }
            );
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
          });
        }
      );
  }

  handleStateChange(event) {
    var stateId = event.target.value;
    var filterCity = [];
    this.setState({ state_id: event.target.value });

    this.state.cityList.map((city) => {
      if (city.state_id == stateId) {
        filterCity.push(city);
      }
    });
    console.log("filterCity", filterCity);
    this.setState({ filterCityList: filterCity });
    setTimeout(function () {
      $("#city-id").selectpicker("refresh");
    }, 500);
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email_id: event.target.value });
  }
  handlePhoneChange(event) {
    this.setState({ contact_number: event.target.value });
  }
  handleRoleChange(event) {
    this.setState({ role_id: event.target.value });
  }
  handleCountryChange(event) {
    this.setState({ country_id: event.target.value });
  }
  //handleStateChange(event)            {    this.setState({state_id: event.target.value});}

  handleCityChange(event) {
    this.setState({ city_id: event.target.value });
  }
  handleGenderChange(event) {
    this.setState({ gender: event.target.value });
  }
  handleDobChange(event) {
    this.setState({ dob: event.target.value });
  }

  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    //Form validation

    if (!this.state.status) {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }

    if (!this.state.city_id) {
      formIsValid = false;
      errors["city_id"] = "The city field is required.";
      this.cityInput.current.focus();
    }
    if (!this.state.state_id) {
      formIsValid = false;
      errors["state_id"] = "The Province field is required.";
      this.stateInput.current.focus();
    }
    if (!this.state.country_id) {
      formIsValid = false;
      errors["country_id"] = "The country field is required.";
      this.countryInput.current.focus();
    }

    if (!this.state.dob) {
      formIsValid = false;
      errors["dob"] = "The DOB field is required.";
      this.dobInput.current.focus();
    } else {
      var date = moment(this.state.dob);
      var dob = this.state.dob;
      dob = dob.split("-").reverse().join("-");
      var date = moment(dob);
      if (!date.isValid()) {
        formIsValid = false;
        errors["dob"] = "Please enter valid date.";
      }
    }
    if (!this.state.gender) {
      formIsValid = false;
      errors["gender"] = "The gender field is required.";
      this.genderInput.current.focus();
    }

    if (!this.state.contact_number) {
      formIsValid = false;
      errors["contact_number"] = "The contact number field required.";
      this.phoneInput.current.focus();
    } else {
      var mobile = this.state.contact_number;
      if (mobile.length == 10 && mobile > 1000000000) {
        errors["contact_number"] = "";
      } else {
        formIsValid = false;
        errors["contact_number"] = "Please enter valid 10 digit mobile.";
        this.phoneInput.current.focus();
      }
    }

    if (!this.state.email_id) {
      formIsValid = false;
      errors["email_id"] = "The email field required.";
      this.emailInput.current.focus();
    } else {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email_id)) {
        formIsValid = false;
        errors["email_id"] = "Please enter valid email address.";
      }
    }
    if (!this.state.name) {
      formIsValid = false;
      errors["name"] = "The name field is required.";
      this.nameInput.current.focus();
    }

    if (!this.state.role_id) {
      formIsValid = false;
      errors["role_id"] = "The role field is required.";
      this.roleInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var userInfo = localStorage.getItem("lmsuser");
      userInfo = JSON.parse(userInfo);
      //console.log("userInfo :"+userInfo)
      if (userInfo) {
        var token = userInfo.token;
        var settings = {
          url: Appconfig.api_base_url + "profile/update",
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            name: this.state.name,
            email_id: this.state.email_id,
            contact_number: this.state.contact_number,
            role_id: this.state.role_id,
            country_id: this.state.country_id,
            state_id: this.state.state_id,
            city_id: this.state.city_id,
            gender: this.state.gender,
            dob: this.state.dob,
            status: this.state.status,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          //localStorage.setItem("lmsuser", JSON.stringify(response.token));
          console.log("response", response);
          if (response.status === 1) {
            window.location.href = Appconfig.site_url + "/profile";
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            //window.location.href=Appconfig.site_url+'/users';
          }
        }); // end ajax
      } else {
        //window.location.href=Appconfig.site_url;
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.nameInput.current.focus();

    $("#dob")
      .datepicker({
        autoclose: true,
        //startDate: "-1d",
        format: "dd-mm-yyyy",
        //maxViewMode: 2,
        endDate: new Date(),
        todayHighlight: true,
      })
      .on("changeDate", (e) => {
        //  e here contains the extra attributes
        this.handleDobChange(e);
      });
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <header className="head-title mb-md-5 mb-4 d-flex align-items-center">
            <Link to="/profile" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Update Profile</h1>
          </header>

          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}

          <div className="card">
            <header className="card-header pt-3 pb-3 bg-white">
              <div className="recourd-number bold">Basic Details</div>
            </header>
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Roles<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        data-size="7"
                        name="role_id"
                        value={this.state.role_id}
                        onChange={this.handleRoleChange}
                        ref={this.roleInput}
                      >
                        <option value="">Select Role</option>
                        {typeof this.state.rolesList[0] === "object"
                          ? this.state.rolesList.map((role) => {
                              return (
                                <option value={role.id}>
                                  {role.role_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["role_id"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter your name"
                        value={this.state.name}
                        onChange={this.handleNameChange}
                        ref={this.nameInput}
                      />
                      <span className="error">{this.state.errors["name"]}</span>
                    </div>
                    <div className="col-md-4 form-group">
                      <label for="">
                        Email Id<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email_id"
                        placeholder="Enter your email id"
                        value={this.state.email_id}
                        onChange={this.handleEmailChange}
                        ref={this.emailInput}
                        readOnly
                      />
                      <span className="error">
                        {this.state.errors["email_id"]}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Contact Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="contact_number"
                        maxlength="10"
                        placeholder="Enter your Contact Number"
                        value={this.state.contact_number}
                        onChange={this.handlePhoneChange}
                        ref={this.phoneInput}
                      />
                      <span className="error">
                        {this.state.errors["contact_number"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Gender<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        name="gender"
                        value={this.state.gender}
                        onChange={this.handleGenderChange}
                        ref={this.genderInput}
                      >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      <span className="error">
                        {this.state.errors["gender"]}
                      </span>
                    </div>
                    <div className="col-md-4 form-group">
                      <label for="">
                        Date Of Birth<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control date-input"
                        name="dob"
                        id="dob"
                        placeholder="Enter your DOB DD-MM-YYYY"
                        value={this.state.dob}
                        onChange={this.handleDobChange}
                        ref={this.dobInput}
                      />
                      <span className="error">{this.state.errors["dob"]}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Country<span className="text-red">*</span>
                      </label>

                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        name="country_id"
                        value={this.state.country_id}
                        onChange={this.handleCountryChange}
                        ref={this.countryInput}
                      >
                        <option value="">Select Country</option>
                        {typeof this.state.countryList[0] === "object"
                          ? this.state.countryList.map((country) => {
                              return (
                                <option value={country.id}>
                                  {country.country_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["country_id"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        State<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        id="state-id"
                        data-size="7"
                        name="state_id"
                        value={this.state.state_id}
                        onChange={this.handleStateChange}
                        ref={this.stateInput}
                      >
                        <option value="">Select State</option>
                        {typeof this.state.stateList[0] === "object"
                          ? this.state.stateList.map((state) => {
                              return (
                                <option value={state.id}>
                                  {state.state_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["state_id"]}
                      </span>
                    </div>
                    <div className="col-md-4 form-group">
                      <label for="">
                        City<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        id="city-id"
                        data-size="7"
                        name="city_id"
                        value={this.state.city_id}
                        onChange={this.handleCityChange}
                        ref={this.cityInput}
                      >
                        <option value="">Select City</option>
                        {typeof this.state.filterCityList[0] === "object"
                          ? this.state.filterCityList.map((city) => {
                              return (
                                <option value={city.id}>
                                  {city.city_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["city_id"]}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        name="status"
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>

                  <div className="pt-md-5 pt-4 text-center">
                    <Link
                      to="/users"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
