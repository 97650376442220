import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../helpers/config";

export default class Setting extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoaded: true };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container">
        <div className="dashboard-content">
          <header className="head-title mb-5 d-flex align-items-center">
            <a href="/users" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </a>
            <h1 className="h3 mb-0">Account Setting</h1>
          </header>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <header className="card-header pt-4 pb-4 bg-white">
                  <h4 className="m-0 bold">Setting</h4>
                </header>
                <div className="card-body">
                  <div className="row">
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Setting 1</label>
                      <h5 className="m-0">setting 1</h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Setting 2</label>
                      <h5 className="m-0">Setting 2</h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Setting 3</label>
                      <h5 className="m-0">setting 3</h5>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
