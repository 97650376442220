import React, { useState } from "react";

const rowHeight = 40;

const getColumns = ({ name, agent, ...row } = {}) => {
  let columns = [{ name: "name", title: "Name", width: 400 }];
  Object.keys(row).forEach((item) => {
    columns.push({
      name: item,
      title: item,
      width: 180,
      align: "flex-end",
    });
  });
  return columns;
  //   {
  //     name: "Light Speed Web",
  //     title: "Light Speed Web",
  //     width: 180,
  //     align: "flex-end",
  //   },
  //   {
  //     name: "Personal Account",
  //     title: "Personal Account",
  //     width: 180,
  //     align: "flex-end",
  //   },
  //   {
  //     name: "Lead Engineers",
  //     title: "Lead Engineers",
  //     width: 180,
  //     align: "flex-end",
  //   },
  //   { name: "LMS", title: "LMS", width: 180, align: "flex-end" },
  //   { name: "Total", title: "Total", width: 180, align: "flex-end" },
  // ];
};
export default ({ data, columnsData }) => {
  return <NestedTable rows={data} columns={getColumns(columnsData)} />;
};
const NestedTable = ({ rows, columns }) => {
  return (
    <div>
      <TableHeader columns={columns} />
      {rows.map((item, index) => {
        return <NestedTableRow item={item} level={0} columns={columns} />;
      })}
    </div>
  );
};

const NestedTableRow = ({ item, level, columns }) => {
  const [collapsed, setCollapsed] = useState(true);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: collapsed ? 40 : "auto",
        overflow: collapsed ? "auto" : "hidden",
        transition: "height 0.5s",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          paddingLeft: 10,
          paddingRight: 10,
          height: rowHeight,
          borderBottomStyle: "solid",
          borderBottomColor: "#c9c9c9",
          borderBottomWidth: 1,
          fontWeight: level ? 500 : 700,
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 20,
            height: 20,
            backgroundColor: item?.agent?.length ? "#F1F6F9" : "transparent",
            marginLeft: (level + 1) * 10,
            marginRight: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 4,
            cursor: item?.agent?.length ? "pointer" : "none",
            userSelect: "none",
            boxShadow: item?.agent?.length
              ? "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
              : "",
          }}
          onClick={() => {
            if (item?.agent?.length) {
              setCollapsed(!collapsed);
            }
          }}
        >
          {item?.agent?.length ? (collapsed ? "+" : "-") : ""}
        </div>
        {columns?.map((column, index) => {
          const { name, width, title, align = "flex-start" } = column;
          return (
            <div
              style={{
                height: rowHeight,
                alignItems: "center",
                display: "flex",
                flex: index === 0 ? "none" : 1,
                borderLeft: index === 0 ? "" : "1px solid #c9c9c9",
                paddingLeft: index === 0 ? (level + 1) * 10 : 10,
                paddingRight: index === 0 ? (level + 1) * 10 : 10,
                width: index === 0 ? width - (level + 1) * 10 : width,
                justifyContent: align,
              }}
            >
              {item[name] || ""}
            </div>
          );
        })}
      </div>

      {item.agent
        ? item.agent.map((child, index) => {
            return (
              <NestedTableRow
                item={child}
                level={level + 1}
                columns={columns}
              />
            );
          })
        : null}
    </div>
  );
};
const TableHeader = ({ columns }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        paddingLeft: 10,
        paddingRight: 10,
        // padding: 10,
        height: 40,
        borderBottom: "1px solid #c9c9c9",
        backgroundColor: "#F1F6F9",
        fontWeight: "bold",
        fontSize: 14,
        justifyContent: "flex-start",
        alignItems: "center",
        display: "flex",
        boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.15px 0.6px",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
      }}
    >
      {columns?.map((column, index) => {
        const { name, width, title, align, flex } = column;
        return (
          <div
            style={{
              height: rowHeight,
              alignItems: "center",
              display: "flex",
              flex: index === 0 ? "none" : 1,
              borderLeft: index === 0 ? "" : "1px solid #c9c9c9",
              paddingLeft: index === 0 ? 0 : 10,
              width: index === 0 ? width - 20 : width,
              marginLeft: index === 0 ? 50 : 0,
              paddingRight: index === 0 ? 10 : 10,
              fontWeight: "bold",
              justifyContent: align,
            }}
          >
            {title}
          </div>
        );
      })}
    </div>
  );
};
