import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";
import Dropdown from "../dropdown";

export default class UserAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productList: {},
      productChildList: {},
      subProductList: [],
      countryList: {},
      stateList: {},
      cityList: {},
      agents: [],
      filterCityList: {},

      errors: {
        product_id: "",
        first_name: "",
        last_name: "",
        email_id: "",
        contact_number: "",
        country_code: "",
        country_id: "",
        state_id: "",
        city_id: "",
        status: "",
        comment: "",
        driving_license: "",
        monthaly_income: "",
        sub_product_id: "",
        landmark: "",
        sin_number: "",
        loan_amount: "",
        dob: "",
        property_address: "",
        property_value: "",
      },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      apiError: false,
      is_loader: true,
      showSubProduct: false,
      redirectToList: false,
    };

    this.productInput = React.createRef();
    this.subProductInput = React.createRef();

    this.fnameInput = React.createRef();
    this.lnameInput = React.createRef();
    this.emailInput = React.createRef();
    this.codeInput = React.createRef();
    this.phoneInput = React.createRef();
    this.countryInput = React.createRef();
    this.stateInput = React.createRef();
    this.cityInput = React.createRef();
    this.commentInput = React.createRef();
    //this.statusInput = React.createRef();

    this.addressInput = React.createRef();
    this.pincodeInput = React.createRef();
    this.drivingLicenseInput = React.createRef();
    this.SinNumberInput = React.createRef();

    /** 
		this.landmarkInput = React.createRef();
		this.employmentInput = React.createRef();
		this.monthalyIncomeInput = React.createRef();
		this.experienceInput = React.createRef();
       */
    this.previousLoanInput = React.createRef();
    this.loanAmountInput = React.createRef();
    this.dobInput = React.createRef();
    this.propertyAddressInput = React.createRef();
    this.propertyValueInput = React.createRef();

    this.sourceInput = React.createRef();
    // this.agentInput = React.createRef();

    this.handleProductChange = this.handleProductChange.bind(this);
    this.handleSubProductChange = this.handleSubProductChange.bind(this);

    this.handlefNameChange = this.handlefNameChange.bind(this);
    this.handlelNameChange = this.handlelNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleCityChange = this.handleCityChange.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);

    this.handleAddressChange = this.handleAddressChange.bind(this);

    this.handlePincodeChange = this.handlePincodeChange.bind(this);
    this.handleDrivingLicenseChange =
      this.handleDrivingLicenseChange.bind(this);
    this.handlePreviousLoanChange = this.handlePreviousLoanChange.bind(this);
    this.handleLoanAmountChange = this.handleLoanAmountChange.bind(this);
    this.handleSinNumberChange = this.handleSinNumberChange.bind(this);

    /**
		 this.handleLandmarkChange     = this.handleLandmarkChange.bind(this);
		this.handleEmploymentChange     = this.handleEmploymentChange.bind(this);
		this.handleMonthalyIncomeChange     = this.handleMonthalyIncomeChange.bind(this);
		this.handleExperienceChange     = this.handleExperienceChange.bind(this);
        */
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handlePropertyAddressChange =
      this.handlePropertyAddressChange.bind(this);
    this.handlePropertyValueChange = this.handlePropertyValueChange.bind(this);
    this.handleAgentChange = this.handleAgentChange.bind(this);

    this.handleSourceChange = this.handleSourceChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);

    let self = this;
    var token = Appconfig.getSessionToken();
    // get all dropdown list data
    if (token) {
      fetch(Appconfig.api_base_url + "lead/add", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            console.log("result", result);
            if (result.status === 1) {
              var productList = [];
              var productChildList = {};
              if (typeof (result.products[0] === "object")) {
                result.products.map((row) => {
                  console.log("parent_name", row.parent_name);
                  if (row.parent_id == "") {
                    productList.push(row);
                  } else {
                    if (
                      !productChildList.hasOwnProperty("id" + row.parent_id)
                    ) {
                      productChildList["id" + row.parent_id] = [];
                    }
                    productChildList["id" + row.parent_id].push(row);
                  }
                });
              }
              this.setState(
                {
                  countryList: result.countries,
                  country_code: result.country_code,
                  stateList: result.states,
                  agents: result.agents,
                  cityList: result.cities,
                  productList: productList,
                  productChildList: productChildList,
                },
                () => {
                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 500);
                  console.log("productList", typeof this.state.productList);
                }
              );
            } else if (result.status === 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 403) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            }
            setTimeout(function () {
              self.setState({ is_loader: false });
            }, 400);
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleStateChange(event) {
    var stateId = event.target.value;
    var filterCity = [];
    this.setState({ state_id: event.target.value });

    this.state.cityList.map((city) => {
      if (city.state_id == stateId) {
        filterCity.push(city);
      }
    });
    console.log("filterCity", filterCity);
    this.setState({ filterCityList: filterCity });
    setTimeout(function () {
      $("#city-id").selectpicker("refresh");
    }, 1000);
  }

  handleSubProductChange(event) {
    this.setState({ sub_product_id: event.target.value });
  }
  handlefNameChange(event) {
    var fname = event.target.value;
    fname = fname.charAt(0).toUpperCase() + fname.slice(1);
    this.setState({ fname: fname });
  }
  handlelNameChange(event) {
    var lname = event.target.value;
    lname = lname.charAt(0).toUpperCase() + lname.slice(1);
    this.setState({ lname: lname });
  }

  handleEmailChange(event) {
    this.setState({ email_id: event.target.value });
  }
  handleCodeChange(event) {
    this.setState({ country_code: event.target.value });
  }
  handlePhoneChange(event) {
    this.setState({ contact_number: event.target.value });
  }
  handleCountryChange(event) {
    this.setState({ country_id: event.target.value });
  }
  handleCityChange(event) {
    this.setState({ city_id: event.target.value });
  }
  handleCommentChange(event) {
    this.setState({ comment: event.target.value });
  }
  handleAddressChange(event) {
    this.setState({ address: event.target.value });
  }

  handlePincodeChange(event) {
    this.setState({ pincode: event.target.value });
  }
  handleDrivingLicenseChange(event) {
    this.setState({ driving_license: event.target.value });
  }

  handlePreviousLoanChange(event) {
    this.setState({ previous_loan: event.target.value });
  }
  handleSinNumberChange(event) {
    this.setState({ sin_number: event.target.value });
  }
  /** 
  handleLandmarkChange(event)           {    this.setState({landmark: event.target.value});}
  handleEmploymentChange(event)         {    this.setState({employment_type: event.target.value});}
  handleMonthalyIncomeChange(event)     {    this.setState({monthaly_income: event.target.value});}
  handleExperienceChange(event)         {    this.setState({experience: event.target.value});}
  */
  handlePropertyAddressChange(event) {
    this.setState({ property_address: event.target.value });
  }
  handleSourceChange(event) {
    this.setState({ source_of_lead: event.target.value });
  }
  handleAgentChange(value) {
    this.setState({ agent_id: value });
  }

  handlePropertyValueChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ property_value: event.target.value });
    }
  }

  handleLoanAmountChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ loan_amount: event.target.value });
    }
  }

  handleProductChange(event) {
    console.log("productChildList", this.state.productChildList);
    var productChildList = this.state.productChildList;
    var subProductList = [];
    if (productChildList.hasOwnProperty("id" + event.target.value)) {
      subProductList = productChildList["id" + event.target.value];
      this.setState({ subProductList: subProductList, showSubProduct: true });
    } else {
      this.setState({ subProductList: subProductList, showSubProduct: false });
    }
    this.setState({ product_id: event.target.value, sub_product_id: "" });
    setTimeout(function () {
      $("#sub-product-g-id").selectpicker("refresh");
    }, 1000);
    console.log("subProductList", subProductList);
  }
  handleDobChange(event) {
    this.setState({ dob: event.target.value });
  }

  //handleStatusChange(event)           {    this.setState({status: event.target.value});}

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    //Form validation

    // if(!this.state.sub_product_id){
    // 	  formIsValid = false; errors["sub_product_id"] = "The sub product field is required.";
    // 	  this.subProductInput.current.focus();
    // }

    if (!this.state.product_id) {
      formIsValid = false;
      errors["product_id"] = "The product field is required.";
      this.productInput.current.focus();
    }

    if (!this.state.source_of_lead) {
      formIsValid = false;
      errors["source_of_lead"] = "The source of lead field is required.";
      this.sourceInput.current.focus();
    }

    if (!this.state.agent_id) {
      formIsValid = false;
      errors["agent_id"] = "The agent field is required.";
      // this.agentInput.current.focus();
    }

    /** 
	if(!this.state.previous_loan){
		  formIsValid = false; errors["previous_loan"] = "The previous loan field is required.";
		  this.previousLoanInput.current.focus();
	}
	
	
	if(!this.state.experience){
		  formIsValid = false; errors["experience"] = "The experience field is required.";
		  this.experienceInput.current.focus();
	}
	
	if(!this.state.monthaly_income){
		  formIsValid = false; errors["monthaly_income"] = "The monthly income field is required.";
		  this.monthalyIncomeInput.current.focus();
	}
	
	if(!this.state.employment_type){
		  formIsValid = false; errors["employment_type"] = "The employment type field is required.";
		  this.employmentInput.current.focus();
	}
	*/
    /*
	if(!this.state.driving_license){
		  formIsValid = false; errors["driving_license"] = "The driving license no field is required.";
		  this.drivingLicenseInput.current.focus();
	}
	
	if(!this.state.pincode){
		  formIsValid = false; errors["pincode"] = "The postal code field is required.";
		  this.pincodeInput.current.focus();
	}
	
	
	if(!this.state.city_id){
		  formIsValid = false; errors["city_id"] = "The city field is required.";
		  this.cityInput.current.focus();
	}
	if(!this.state.state_id){
		  formIsValid = false; errors["state_id"] = "The Province field is required.";
		  this.stateInput.current.focus();
	}
	
     */
    /*
	if(!this.state.landmark){
		  formIsValid = false; errors["landmark"] = "The landmark field is required.";
		  this.landmarkInput.current.focus();
	}*/
    /*
	if(!this.state.address){
		  formIsValid = false; errors["address"] = "The address field is required.";
		  this.addressInput.current.focus();
	}
	*/
    //~ if(!this.state.country_id){
    //~ formIsValid = false; errors["country_id"] = "The country field is required.";
    //~ this.countryInput.current.focus();
    //~ }

    if (!this.state.contact_number) {
      formIsValid = false;
      errors["contact_number"] = "The Mobile number field required.";
      this.phoneInput.current.focus();
    } else {
      var mobile = this.state.contact_number;
      if (mobile.length == 10 && mobile > 1000000000) {
        errors["contact_number"] = "";
      } else {
        formIsValid = false;
        errors["contact_number"] = "Please enter valid 10 digit mobile.";
        this.phoneInput.current.focus();
      }
    }

    /*
	if(!this.state.email_id){
		   formIsValid = false; errors["email_id"] ="The email field required.";
		   this.emailInput.current.focus();
	}else{
		var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		if (!pattern.test(this.state.email_id)) {
			formIsValid = false;
			errors["email_id"] = "Please enter valid email address.";
		}
	} 
	if(!this.state.fname){
		   formIsValid = false; errors["first_name"] = "The first name field is required.";
		   this.fnameInput.current.focus();
	}
	if(!this.state.lname){
		   formIsValid = false; errors["last_name"] = "The last name field is required.";
		   this.lnameInput.current.focus();
	}
	*/

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (token) {
        var settings = {
          url: Appconfig.api_base_url + "lead/add",
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            product_id: this.state.product_id,
            sub_product_id: this.state.sub_product_id,
            first_name: this.state.fname,
            last_name: this.state.lname,
            email_id: this.state.email_id,
            country_code: this.state.country_code,
            contact_number: this.state.contact_number,
            country_id: this.state.country_id,
            state_id: this.state.state_id,
            city_id: this.state.city_id,
            status: this.state.status,
            comment: this.state.comment,
            driving_license: this.state.driving_license,
            previous_loan: this.state.previous_loan,

            /** 
				   "experience": this.state.experience,
				   "monthaly_income": this.state.monthaly_income,
				   "employment_type": this.state.employment_type,
				   */
            pincode: this.state.pincode,
            address: this.state.address,
            //"landmark": this.state.landmark,
            sin_number: this.state.sin_number,
            loan_amount: this.state.loan_amount,
            date_of_birth: this.state.dob,
            property_address: this.state.property_address,
            property_value: this.state.property_value,
            source_of_lead: this.state.source_of_lead,
            agent_id: this.state.agent_id,
            requestBy: "LMS",
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          //localStorage.setItem("lmsuser", JSON.stringify(response.token));
          console.log("response", response);
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been added successfully");
            self.setState({ redirectToList: true });
            window.location.href = Appconfig.site_url + "/leads";
          } else if (response.status === 0) {
            if (typeof response.error != "object") {
              self.setState({ apiError: response.error });
            } else {
              var errorsObj = response.error;
              var errorStateObj = self.state.errors;
              Object.keys(errorsObj).map(function (key) {
                console.log("key", key);
                if (errorsObj.hasOwnProperty(key)) {
                  console.log("key", key);
                  console.log("error 2", errorsObj[key]);
                  errors[key] = errorsObj[key].message;

                  self.setState({ errors }, () => {
                    console.log("state", self.state.errors);
                  });
                }
              });
            }
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/users";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    $("#dob")
      .datepicker({
        autoclose: true,
        //startDate: "0d",
        endDate: new Date(),
        format: "dd-mm-yyyy",
        //maxViewMode: 2,
        todayHighlight: true,
      })
      .on("changeDate", (e) => {
        //  e here contains the extra attributes
        this.handleDobChange(e);
      });
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/leads"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-4 d-flex align-items-center">
            <Link to="/leads" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Add New Lead</h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}

          {this.state.isLoaded === true ? (
            <form onSubmit={this.handleSubmit} autoComplete="nope">
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-4 bold">Product Detail</h4>
                  <div className="row">
                    <div className="col-md-3 form-group">
                      <label for="">
                        Product looking at<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        id="product-g-id"
                        data-size="7"
                        value={this.state.value}
                        onChange={this.handleProductChange}
                        ref={this.productInput}
                      >
                        <option value="">Select Product</option>
                        {typeof this.state.productList[0] === "object"
                          ? this.state.productList.map((product) => {
                              return (
                                <option value={product.id}>
                                  {product.product_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["product_id"]}
                      </span>
                    </div>

                    {this.state.showSubProduct === true ? (
                      <div className="col-md-3 form-group">
                        <label for="">
                          Sub Product looking at
                          <span className="text-red"></span>
                        </label>
                        <select
                          className="form-control selectpicker"
                          data-live-search="true"
                          id="sub-product-g-id"
                          data-size="7"
                          value={this.state.sub_product_id}
                          onChange={this.handleSubProductChange}
                          ref={this.subProductInput}
                        >
                          <option value="">Select Sub Product</option>
                          {typeof this.state.subProductList[0] === "object"
                            ? this.state.subProductList.map((row) => {
                                return (
                                  <option
                                    value={row.id}
                                    selected={this.state.sub_product_id}
                                  >
                                    {row.product_name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                        <span className="error">
                          {this.state.errors["sub_product_id"]}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* <div className="col-md-3 form-group">
                      <label for="">
                        Agents<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        id="product-g-id"
                        data-size="7"
                        value={this.state.value}
                        onChange={this.handleAgentChange}
                        ref={this.agentInput}
                      >
                        <option value="">Select Agent</option>
                        {typeof this.state.agents[0] === "object"
                          ? this.state.agents.map((agent) => {
                              return (
                                <option value={agent.id}>
                                  {agent.full_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["agent_id"]}
                      </span>
                    </div> */}
                    <Dropdown
                      className="col-md-3"
                      key={"agent_id"}
                      field={"agent_id"}
                      value={this.state.agent_id}
                      title={"Select Agent"}
                      requestURL={"user/getActiveAgentListManagerWise"}
                      labelField={"full_name"}
                      valueField={"id"}
                      error={this.state.errors["agent_id"]}
                      required
                      onChange={this.handleAgentChange}
                    />

                    <div className="col-md-3 form-group">
                      <label for="">
                        Source of Lead<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.source_of_lead}
                        onChange={this.handleSourceChange}
                        ref={this.sourceInput}
                      >
                        <option value="">-Select-</option>
                        {/*<option value="Amazon Connect">Amazon Connect</option>*/}
                        <option value="google">Google</option>
                        <option value="social">Social</option>
                        <option value="LMS-CRM">LMS-CRM</option>
                        <option value="Other">Other</option>
                      </select>
                      <span className="error">
                        {this.state.errors["source_of_lead"]}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <h4 className="mb-4 bold">Customer Detail</h4>
                  <div className="row">
                    <div className="col-md-3 form-group">
                      <label for="">
                        First Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="fname"
                        maxlength="100"
                        placeholder="Enter your first name"
                        value={this.state.fname}
                        onChange={this.handlefNameChange}
                        ref={this.fnameInput}
                        autoComplete="nope"
                      />
                      <span className="error">
                        {this.state.errors["first_name"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Last Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lname"
                        maxlength="100"
                        placeholder="Enter your last name"
                        value={this.state.lname}
                        onChange={this.handlelNameChange}
                        ref={this.lnameInput}
                        autoComplete="nope"
                      />
                      <span className="error">
                        {this.state.errors["last_name"]}
                      </span>
                    </div>
                    <div className="col-md-3 form-group">
                      <label for="">
                        Email Id<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email_id"
                        maxlength="100"
                        placeholder="Enter your email id"
                        value={this.state.value}
                        onChange={this.handleEmailChange}
                        ref={this.emailInput}
                        autoComplete="nope"
                      />
                      <span className="error">
                        {this.state.errors["email_id"]}
                      </span>
                    </div>

                    <div className="col-md-1 form-group">
                      <label for="">
                        Code <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="country_code"
                        maxlength="10"
                        placeholder="Enter Country code"
                        value={this.state.country_code}
                        onChange={this.handleCodeChange}
                        ref={this.codeInput}
                        autoComplete="nope"
                      />
                      <span className="error">
                        {this.state.errors["country_code"]}
                      </span>
                    </div>

                    <div className="col-md-2 form-group">
                      <label for="">
                        Mobile Number <span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="contact_number"
                        maxlength="10"
                        placeholder="Enter your Mobile Number"
                        value={this.state.value}
                        onChange={this.handlePhoneChange}
                        ref={this.phoneInput}
                        autoComplete="nope"
                      />
                      <span className="error">
                        {this.state.errors["contact_number"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">Date Of Birth</label>
                      <input
                        type="text"
                        name="dob"
                        id="dob"
                        className="form-control date-input"
                        placeholder="Select DOB"
                        value={this.state.value}
                        onChange={this.handleDobChange}
                        ref={this.dobInput}
                        autoComplete="off"
                      />
                      <span className="error">{this.state.errors["dob"]}</span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Address <span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        maxlength="250"
                        placeholder="Enter your Address"
                        value={this.state.value}
                        onChange={this.handleAddressChange}
                        ref={this.addressInput}
                        autoComplete="nope"
                      />
                      <span className="error">
                        {this.state.errors["address"]}
                      </span>
                    </div>
                    {/*
									<div className="col-md-3 form-group">
										<label for="">Land Mark <span className="text-red"></span></label>
										<input type="text" className="form-control" name="landmark" maxlength="250" placeholder="Enter Landmark" value={this.state.value}  onChange={this.handleLandmarkChange} ref={this.landmarkInput} autoComplete="nope" />
										<span className="error">{this.state.errors["landmark"]}</span>
									</div>
									*/}

                    <div className="col-md-3 form-group">
                      <label for="">
                        SIN Number <span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="sin_number"
                        maxlength="250"
                        placeholder="Enter SIN Number"
                        value={this.state.value}
                        onChange={this.handleSinNumberChange}
                        ref={this.SinNumberInput}
                        autoComplete="nope"
                      />
                      <span className="error">
                        {this.state.errors["sin_number"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Province<span className="text-red"></span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        id="state-id"
                        data-size="7"
                        value={this.state.value}
                        onChange={this.handleStateChange}
                        ref={this.stateInput}
                      >
                        <option value="">Select Province</option>
                        {typeof this.state.stateList[0] === "object"
                          ? this.state.stateList.map((state) => {
                              return (
                                <option value={state.id}>
                                  {state.state_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["state_id"]}
                      </span>
                    </div>
                    <div className="col-md-3 form-group">
                      <label for="">
                        City<span className="text-red"></span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        id="city-id"
                        data-size="7"
                        value={this.state.value}
                        onChange={this.handleCityChange}
                        ref={this.cityInput}
                      >
                        <option value="">Select City</option>
                        {typeof this.state.filterCityList[0] === "object"
                          ? this.state.filterCityList.map((city) => {
                              return (
                                <option value={city.id}>
                                  {city.city_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["city_id"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Postal Code <span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="pincode"
                        maxlength="7"
                        placeholder="Enter your postal code "
                        value={this.state.value}
                        onChange={this.handlePincodeChange}
                        ref={this.pincodeInput}
                      />
                      <span className="error">
                        {this.state.errors["pincode"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Driving License No. <span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="driving_license"
                        maxlength="25"
                        placeholder="Enter Driving License No"
                        value={this.state.value}
                        onChange={this.handleDrivingLicenseChange}
                        ref={this.drivingLicenseInput}
                      />
                      <span className="error">
                        {this.state.errors["driving_license"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Apply Loan Amount<span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="loan_amount"
                        maxlength="10"
                        placeholder="Enter Loan Amount "
                        value={this.state.loan_amount}
                        onChange={this.handleLoanAmountChange}
                        ref={this.loanAmountInput}
                      />
                      <span className="error">
                        {this.state.errors["loan_amount"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Previous Loan if Any<span className="text-red"></span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.value}
                        onChange={this.handlePreviousLoanChange}
                        ref={this.previousLoanInput}
                      >
                        <option value="">Select Previous Loans</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                      <span className="error">
                        {this.state.errors["previous_loan"]}
                      </span>
                    </div>
                  </div>

                  <hr />
                  {/** 																				  
						<h4 className="mb-4 bold">Employment Detail</h4>
								<div className="row">
									<div className="col-md-3 form-group">
										<label for="">Employment Type<span className="text-red">*</span></label>
										<select className="form-control selectpicker" data-size="7" value={this.state.value}  onChange={this.handleEmploymentChange} ref={this.employmentInput}>
											<option value="">Select Employment Type</option>
											<option value="salaried">Salaried</option>
											<option value="business	">Business</option>									
										</select>
										<span className="error">{this.state.errors["employment_type"]}</span>
									</div>

									<div className="col-md-3 form-group">
										<label for="">Monthly Income <span className="text-red">*</span></label>
										<select className="form-control selectpicker" data-live-search="true" data-size="7" value={this.state.value}  onChange={this.handleMonthalyIncomeChange} ref={this.monthalyIncomeInput}>
											<option value="">Select Monthly Income</option>
											<option value="10000">10000</option>
											<option value="20000">20000</option>
											<option value="30000">30000</option>
											<option value="40000">40000</option>
											<option value="50000">50000</option>
											<option value="60000">60000</option>
											<option value="70000">70000</option>
											<option value="80000">80000</option>
											<option value="90000">90000</option>
											<option value="100000">100000</option>
											<option value="150000">150000</option>
											<option value="200000">200000</option>
											<option value="250000">250000 & above</option>									
										</select>
										<span className="error">{this.state.errors["monthaly_income"]}</span>
									</div>
							
							
									<div className="col-md-3 form-group">
										<label for="">Experience in Year<span className="text-red">*</span></label>
										<select className="form-control selectpicker" data-size="7" value={this.state.value}  onChange={this.handleExperienceChange} ref={this.experienceInput}>
											<option value="">Select Experience</option>
											<option value="0-1">0-1 Year</option>
											<option value="1-3">1-3 Year</option>
											<option value="4-10">4-6 Year</option>
											<option value="7-10">7-10 Year</option>
											<option value="11-15">11-15 Year </option>
											<option value="16-20">16-20 Year</option>
											<option value="21-25">21-25 Year</option>									
										</select>
										<span className="error">{this.state.errors["experience"]}</span>
									</div>

								</div>
								<hr />
								*/}
                  <h4 className="mb-4 bold">Property Detail</h4>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label for="">
                        Property Address<span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="property_address"
                        maxlength="200"
                        placeholder="Enter Property Address"
                        value={this.state.value}
                        onChange={this.handlePropertyAddressChange}
                        ref={this.propertyAddressInput}
                      />
                      <span className="error">
                        {this.state.errors["property_address"]}
                      </span>
                    </div>

                    <div className="col-md-3 form-group">
                      <label for="">
                        Property Value<span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="property_value"
                        maxlength="8"
                        placeholder="Enter Property Value "
                        value={this.state.value}
                        onChange={this.handlePropertyValueChange}
                        ref={this.propertyValueInput}
                      />
                      <span className="error">
                        {this.state.errors["property_value"]}
                      </span>
                    </div>
                  </div>
                  <hr />

                  <h4 className="mb-4 bold">Other Detail</h4>
                  <div className="row">
                    <div className="col-md-12 form-group">
                      <label for="">Your Comment</label>
                      <textarea
                        className="form-control"
                        value={this.state.value}
                        name="comment"
                        rows="2"
                        maxlength="250"
                        placeholder="Enter Your comment"
                        onChange={this.handleCommentChange}
                        ref={this.commentInput}
                      ></textarea>
                      <span className="error">
                        {this.state.errors["comment"]}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-5 text-center">
                <Link
                  to="/leads"
                  className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                >
                  Cancel
                </Link>
                <button
                  className="btn btn-lg btn-primary pl-4 pr-4"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
