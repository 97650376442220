import React, { Component } from "react";
import logo from "../assets/img/logo.png";
import warningIcon from "../assets/img/warning.svg";
import successIcon from "../assets/img/success.svg";
import { Link } from "react-router-dom";
import $ from "jquery";
import request from "request";
import * as Appconfig from "../helpers/config";
import * as usersessionHelper from "../helpers/lms_usersession";

class Mailverification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      apiError: "",
    };

    fetch(
      Appconfig.api_base_url + "webapi/verifyemail/" + props.match.params.token,
      {
        method: "GET",
      }
    )
      .then((res) => res.json())
      .then(
        async (result) => {
          if (result.status === 1) {
            this.setState({ message: result.message });
          } else if (result.status === 0) {
            this.setState({ apiError: result.message });
          } else {
            this.setState({ apiError: result.message });
          }
        },
        (error) => {
          this.setState({
            isLoaded: false,
          });
        }
      );
  }

  render() {
    return (
      <div className="d-flex align-items-center justify-content-center h-100 w-100 bg-white p-4">
        <div className="text-center">
          <div className="mb-5">
            <img className="login-logo" src={logo} alt="logo" />
          </div>

          {this.state.message ? (
            <div>
              <img
                className="mb-2"
                src={successIcon}
                width="80"
                alt="success"
              />
              <h1 className="bold text-black">Thank You</h1>
              <p className="text-muted text-large">
                {this.state.message ? this.state.message : ""}
              </p>
            </div>
          ) : (
            ""
          )}

          {this.state.apiError ? (
            <div>
              <img
                src={warningIcon}
                alt="warning"
                width="100"
                style={{ opacity: "0.5" }}
              />
              <h1 className="bold text-black">Error</h1>
              <p className="text-muted text-large">
                {this.state.apiError
                  ? "In valid link OR link has been expired."
                  : ""}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default Mailverification;
