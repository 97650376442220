import React, { Component } from "react";
import { Link } from "react-router-dom";
import LeadsCounterItem from "../dashboard/LeadsCounterItem";

class LeadsCounterNew extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let width = Math.round(12 / (this.props.tickets.length / 2)) - 1;
    console.log("@@@@@@@@toicket", this.props);
    return (
      <div className="row row-gap h-100">
        {this.props.tickets
          ? this.props.tickets.map((LeadsCard, i) => {
              return (
                <LeadsCounterItem
                  key={i}
                  id={this.props.tickets[i].title}
                  title={this.props.tickets[i].title}
                  link={this.props.tickets[i].link}
                  number={this.props.tickets[i].number}
                  width={width}
                />
              );
            })
          : ""}
      </div>
    );
  }
}

export default LeadsCounterNew;
