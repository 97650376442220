import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerformerListItem from "../../dashboard/PerformerListItem";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import * as Appconfig from "../../../helpers/config";
import $ from "jquery";
//http://jsfiddle.net/izothep/myork5sa/

import Highcharts from "./Highcharts";

class ProvinceWiseLeadStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channelWiseLeadFundedBar: this.props.channelWiseLeadFundedBar,
      apiError: false,
      error: null,
    };
  }

  componentDidMount() {
    Highcharts.setOptions({
      lang: {
        drillUpText: "◁",
      },
    });
    // Create the chart
    var drillupVal = 0;
    let self = this;
    if (self.state.channelWiseLeadFundedBar.series) {
      Highcharts.chart("container_channelLeadVsLenderFunded", {
        chart: {
          type: "column",
          events: {
            drilldown: function (e) {
              if (!e.seriesOptions) {
                this.setTitle({
                  text: e.target.title.textStr + " : " + e.point.name,
                });
                var chart = this,
                  drilldowns = (drilldowns =
                    self.state.channelWiseLeadFundedBar.drilldowns1),
                  drilldowns2 = self.state.channelWiseLeadFundedBar.drilldowns2,
                  series = drilldowns[e.point.id],
                  series2 = drilldowns2[e.point.id];

                chart.addSingleSeriesAsDrilldown(e.point, series);
                chart.addSingleSeriesAsDrilldown(e.point, series2);

                chart.applyDrilldown();
              }
            },
            drillup: function (e) {
              if (drillupVal === 0) {
                drillupVal = 1;
                console.log("eventText", e.target);
                var titleArr = e.target.title.textStr.split(":");
                titleArr.splice(-1, 1);
                var newtitle = titleArr.join(":");
                console.log("eventText2", newtitle);
                this.setTitle({ text: newtitle });
              }
              setTimeout(function () {
                drillupVal = 0;
                console.log("tests", "k");
              }, 300);
            },
          },
        },
        title: {
          text: "Channel Wise Leads vs Funded",
          align: "left",
          margin: 30,
          style: {
            fontWeight: "bold",
            fontSize: "18px",
            fontFamily: "Poppins, sans-serif",
          },
        },
        xAxis: {
          type: "category",
          labels: {
            style: {
              color: "#333333",
              fontFamily: "Poppins, sans-serif",
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          gridLineDashStyle: "longdash",
          labels: {
            style: {
              color: "#5774aa",
              fontFamily: "Poppins, sans-serif",
            },
          },
        },
        crosshair: true,

        legend: {
          enabled: true,
          itemStyle: {
            fontWeight: "regular",
            fontFamily: "Poppins, sans-serif",
          },
        },
        credits: { enabled: false },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true,
          backgroundColor: "rgba(255,255,255,0.9)",
          borderRadius: 6,
          borderColor: "#dddddd",
          style: { fontWeight: "regular", fontFamily: "Poppins, sans-serif" },
        },

        plotOptions: {
          //column: {stacking: 'normal'},
          series: {
            borderWidth: 0,
            borderRadius: 8,
            dataLabels: {
              enabled: false,
              style: {
                fontSize: "13px",
                fontWeight: "regular",
                fontFamily: "Poppins, sans-serif",
              },
            },
          },
        },

        series: self.state.channelWiseLeadFundedBar.series,

        drilldown: {
          drillUpButton: {
            relativeTo: "spacingBox",
            position: {
              y: 0,
              x: -5,
            },

            theme: {
              fill: "#eeeeee",
              "stroke-width": 1,
              stroke: "#eeeeee",
              width: 20,
              height: 20,
              r: 20,
              style: {
                color: "black",
              },
              states: {
                hover: {
                  fill: "#eeeeee",
                },

                select: {
                  stroke: "#eeeeee",
                  fill: "#eeeeee",
                },
              },
            },
          },
          series: [],
        },
      });
    }
  }
  render() {
    return (
      <div className="col-xl-6 col-md-6 mb-2">
        <div className="card h-100 hover-shadow p-4">
          {/*<h2 className="mb-4 h4">Channel Wise Leads vs Funded</h2>*/}
          <div id="container_channelLeadVsLenderFunded"></div>
        </div>
      </div>
    );
  }
}
export default ProvinceWiseLeadStatus;
