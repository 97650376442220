//import logo from './logo.svg';
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";

import Header from "./Header.js";
import Main from "./Main.js";
import Footer from "./Footer.js";

const history = createBrowserHistory();
function App() {
  return (
    <Router history={history}>
      <div className="App">
        {window.location.pathname !== "/" ? (
          <Header name={window.location.pathname} />
        ) : null}
        <Main />

        {window.location.pathname !== "/" ? <Footer /> : null}
      </div>
    </Router>
  );
}

export default App;
