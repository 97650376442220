import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerformerListItem from "../dashboard/PerformerListItem";

class PerformanceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lowPerformer: this.props.lowPerformer,
      PerformerlistArray: "",
      apiError: false,
      error: null,
    };
  }

  render() {
    const { data, title, type = "top" } = this.props;

    return (
      <div className="col-lg-6 mb-4">
        <div className="card h-100 hover-shadow p-4">
          <h2 className="mb-4 h4">{title}</h2>
          <div className="performer-head d-flex border-bottom pb-2 mb-1 bold">
            <div className="col col-author pl-0">Name</div>
            <div className="col col-progress">Converted Leads</div>
            <div className="col col-number text-right pr-0">Assigned</div>
          </div>

          <div className={`${type}-performer performer-list text-primary-dark`}>
            {data
              ? data.map((PerformerCard, i) => {
                  return (
                    <div>
                      {parseInt(data[i].total_lead) > 0 ? (
                        <PerformerListItem
                          key={i}
                          id={data[i].name}
                          name={data[i].full_name}
                          converted_leads={(
                            (data[i].completed_lead * 100) /
                            data[i].total_lead
                          ).toFixed(2)}
                          assigned_leads={data[i].total_lead}
                          completed_leads={data[i].completed_lead}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
    );
  }
}

export default PerformanceList;
