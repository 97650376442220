import React from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import view_icon from "../../assets/img/view-icon.svg";
import * as Appconfig from "../../helpers/config";
import * as common_helper from "../../helpers/lms_common";
import $ from "jquery";
import * as checkBtnPermission from "../../helpers/checkBtnPermission";

export default class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountList: [],
      totalAccount: 0,
      offset: 0,
      perPage: Appconfig.perPage,
      currentPage: 1,
      filterStatus: "",
      filterKeyword: "",
      isLoaded: false,
      apiError: false,
    };

    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleClickCsvData = this.handleClickCsvData.bind(this);
    this.receivedData();
  }

  // hendel Serch
  handleSubmit = (e) => {
    e.preventDefault();
    console.log("filterKeyword", e.target.elements.filterKeyword.value);
    console.log("filterStatus", e.target.elements.filterStatus.value);

    this.setState(
      {
        currentPage: 1,
        offset: 0,
        isLoaded: false,
        filterStatus: e.target.elements.filterStatus.value,
        filterKeyword: e.target.elements.filterKeyword.value,
      },
      () => {
        this.receivedData(this.state.filterStatus, this.state.filterKeyword);
      }
    );
  };

  // pagination
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData(this.state.filterStatus, this.state.filterKeyword);
      }
    );
  };

  receivedData(filterStatus, filterKeyword) {
    var userInfo = localStorage.getItem("lmsuser");
    userInfo = JSON.parse(userInfo);
    var token = "";
    if (userInfo != "undefined" && userInfo.hasOwnProperty("token")) {
      token = userInfo.token;
    }

    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "customer_account/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offset: this.state.offset,
          perPage: this.state.perPage,
          filterStatus: filterStatus,
          filterKeyword: filterKeyword,
        }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                isLoaded: true,
                accountList: result.data.rows,
                totalAccount: result.data.count,
                pageCount: Math.ceil(result.data.count / this.state.perPage),
              });
              console.log("result:", result.data.rows);
            } else if (result.status === 0) {
              this.setState({ apiError: result.message });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message });
              //window.location.href=Appconfig.site_url;
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleClickCsvData(event) {
    var userInfo = localStorage.getItem("lmsuser");
    userInfo = JSON.parse(userInfo);
    var token = "";
    if (userInfo != "undefined" && userInfo.hasOwnProperty("token")) {
      token = userInfo.token;
    }

    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "customer_account/list", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          offset: 0,
          perPage: 10000,
          filterStatus: this.state.filterStatus,
          filterKeyword: this.state.filterKeyword,
        }),
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.data) {
              var Items = result.data.rows;
              var StatusStr = ["Inactive", "Active"];
              console.log("Items", Items);
              const csvString = [
                [
                  "Customer Name",
                  "Customer Email",
                  "Customer Mobile",
                  "Customer Province",
                  "Customer City",
                  "Status",
                  "Created date",
                ],
                ...Items.map((item) => [
                  item.first_name,
                  item.email_id,
                  item.contact_number,
                  item.state ? item.state.state_name : "",
                  item.city ? item.city.city_name : "",
                  StatusStr[item.status],
                  common_helper.getDateTime(item.createdAt),
                ]),
              ]
                .map((e) => e.join(","))
                .join("\n");

              var data = new Blob([csvString], { type: "text/csv" });
              var csvURL = window.URL.createObjectURL(data);
              var tempLink = document.createElement("a");
              tempLink.href = csvURL;
              var timestr = common_helper.getCurrentDateTimeStr();
              tempLink.setAttribute("download", "customers" + timestr + ".csv");
              tempLink.click();
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleStatusChange(event) {
    const target = event.target;
    const customerId = target.value;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log("checked", target);
    console.log("value", target.value);

    var updateAccountList = [];
    var newStatus = this.state.accountList.map((row, index) => {
      console.log(row.id + "===" + customerId);
      if (row.id == parseInt(customerId)) {
        if (row.status == 1) var newStatus = 0;
        else var newStatus = 1;
        row.status = newStatus;
        console.log("updaterow", row);
      }
      updateAccountList.push(row);
    });

    var userInfo = localStorage.getItem("lmsuser");
    userInfo = JSON.parse(userInfo);
    var token = "";
    if (userInfo != "undefined" && userInfo.hasOwnProperty("token")) {
      token = userInfo.token;
    }

    if (token) {
      fetch(
        Appconfig.api_base_url + "customer_account/updateStatus/" + customerId,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
            //"Content-Type": "application/json"
          },
        }
      )
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status == 1) {
              this.setState({ accountList: updateAccountList });
            } else {
              this.setState({ error: result.message });
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    }

    //console.log('updateUserList',updateUserList);
    //this.setState({userList:updateUserList});
  }

  componentDidMount() {
    $(document).ready(function () {
      $("select").selectpicker();
      $(
        ".filter-trigger, .search-btn, .btn-search-close, .filter-backdrop"
      ).click(function () {
        $("body").toggleClass("is-search");
      });
    });
  }

  render() {
    return (
      <div
        className={this.state.isLoaded ? "container" : "container is-loader"}
      >
        <div className="loader">&nbsp;</div>

        <div className="dashboard-content">
          <form onSubmit={this.handleSubmit}>
            <header className="head-title mb-md-3 d-sm-flex align-items-center">
              <h1 className="h3 mb-sm-0 mb-3">Account Management</h1>
              <button
                type="button"
                className="btn btn-secondary d-xl-none ml-auto filter-trigger"
              >
                Filter Search <i class=" fa fa-search ml-2"></i>
              </button>

              <div className="filter-group ml-auto d-xl-flex align-items-center">
                <div className="select-filter filter-email mr-xl-3">
                  <label for="" className="d-block text-muted m-0">
                    Search By : Name/Email/Mobile
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="filterKeyword"
                    maxlength="100"
                    placeholder="Enter Keyword"
                    value={this.state.value}
                  />
                  <span className="error"></span>
                </div>

                <div className="select-filter mr-xl-3">
                  <label className="d-block text-muted m-0" for="FilterStatus">
                    Select Status
                  </label>
                  <select
                    className="selectpicker"
                    name="filterStatus"
                    id="FilterStatus"
                    value={this.state.value}
                  >
                    <option value="">Select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                </div>
                <div className="filter-search-btn">
                  <button type="submit" className="search-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16.144"
                      height="17.105"
                      viewBox="0 0 16.144 17.105"
                    >
                      <g transform="translate(-1041 -173)">
                        <g
                          transform="translate(1041 173)"
                          fill="none"
                          stroke="#050033"
                          stroke-width="1.5"
                        >
                          <circle cx="7.5" cy="7.5" r="7.5" stroke="none" />
                          <circle cx="7.5" cy="7.5" r="6.75" fill="none" />
                        </g>
                        <path
                          d="M1064.441,198.1l2.72,3.333"
                          transform="matrix(0.996, -0.087, 0.087, 0.996, -24.656, 81.065)"
                          fill="none"
                          stroke="#050033"
                          stroke-linecap="round"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                    <span class="d-xl-none">SEARCH</span>
                  </button>
                </div>
                <button type="button" className="btn-search-close">
                  &times;
                </button>
              </div>
              <div className="filter-backdrop"></div>
            </header>
          </form>

          <p className="error align-items-center">{this.state.error}</p>

          <div className="card">
            <header className="card-header py-3 d-flex align-items-center bg-white">
              <div className="recourd-number bold">
                Total Records:{" "}
                {this.state.totalAccount ? this.state.totalAccount : ""}
              </div>

              <div className="ml-auto">
                {checkBtnPermission.getButton(
                  "download_csv",
                  "/accounts/download_csv",
                  "",
                  this.props,
                  Link,
                  this
                )}
              </div>
            </header>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-striped table-hover dashboard-table v-middle">
                  <thead className="bg-white">
                    <tr>
                      <th className="col-fixed">Actions</th>
                      <th>Account ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Contact No.</th>
                      <th>No. of leads</th>
                      <th>Province</th>
                      <th>City</th>
                      {checkBtnPermission.getButton(
                        "updateStatus",
                        "",
                        "",
                        this.props,
                        Link,
                        this
                      ) ? (
                        <th>Status</th>
                      ) : (
                        ""
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {typeof this.state.accountList[0] === "object" ? (
                      this.state.accountList.map((account, index) => {
                        return (
                          <tr>
                            <td className="nowrap col-fixed">
                              {checkBtnPermission.getButton(
                                "view",
                                "./account/view/" + account.id,
                                view_icon,
                                this.props,
                                Link,
                                this
                              )}
                            </td>
                            <td>{account.customer_account_id}</td>
                            <td>
                              {account.first_name ? account.first_name : ""}
                            </td>
                            <td>{account.email_id ? account.email_id : ""}</td>
                            <td>
                              {account.contact_number
                                ? common_helper.formatPhoneNumber(
                                    account.contact_number
                                  )
                                : ""}
                            </td>
                            <td>
                              {account.leads.length ? account.leads.length : ""}
                            </td>
                            <td>
                              {account.state != null
                                ? account.state.state_name
                                : ""}
                            </td>
                            <td>
                              {account.city != null
                                ? account.city.city_name
                                : ""}
                            </td>
                            {checkBtnPermission.getButton(
                              "updateStatus",
                              "",
                              "",
                              this.props,
                              Link,
                              this
                            ) ? (
                              <td>
                                <div className="switch-btn btn-checkbox">
                                  <input
                                    type="checkbox"
                                    name="status"
                                    checked={account.status ? true : false}
                                    id={"customSwitch" + index + 1}
                                    value={account.id}
                                    data-index={account.status}
                                    onChange={this.handleStatusChange}
                                  />
                                  <label for={"customSwitch" + index + 1}>
                                    &nbsp;
                                  </label>
                                </div>
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colspan="10">Record Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination justify-content-center mt-3"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      </div>
    );
  }
}
