import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";

export default class CityEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cityId: parseInt(props.match.params.cityId),
      stateList: {},
      errors: { city_name: "", state_id: "", status: "" },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      apiError: false,
      is_loader: true,
      redirectToList: false,
    };

    this.cityInput = React.createRef();
    this.stateInput = React.createRef();
    this.statusInput = React.createRef();

    this.handleCityNameChange = this.handleCityNameChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    let self = this;
    // get token
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "city/edit/" + this.state.cityId, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            console.log("result", result);
            if (result.status === 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 403) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {
                  console.log("aaaaaaaaaaaa");
                }
              );
            } else if (result.status === 1) {
              this.setState(
                {
                  city_name: result.data.city_name,
                  state_id: result.data.state_id,
                  status: result.data.status,
                  stateList: result.states,
                },
                () => {
                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 1000);
                  console.log("stateList2", typeof this.state.stateList);
                }
              );
            }
            setTimeout(function () {
              self.setState({ is_loader: false });
            }, 500);
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }
  handleCityNameChange(event) {
    this.setState({ city_name: event.target.value });
  }
  handleStateChange(event) {
    this.setState({ state_id: event.target.value });
  }
  handleStatusChange(event) {
    this.setState({ status: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    if (this.state.status === "") {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }
    if (!this.state.city_name.trim()) {
      formIsValid = false;
      errors["city_name"] = "The city name field is required.";
      this.cityInput.current.focus();
    }
    if (!this.state.state_id) {
      formIsValid = false;
      errors["state_id"] = "The Province field is required.";
      this.stateInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (token) {
        var settings = {
          url: Appconfig.api_base_url + "city/edit/" + this.state.cityId,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            city_name: this.state.city_name.trim(),
            state_id: this.state.state_id,
            status: this.state.status,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been updated successfully");
            self.setState({ redirectToList: true });
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/cities";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.cityInput.current.focus();
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/cities"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/cities" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">
              Edit City :{parseInt(this.state.cityId)}
            </h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        City Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="city_name"
                        maxlength="100"
                        placeholder="Enter city name"
                        value={this.state.city_name}
                        onChange={this.handleCityNameChange}
                        ref={this.cityInput}
                      />
                      <span className="error">
                        {this.state.errors["city_name"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Province<span className="text-red">*</span>
                      </label>

                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.state_id}
                        onChange={this.handleStateChange}
                        ref={this.stateInput}
                      >
                        <option value="">Select Province</option>
                        {typeof this.state.stateList[0] === "object"
                          ? this.state.stateList.map((state) => {
                              return (
                                <option value={state.id} key={state.id}>
                                  {state.state_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["state_id"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.status}
                        onChange={this.handleStatusChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>

                  <div className="pt-md-5 pt-4 text-center">
                    <Link
                      to="/cities"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
