import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";

export default class SettingEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: "",
      countryList: {},
      errors: { name: "", email: "", mobile: "", country_id: "" },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      is_loader: true,
      apiError: false,
      redirectToList: false,
    };

    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleMobileChange = this.handleMobileChange.bind(this);
    this.handleMethodChange = this.handleMethodChange.bind(this);
    this.handlePriorityChange = this.handlePriorityChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    let self = this;
    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "setting/update", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            console.log("result", result);
            if (result.status == 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {}
              );
            } else if (result.status === 403) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {}
              );
            } else if (result.status === 1) {
              this.setState(
                {
                  countryList: result.countries,
                  name: result.data.name,
                  email: result.data.email,
                  mobile: result.data.mobile,
                  country_id: result.data.default_country,
                  lead_assign_method: result.data.lead_assign_method,
                  lead_priority: result.data.lead_priority,
                },
                () => {
                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 300);
                }
              );
            }
            this.setState({ is_loader: false });
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleNameChange(event) {
    this.setState({ name: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handleMobileChange(event) {
    this.setState({ mobile: event.target.value });
  }
  handleMethodChange(event) {
    this.setState({ lead_assign_method: event.target.value });
  }
  handlePriorityChange(event) {
    this.setState({ lead_priority: event.target.value });
  }

  handleCountryChange(event) {
    this.setState({ country_id: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    //Form validation

    if (this.state.mobile) {
      var mobile = this.state.mobile;
      if (mobile.length == 10 && mobile > 5000000000) {
        errors["mobile"] = "";
      } else {
        formIsValid = false;
        errors["mobile"] = "Please enter valid 10 digit mobile.";
      }
    }

    if (this.state.email) {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        formIsValid = false;
        errors["email"] = "Please enter valid email address.";
      }
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (token) {
        var settings = {
          url: Appconfig.api_base_url + "setting/update/",
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            name: this.state.name,
            email: this.state.email,
            mobile: this.state.mobile,
            default_country: this.state.country_id,
            lead_assign_method: this.state.lead_assign_method,
            lead_priority: this.state.lead_priority,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          //localStorage.setItem("lmsuser", JSON.stringify(response.token));
          console.log("response", response);
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been updated successfully");
            self.setState({ redirectToList: true });
          } else if (response.status === 0) {
            var errorsObj = response.error;
            var errorStateObj = self.state.errors;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/settings";
          }
        }); // end ajax
      } else {
        window.location.href = Appconfig.site_url;
      }
    } else {
    }

    event.preventDefault();
  }

  componentDidMount() {
    let self = this;
    $(document).ready(function () {
      $(".selectpicker").selectpicker("refresh");
      if (self.state.lead_assign_method) {
        $(".selectpicker").selectpicker();
      }
    });
  }
  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/settings"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-5 d-flex align-items-center">
            <Link to="/settings" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Edit Setting</h1>
          </header>

          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}

          <div className="card">
            <header className="card-header pt-3 pb-3 bg-white">
              <div className="recourd-number bold">Basic Setting</div>
            </header>
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Name<span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Enter your name"
                        value={this.state.name}
                        onChange={this.handleNameChange}
                        ref={this.nameInput}
                      />
                      <span className="error">{this.state.errors["name"]}</span>
                    </div>
                    <div className="col-md-4 form-group">
                      <label for="">
                        Email Id<span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Enter your email id"
                        value={this.state.email}
                        onChange={this.handleEmailChange}
                        ref={this.emailInput}
                      />
                      <span className="error">
                        {this.state.errors["email"]}
                      </span>
                    </div>
                    <div className="col-md-4 form-group">
                      <label for="">
                        Contact Number <span className="text-red"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="mobile"
                        maxlength="10"
                        placeholder="Enter your Contact Number"
                        value={this.state.mobile}
                        onChange={this.handleMobileChange}
                      />
                      <span className="error">
                        {this.state.errors["mobile"]}
                      </span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-4 form-group">
                      <label for="">
                        Lead Assign Method<span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control selectpicker"
                        data-size="7"
                        name="gender"
                        value={
                          this.state.lead_assign_method
                            ? this.state.lead_assign_method
                            : ""
                        }
                        onChange={this.handleMethodChange}
                      >
                        <option value="">Select Agent Assign Method</option>
                        <option value="1">Round Robin </option>
                        <option value="2">Priority</option>
                      </select>
                      <span className="error">
                        {this.state.errors["lead_assign_method"]}
                      </span>
                    </div>
                    <div className="col-md-4 form-group">
                      <label for="">
                        Lead Priority<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lead_priority"
                        placeholder="Enter Priority"
                        value={this.state.lead_priority}
                        onChange={this.handlePriorityChange}
                      />
                      <span className="error">
                        {this.state.errors["lead_priority"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Country<span className="text-red">*</span>
                      </label>

                      <select
                        className="form-control selectpicker"
                        data-live-search="true"
                        data-size="7"
                        name="country_id"
                        value={this.state.country_id}
                        onChange={this.handleCountryChange}
                        ref={this.countryInput}
                      >
                        <option value="">Select Country</option>
                        {typeof this.state.countryList[0] === "object"
                          ? this.state.countryList.map((country) => {
                              return (
                                <option value={country.id}>
                                  {country.country_name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                      <span className="error">
                        {this.state.errors["country_id"]}
                      </span>
                    </div>
                  </div>

                  <div className="p-5 text-center">
                    <Link
                      to="/settings"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
