import React from "react";
import { Link } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import * as Appconfig from "../../helpers/config";
import $, { data } from "jquery";
class Music extends React.Component {
  constructor(props) {
    super(props);
    console.log("props", props);
    this.state = {
      audioUrl: null,
      contactId: props.contactId,
      leadId: props.leadId,
    };
    // this.handleDownloadAudio = this.handleDownloadAudio.bind(this);
    // this.handleDownloadAudio();
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ audioUrl: this.props.recording });
    }, 500);
  }

  handleDownloadAudio() {
    var errors = {};
    var formIsValid = true;
    this.setState({ errors: errors });
    console.log("handleDownloadAudio");
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (this.props.recording) {
        var options = {
          method: "GET",
          url: this.props.recording + ".json",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${Appconfig.callrail_api_key}`,
          },
        };
        let self = this;
        $.ajax(options).done(function (response) {
          if (response.url) {
            self.setState({
              audioUrl: response.url,
            });
          }
        }); // end
      } else if (token) {
        var settings = {
          url:
            Appconfig.api_base_url +
            "lead/downloadAudiofile/" +
            this.state.leadId,
          method: "post",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            contactId: this.state.contactId,
            recording: this.props.recording,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          if (response.status === 1) {
            console.log("response");
            self.setState({
              audioUrl: Appconfig.api_base_url + response.filePath,
            });
          } else if (response.status === 0) {
            console.log("response");
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/leads";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
        window.location.href = Appconfig.site_url;
      }
    } else {
      console.log("state", this.state.inputFocus);

      $("input[" + this.state.inputFocus + "]").focus();
    }
  }

  render() {
    return (
      <>
        {/* {this.state.audioUrl ? ( */}
        {/* <AudioPlayer
          autoPlay
          src={this.state.audioUrl || "test"}
          onPlay={(e) => console.log("onPlay")}
          showJumpControls={false}
          autoPlayAfterSrcChange={false}
        /> */}

        <audio
          controls
          src={this.state.audioUrl || "test"}
          style={{ width: "100%" }}
        >
          <a href={this.state.audioUrl || "test"}>Download audio</a>
        </audio>
        {/* ) : (
          ""
        )} */}
      </>
    );
  }
}

export default Music;
