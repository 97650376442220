import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerformerListItem from "../../dashboard/PerformerListItem";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import * as Appconfig from "../../../helpers/config";
import $ from "jquery";
//http://jsfiddle.net/izothep/myork5sa/

import Highcharts from "./Highcharts";

class LeadStage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topPerformer: this.props.topPerformer,
      PerformerlistArray: "",
      stagePieChart: this.props.stagePieChart,
      apiError: false,
      error: null,
    };

    console.log("stagePieChart", this.props.stagePieChart);
  }

  componentDidMount() {
    // Create the chart
    let self = this;
    Highcharts.setOptions({
      lang: {
        drillUpText: "◁",
      },
    });

    if (self.state.stagePieChart.series) {
      console.log("stagePieChart", self.state.stagePieChart.series);
      Highcharts.chart("container_stage", {
        chart: {
          type: "pie",
          events: {
            drilldown: function (e) {
              var chart = this;
              this.setTitle({ text: "Lead Stage: " + e.point.name });
            },
            drillup: function (e) {
              this.setTitle({ text: "Lead Stage" });
            },
          },
        },
        title: {
          text: "Lead Stage",
          align: "left",
          style: {
            fontWeight: "bold",
            fontSize: "18px",
            fontFamily: "Poppins, sans-serif",
          },
        },
        subtitle: {
          text: "",
        },
        legend: {
          itemStyle: {
            fontWeight: "regular",
            fontFamily: "Poppins, sans-serif",
          },
          itemMarginBottom: 5,
        },
        credits: { enabled: false },
        exporting: { enabled: false },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: { enabled: false },
            showInLegend: true,
          },
        },

        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">{point.name}</span>: <br>{point.percentage:.1f} %<br>value: {point.y}',
          backgroundColor: "rgba(255,255,255,0.9)",
          borderRadius: 6,
          borderColor: "#dddddd",
          style: { fontWeight: "regular", fontFamily: "Poppins, sans-serif" },
        },
        series: [
          {
            name: "Lead Stage",
            colorByPoint: true,
            data: self.state.stagePieChart.series,
          },
        ],
        drilldown: {
          drillUpButton: {
            relativeTo: "spacingBox",
            position: {
              y: 0,
              x: -5,
            },

            theme: {
              fill: "#eeeeee",
              "stroke-width": 1,
              stroke: "#eeeeee",
              width: 20,
              height: 20,
              r: 20,
              style: {
                color: "black",
              },
              states: {
                hover: {
                  fill: "#eeeeee",
                },

                select: {
                  stroke: "#eeeeee",
                  fill: "#eeeeee",
                },
              },
            },
          },
          series: self.state.stagePieChart.drilldownSeries,
        },
      });
    }
  }

  render() {
    return (
      <div className="col-md-6 mb-2">
        <div className="card h-100 hover-shadow p-4">
          <div id="container_stage"></div>
        </div>
      </div>
    );
  }
}

export default LeadStage;
