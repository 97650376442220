import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import { customAlphabet } from "nanoid";

export default class SupplierAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplier_id: parseInt(props.match.params.SupplierId),
      categoryList: {},
      access_key: "",
      supplier_name: "",
      supplier_type: "",
      status: 0,
      send_lead_rejection_email: 0,
      send_lead_to_boss: 0,
      errors: { supplier_name: "", access_key: "", status: "" },
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      is_loader: props.match.params.SupplierId ? true : false,
      apiError: false,
      redirectToList: false,
    };

    this.supplierInput = React.createRef();
    this.access_keyInput = React.createRef();
    this.statusInput = React.createRef();

    this.handleSupplierNameChange = this.handleSupplierNameChange.bind(this);
    this.handleGenerateAccessKey = this.handleGenerateAccessKey.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    var self = this;
    if (this.state.supplier_id) {
      var token = Appconfig.getSessionToken();
      if (token) {
        fetch(
          Appconfig.api_base_url + "supplier/edit/" + this.state.supplier_id,
          {
            method: "GET",
            headers: { Authorization: "Bearer " + token },
          }
        )
          .then((res) => res.json())
          .then(
            async (result) => {
              console.log("result", result);
              if (result.status === 0) {
                this.setState(
                  { apiError: result.message, isLoaded: false },
                  () => {
                    console.log("aaaaaaaaaaaa");
                  }
                );
              } else if (result.status === 403) {
                this.setState(
                  { apiError: result.message, isLoaded: false },
                  () => {
                    console.log("aaaaaaaaaaaa");
                  }
                );
              } else if (result.status === 1) {
                this.setState({
                  supplier_name: result.data.supplier_name,
                  supplier_type: result.data.supplier_type,
                  access_key: result.data.access_key,
                  status: result.data.status,
                  send_lead_rejection_email:
                    result.data.send_lead_rejection_email || 0,
                  send_lead_to_boss: result.data.send_lead_to_boss || 0,
                });
              }
              setTimeout(function () {
                self.setState({ is_loader: false });
              }, 300);
            },
            (error) => {
              self.setState({
                isLoaded: false,
              });
            }
          );
      } else {
        window.location.href = Appconfig.site_url;
      }
    }
  }

  handleSupplierNameChange(event) {
    this.setState({ supplier_name: event.target.value });
  }
  handleSupplierTypeChange = (event) => {
    this.setState({ supplier_type: event.target.value });
  };
  handleGenerateAccessKey(e) {
    e.preventDefault();
    const nanoid = customAlphabet(
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
      35
    );
    const access_key = nanoid();
    this.setState({ access_key });
  }
  handleStatusChange = (event) => {
    this.setState({ status: this.state.status === 0 ? 1 : 0 });
  };

  handleRejectionEmailStatus = (event) => {
    this.setState({
      send_lead_rejection_email: !this.state.send_lead_rejection_email ? 1 : 0,
    });
  };
  handleSendLeadToBoss = (event) => {
    this.setState({
      send_lead_to_boss: !this.state.send_lead_to_boss ? 1 : 0,
    });
  };

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    if (!this.state.supplier_name?.trim()) {
      formIsValid = false;
      errors["supplier_name"] = "The supplier name field is required.";
      this.supplierInput.current.focus();
    }
    if (!this.state.access_key) {
      formIsValid = false;
      errors["access_key"] = "The access key field is required.";
      // this.access_keyInput.current.focus();
    }

    if (!this.state.status || this.state.status < 0) {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (token) {
        var settings = {
          url:
            Appconfig.api_base_url +
            (this.state.supplier_id
              ? "supplier/edit/" + this.state.supplier_id
              : "supplier/add"),
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            supplier_name: this.state.supplier_name.trim(),
            supplier_type: this.state.supplier_type,
            access_key: this.state.access_key,
            send_lead_rejection_email:
              this.state.send_lead_rejection_email || 0,
            send_lead_to_boss: this.state.send_lead_to_boss || 0,
            status: this.state.status,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          console.log("response", response);
          if (response.status === 1) {
            Appconfig.setFlashMessage(
              self.state.supplier_id
                ? "Record has been updated successfully"
                : "Record has been added successfully"
            );
            self.setState({ redirectToList: true });
          } else if (response.status === 0) {
            var errorsObj = response.error;

            Object.keys(errorsObj).map(function (key) {
              console.log("key", key);
              if (errorsObj.hasOwnProperty(key)) {
                console.log("key", key);
                console.log("error 2", errorsObj[key]);
                errors[key] = errorsObj[key].message;

                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/suppliers";
          }
        }); // end ajax
      } else {
        console.log("auth token has been expired");
      }
    } else {
      $("input[" + this.state.inputFocus + "]").focus();
    }

    event.preventDefault();
  }

  componentDidMount() {
    this.supplierInput.current.focus();
  }
  render() {
    console.log("@@@@@@@", this.state.status, typeof this.state.status);
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.redirectToList ? <Redirect to={"/suppliers"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/suppliers" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">
              {this.state.supplier_id ? "Edit" : "Add New"} Supplier
            </h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-5 form-group">
                      <label for="">
                        Supplier Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="supplier_name"
                        maxlength="100"
                        placeholder="Enter supplier name"
                        value={this.state.supplier_name}
                        onChange={this.handleSupplierNameChange}
                        ref={this.supplierInput}
                      />
                      <span className="error">
                        {this.state.errors["supplier_name"]}
                      </span>
                    </div>
                    <div className="col-md-5 form-group">
                      <label for="">
                        Supplier Type<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="supplier_type"
                        maxlength="100"
                        placeholder="Enter supplier Type"
                        value={this.state.supplier_type}
                        onChange={this.handleSupplierTypeChange}
                      />
                      <span className="error">
                        {this.state.errors["supplier_type"]}
                      </span>
                    </div>

                    <div className="col-md-5 form-group">
                      <label for="">
                        Access Key<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="access_key"
                        maxlength="100"
                        // placeholder="Enter supplier name"
                        value={this.state.access_key}
                        disabled
                        // onChange={this.handleSupplierNameChange}
                        // ref={this.supplierInput}
                      />
                      <button
                        className="btn btn-primary mt-3"
                        title="Update Profile Image"
                        key={"generate-access-key-btn"}
                        onClick={(e) => this.handleGenerateAccessKey(e)}
                      >
                        Generate Access Key
                      </button>
                      {/* <h5 className="m-0">
                        {this.state.access_key ? this.state.access_key : ""}
                      </h5> */}
                      {/* <select
                        className="form-control selectpicker"
                        data-size="7"
                        value={this.state.value}
                        onChange={this.handleGenerateAccessKey}
                        ref={this.access_keyInput}
                      >
                        <option value="">Select Province</option>
                        {typeof this.state.categoryList[0] === "object"
                          ? this.state.categoryList.map((row) => {
                              return (
                                <option value={row.id}>
                                  {row.lender_cat_name}
                                </option>
                              );
                            })
                          : ""}
                      </select> */}
                      <span className="error">
                        {this.state.errors["access_key"]}
                      </span>
                    </div>

                    <div className="col-md-2 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <div className="switch-btn btn-checkbox">
                        <input
                          type="checkbox"
                          name="status"
                          checked={this.state.status ? true : false}
                          id={"customSwitch" + 1}
                          value={this.state.status}
                          data-index={this.state.status}
                          onChange={this.handleStatusChange}
                          ref={this.statusInput}
                        />
                        <label for={"customSwitch" + 1}>&nbsp;</label>
                      </div>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                    <div className="col-md-2 form-group">
                      <label for="">
                        Lead Rejection Email <span className="text-red">*</span>
                      </label>
                      <div className="switch-btn btn-checkbox">
                        <input
                          type="checkbox"
                          name="send_lead_rejection_email"
                          checked={
                            this.state.send_lead_rejection_email ? true : false
                          }
                          id={"customSwitch" + 2}
                          value={this.state.send_lead_rejection_email}
                          data-index={this.state.send_lead_rejection_email}
                          onChange={this.handleRejectionEmailStatus}
                        />
                        <label for={"customSwitch" + 2}>&nbsp;</label>
                      </div>
                      <span className="error">
                        {this.state.errors["send_lead_rejection_email"]}
                      </span>
                    </div>
                    <div className="col-md-2 form-group">
                      <label for="">
                        Send Leads to Boss <span className="text-red">*</span>
                      </label>
                      <div className="switch-btn btn-checkbox">
                        <input
                          type="checkbox"
                          name="send_lead_to_boss"
                          checked={this.state.send_lead_to_boss ? true : false}
                          id={"customSwitch" + 3}
                          value={this.state.send_lead_to_boss}
                          data-index={this.state.send_lead_to_boss}
                          onChange={this.handleSendLeadToBoss}
                        />
                        <label for={"customSwitch" + 3}>&nbsp;</label>
                      </div>
                      <span className="error">
                        {this.state.errors["send_lead_to_boss"]}
                      </span>
                    </div>
                  </div>

                  <div className="pt-md-5 pt-4 text-center">
                    <Link
                      to="/suppliers"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
