import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../helpers/config";
import edit_icon from "../assets/img/edit-icon.svg";
import avtar_img from "../assets/img/avtar.jpg";
import ImageCropModel from "../components/imageCropModel";
import * as common_helper from "../helpers/lms_common";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      totalUsers: 0,
      isLoaded: true,
      token: "",
      user_id: 1,
      is_loader: true,
      refresh: false,
      flashMessage: Appconfig.getFlashMessage(),
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    var token = Appconfig.getSessionToken();
    if (token) {
      var token = token;
      fetch(Appconfig.api_base_url + "profile/view/sessionId", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.data) {
              this.setState({ user: result.data, is_loader: false });
              console.log("result:", result.data);

              setTimeout(() => {
                this.setState({ flashMessage: "" });
                localStorage.setItem("successMsg", "");
              }, 3000);
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  openModal(modalId) {
    this.setState({
      modalId,
      modalIsOpen: true,
    });
  }

  closeModal() {
    this.setState({ modalIsOpen: false }, () => {
      console.log("refresh", Appconfig.site_url);
      window.location.href = Appconfig.site_url + "/profile";
    });
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        {this.state.refresh ? <Redirect to={"/profile"} refresh="true" /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-md-5 mb-4 d-flex align-items-center">
            <h1 className="h3 mb-0">My Profile</h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          {this.state.flashMessage ? this.state.flashMessage : ""}
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <header className="card-header pt-4 pb-4 bg-white d-flex align-items-center">
                  <h4 className="m-0 bold">
                    {this.state.user.full_name ? this.state.user.full_name : ""}
                  </h4>
                  <Link
                    to={"/profile_edit"}
                    className="btn-icon btn-view ml-auto"
                    title="Update"
                  >
                    <img src={edit_icon} alt="Update" />
                  </Link>
                </header>

                <div className="card-body">
                  <div className="row">
                    <div className="col-avtar col-md-4 text-center">
                      <img
                        src={
                          this.state.user.profile_img
                            ? Appconfig.api_base_url +
                              "uploads/profile_images/" +
                              this.state.user.profile_img
                            : avtar_img
                        }
                        alt="avtar image"
                        className="rounded-circle m-auto d-block"
                      />
                      <button
                        className="btn btn-primary mt-3"
                        title="Update Profile Image"
                        key={this.state.user_id}
                        onClick={() => this.openModal(this.state.user_id)}
                      >
                        Update Profile Image
                      </button>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Name</label>
                          <h5 className="m-0">
                            {this.state.user.full_name
                              ? this.state.user.full_name
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Contact No</label>
                          <h5 className="m-0">
                            {this.state.user.contact_number
                              ? common_helper.formatPhoneNumber(
                                  this.state.user.contact_number
                                )
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Email</label>
                          <h5 className="m-0">
                            {this.state.user.email_id
                              ? this.state.user.email_id
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">DOB</label>
                          <h5 className="m-0">
                            {this.state.user.dob
                              ? this.state.user.dob
                                  .split("-")
                                  .reverse()
                                  .join("-")
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Gender</label>
                          <h5 className="m-0">
                            {this.state.user.gender
                              ? this.state.user.gender
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">User Role</label>
                          <h5 className="m-0">
                            {typeof this.state.user["user_role"] === "object"
                              ? this.state.user["user_role"].role_name
                              : ""}
                          </h5>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Country</label>
                          <h5 className="m-0">
                            {typeof this.state.user["country"] === "object"
                              ? this.state.user.country.country_name
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">State</label>
                          <h5 className="m-0">
                            {typeof this.state.user["state"] === "object"
                              ? this.state.user.state.state_name
                              : ""}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">City</label>
                          <h5 className="m-0">
                            {typeof this.state.user["city"] === "object"
                              ? this.state.user.city.city_name
                              : ""}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Status</label>
                          <h5 className="m-0">
                            {this.state.user.status === 1
                              ? "Active"
                              : "Inactive"}
                          </h5>
                        </div>
                        <div className="mb-4 col-md-4">
                          <label className="text-muted">Created date</label>
                          <h5 className="m-0">
                            {this.state.user.createdAt
                              ? common_helper.getDate(this.state.user.createdAt)
                              : ""}
                          </h5>
                        </div>

                        <div className="mb-4 col-md-4">
                          <label className="text-muted">last_login</label>
                          <h5 className="m-0">
                            {this.state.user.last_login
                              ? common_helper.getDate(
                                  this.state.user.last_login
                                )
                              : "Null"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ImageCropModel
          modalIsOpen={this.state.modalIsOpen}
          closeModal={this.closeModal}
          modalId={this.state.modalId}
        />
      </div>
    );
  }
}
