import React from "react";
import avtar_img from "../../assets/img/avtar.jpg";
import { Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";

const PerformerListItem = (props) => {
  return (
    <div className="d-flex align-items-center pt-2 pb-2">
      <div className="col pl-0 col-author">
        <div className="d-flex align-items-center">
          {/* <div className="author-img rounded-circle">
                            <img src={avtar_img} alt="avtar image" /> 
                          </div> 
                        */}
          <span className="author-title text-truncate">{props.name}</span>
        </div>
      </div>
      <div className="col col-progress d-flex align-items-center">
        <ProgressBar now={props.converted_leads} className="w-100" />
        <div className="pl-2">{props.converted_leads}%</div>
      </div>
      <div className="col col-number pr-0">{props.assigned_leads}</div>
    </div>
  );
};

export default PerformerListItem;
