import React from "react";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import moment from "moment";

export default class MailTemplateAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editId: parseInt(props.match.params.id),
      errors: {
        template_name: "",
        template_type: "",
        subject: "",
        body: "",
        status: "",
      },
      template_name: "",
      template_slug: "",
      template_type: "",
      subject: "",
      body: "",
      sms_template: "",
      status: "",
      formIsValid: true,
      inputFocus: true,
      isLoaded: true,
      apiError: false,
      is_loader: true,
      redirectToList: false,
    };

    this.nameInput = React.createRef();
    this.typeInput = React.createRef();
    this.subjectInput = React.createRef();
    this.bodyInput = React.createRef();
    this.statusInput = React.createRef();
    this.smsInput = React.createRef();

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    let self = this;

    var token = Appconfig.getSessionToken();
    if (token) {
      fetch(Appconfig.api_base_url + "mailtemplate/edit/" + this.state.editId, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 0) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {}
              );
            } else if (result.status === 403) {
              this.setState(
                { apiError: result.message, isLoaded: false },
                () => {}
              );
              window.location.href = Appconfig.site_url;
            } else if (result.status === 1) {
              this.setState(
                {
                  template_name: result.data.template_name,
                  template_slug: result.data.template_slug,
                  template_type: result.data.template_type,
                  subject: result.data.subject,
                  body: result.data.body,
                  sms_template: result.data.sms_template,
                  status: result.data.status,
                },
                () => {
                  setTimeout(function () {
                    $(".selectpicker").selectpicker("refresh");
                  }, 300);
                }
              );
            }
            setTimeout(function () {
              self.setState({ is_loader: false });
            }, 300);
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  handleInputChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    var errors = {};
    var formIsValid = true;

    if (this.state.status === "") {
      formIsValid = false;
      errors["status"] = "The status field is required.";
      this.statusInput.current.focus();
    }
    if (!this.state.body.trim()) {
      formIsValid = false;
      errors["body"] = "The Mail Body field is required.";
      this.bodyInput.current.focus();
    }
    if (!this.state.subject.trim()) {
      formIsValid = false;
      errors["subject"] = "The Mail Subject field is required.";
      this.subjectInput.current.focus();
    }
    if (!this.state.template_type.trim()) {
      formIsValid = false;
      errors["template_type"] = "The Template type field is required.";
      this.typeInput.current.focus();
    }
    if (!this.state.template_name.trim()) {
      formIsValid = false;
      errors["template_name"] = "The Template name field is required.";
      this.nameInput.current.focus();
    }

    this.setState({ errors: errors });
    if (formIsValid === true) {
      var token = Appconfig.getSessionToken();
      if (token) {
        var settings = {
          url:
            Appconfig.api_base_url + "mailtemplate/edit/" + this.state.editId,
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + token,
          },
          dataType: "JSON",
          data: {
            template_name: this.state.template_name.trim(),
            template_type: this.state.template_type,
            subject: this.state.subject.trim(),
            body: this.state.body,
            sms_template: this.state.sms_template,
            status: this.state.status,
          },
        };

        let self = this;
        $.ajax(settings).done(function (response) {
          if (response.status === 1) {
            Appconfig.setFlashMessage("Record has been added successfully");
            self.setState({ redirectToList: true });
          } else if (response.status === 0) {
            var errorsObj = response.error;
            Object.keys(errorsObj).map(function (key) {
              if (errorsObj.hasOwnProperty(key)) {
                errors[key] = errorsObj[key].message;
                self.setState({ errors }, () => {
                  console.log("state", self.state.errors);
                });
              }
            });
          } else if (response.status === 401) {
            window.location.href = Appconfig.site_url + "/mailtemplates";
          }
        });
      } else {
        console.log("auth token has been expired");
        window.location.href = Appconfig.site_url + "/mailtemplates";
      }
    }
    event.preventDefault();
  }

  componentDidMount() {
    this.nameInput.current.focus();
  }
  render() {
    return (
      <div class="container">
        {this.state.redirectToList ? <Redirect to={"/mailtemplates"} /> : ""}
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/mailtemplates" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Add New Mail Template</h1>
          </header>
          {this.state.apiError ? (
            <div className="alert alert-danger">
              <strong>Error :</strong> {this.state.apiError}
            </div>
          ) : (
            ""
          )}
          <div className="card">
            {this.state.isLoaded === true ? (
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <label for="">
                        Template Name<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="template_name"
                        maxlength="50"
                        placeholder="Enter Template Name"
                        value={this.state.template_name}
                        onChange={this.handleInputChange}
                        ref={this.nameInput}
                      />
                      <span className="error">
                        {this.state.errors["template_name"]}
                      </span>
                    </div>
                    <div className="col-md-6 form-group">
                      <label for="">
                        Template Slug<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        maxlength="50"
                        placeholder="Enter Template Slug"
                        disabled
                        value={this.state.template_slug}
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <label for="">
                        Template Type <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="template_type"
                        data-size="7"
                        value={this.state.template_type}
                        onChange={this.handleInputChange}
                        ref={this.typeInput}
                      >
                        <option value="">-Select-</option>
                        <option value="Customer">Customer</option>
                        <option value="Agent">Agent</option>
                      </select>
                      <span className="error">
                        {this.state.errors["template_type"]}
                      </span>
                    </div>
                    <div className="col-md-6 form-group">
                      <label for="">
                        Mail Subject<span className="text-red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        maxlength="200"
                        placeholder="Enter Mail Subject"
                        value={this.state.subject}
                        onChange={this.handleInputChange}
                        ref={this.subjectInput}
                      />
                      <span className="error">
                        {this.state.errors["subject"]}
                      </span>
                    </div>

                    <div className="col-md-6 form-group">
                      <label for="">
                        Mail Body<span className="text-red">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="body"
                        row="6"
                        placeholder="Enter Mail Body"
                        onChange={this.handleInputChange}
                        value={this.state.body}
                        ref={this.bodyInput}
                      >
                        {this.state.body}
                      </textarea>
                      <span className="error">{this.state.errors["body"]}</span>
                    </div>

                    <div className="col-md-6 form-group">
                      <label for="">
                        Sms Description<span className="text-red">*</span>
                      </label>
                      <textarea
                        className="form-control"
                        name="sms_template"
                        row="6"
                        placeholder="Enter Sms Description"
                        onChange={this.handleInputChange}
                        value={this.state.sms_template}
                        ref={this.smsInput}
                      >
                        {this.state.sms_template}
                      </textarea>
                      <span className="error">
                        {this.state.errors["sms_template"]}
                      </span>
                    </div>

                    <div className="col-md-4 form-group">
                      <label for="">
                        Status <span className="text-red">*</span>
                      </label>
                      <select
                        className="form-control"
                        name="status"
                        data-size="7"
                        value={this.state.status}
                        onChange={this.handleInputChange}
                        ref={this.statusInput}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                      <span className="error">
                        {this.state.errors["status"]}
                      </span>
                    </div>
                  </div>
                  <div className="pt-md-5 pt-4 text-center">
                    <Link
                      to="/mailtemplates"
                      className="btn btn-lg btn-outline-primary pl-4 pr-4 mr-2"
                    >
                      Cancel
                    </Link>
                    <button
                      className="btn btn-lg btn-primary pl-4 pr-4"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
