import React, { useEffect, useRef } from "react";

export const HeaderDropdown = ({ children, path, title }) => {
  const mutationObserver = new MutationObserver(callback);

  const ref = useRef();
  useEffect(() => {
    mutationObserver.observe(ref.current, { attributes: true });
  }, []);
  function callback(mutationsList) {
    mutationsList.forEach((mutation) => {
      if (mutation.attributeName === "class") {
        if (
          window.innerWidth < 1200 &&
          mutation.target.className.indexOf("show") > -1
        ) {
          mutation.target.style.height = 57 + children.length * 36 + "px";
        } else {
          mutation.target.style.height = "auto";
        }
      }
    });
  }

  return (
    <li className="nav-item dropdown" key={"dashboards"} ref={ref}>
      <a
        // href="javascript:void(0);"
        className={
          path
            ? "nav-link  dropdown-toggle active"
            : "nav-link  dropdown-toggle"
        }
        data-toggle="dropdown"
      >
        {title}
      </a>

      <div
        className="dropdown-menu dropdown-menu-right"
        aria-labelledby="navbarDropdown"
      >
        {children}
      </div>
    </li>
  );
};
