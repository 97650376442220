import React from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import view_icon from "../../assets/img/view-icon.svg";
import * as common_helper from "../../helpers/lms_common";
import * as checkBtnPermission from "../../helpers/checkBtnPermission";
export default class UserView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: "",
      totalCustomer: 0,
      isLoaded: true,
      token: "",
      is_loader: true,
    };

    var customerId = props.match.params.id;
    customerId = parseInt(customerId);
    var token = Appconfig.getSessionToken();
    if (token && customerId > 0) {
      var token = token;
      fetch(Appconfig.api_base_url + "customer_account/view/" + customerId, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({ customer: result.data, is_loader: false });
              console.log("result:", result.data);
            } else {
              this.setState({ error: result.message });
            }
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );

      //console.log('roles:',this.state.roles);
    } else {
      //window.location.href=Appconfig.site_url;
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/accounts" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Account Detail</h1>
          </header>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <header className="card-header py-3 bg-white">
                  <h4 className="m-0 bold">Detail</h4>
                </header>
                <div className="card-body">
                  <div className="row">
                    <div className="mb-4 col-md-3">
                      <label className="text-muted">Name</label>
                      <h5 className="m-0">
                        {this.state.customer.first_name
                          ? this.state.customer.first_name
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-3">
                      <label className="text-muted">Contact No</label>
                      <h5 className="m-0">
                        {this.state.customer.contact_number
                          ? common_helper.formatPhoneNumber(
                              this.state.customer.contact_number
                            )
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-3">
                      <label className="text-muted">Email</label>
                      <h5 className="m-0">
                        {this.state.customer.email_id
                          ? this.state.customer.email_id
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-3">
                      <label className="text-muted">Country</label>
                      <h5 className="m-0">
                        {typeof this.state.customer["country"] === "object"
                          ? this.state.customer.country.country_name
                          : ""}
                      </h5>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-4 col-md-3">
                      <label className="text-muted">State</label>
                      <h5 className="m-0">
                        {this.state.customer["state"] != null
                          ? this.state.customer.state.state_name
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-3">
                      <label className="text-muted">City</label>
                      <h5 className="m-0">
                        {this.state.customer["city"] != null
                          ? this.state.customer.city.city_name
                          : ""}
                      </h5>
                    </div>

                    <div className="mb-4 col-md-3">
                      <label className="text-muted">Status</label>
                      <h5 className="m-0">
                        {this.state.customer.status === 1
                          ? "Active"
                          : "Inactive"}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-3">
                      <label className="text-muted">Created date</label>
                      <h5 className="m-0">
                        {this.state.customer.createdAt
                          ? common_helper.getDate(this.state.customer.createdAt)
                          : ""}
                      </h5>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>

          {typeof this.state.customer["leads"] === "object" ? (
            <div className="card">
              <header className="card-header py-3 bg-white">
                <h4 className="m-0 bold">Leads</h4>
              </header>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped table-hover dashboard-table v-middle">
                    <thead className="bg-white">
                      <tr>
                        <th>Lead No.</th>
                        <th>Lead Stage</th>
                        <th>Lead Status</th>
                        <th>Product Name</th>
                        <th>Sub Product Name</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Contact No.</th>
                        <th>City</th>
                        <th>Loan Amount</th>
                        <th width="50">Source of lead</th>
                        <th>Created on</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {typeof this.state.customer["leads"] === "object"
                        ? this.state.customer.leads.map((lead, index) => {
                            return (
                              <tr>
                                <td className="nowrap">
                                  {parseInt(lead.id) + 1000}
                                </td>
                                <td className="nowrap">
                                  {lead.lead_process_stage != null
                                    ? lead.lead_process_stage
                                        .lead_process_stage_name
                                    : ""}
                                </td>
                                <td className="nowrap">
                                  {lead.lead_process_status != null
                                    ? lead.lead_process_status
                                        .lead_process_status_name
                                    : ""}
                                </td>

                                <td className="nowrap">
                                  {lead.pg != null ? lead.pg.product_name : ""}
                                </td>
                                <td className="nowrap">
                                  {lead.spg != null
                                    ? lead.spg.product_name
                                    : ""}
                                </td>
                                <td className="nowrap">
                                  {lead.first_name + " " + lead.last_name}
                                </td>
                                <td className="nowrap">{lead.email_id}</td>
                                <td className="nowrap">
                                  {lead.contact_number
                                    ? common_helper.formatPhoneNumber(
                                        lead.contact_number
                                      )
                                    : ""}
                                </td>
                                <td className="nowrap">
                                  {lead.city ? lead.city.city_name : ""}
                                </td>
                                <td className="nowrap">
                                  {parseInt(lead.loan_amount)
                                    ? lead.loan_amount
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                    : ""}
                                </td>
                                <td className="nowrap">
                                  {lead.source_of_lead
                                    ? lead.source_of_lead
                                    : ""}
                                </td>

                                <td className="nowrap">
                                  {common_helper.getDate(lead.createdAt)}
                                </td>
                                <td className="nowrap">
                                  {checkBtnPermission.getButton(
                                    "view",
                                    "/lead/view/" + lead.id,
                                    view_icon,
                                    this.props,
                                    Link,
                                    this
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
