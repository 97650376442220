import React, { useLayoutEffect } from "react";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

//chart type
import * as am5percent from "@amcharts/amcharts5/percent";
import CardWrapper from "./CardWrapper";
import { setIndianNumberFormat, setSeriesColors } from "./utility";

function Donut(props) {
  //const chart = useRef(null);
  const {
    chartID,
    primaryValueField,
    labelField,
    secondaryValueField,
    title,
    data,
  } = props;

  useLayoutEffect(() => {
    //var root = am5.Root.new("chartdiv2");
    var root = am5.Root.new(chartID);
    if (root) {
      root._logo.dispose();
    }
    setIndianNumberFormat(root);
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    var chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(90),
        innerRadius: am5.percent(40),
        layout: root.horizontalLayout,
      })
    );
    // chart.current
    //   .get("colors")
    //   .set("colors", [am5.color("#0000FF"), am5.color("#FF0000")]);
    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: title,
        valueField: primaryValueField,
        categoryField: labelField,
        tooltip: am5.Tooltip.new(root, {
          labelText:
            "[bold]{NAME}[/]: ({valuePercentTotal.formatNumber('#.00')}%) \n[fontSize: 13px]Total: {COUNT}[/] ",
          layout: root.horizontalLayout,
          oversizedBehavior: "wrap",
          // maxWidth: 150,
          textAlign: "center",
        }),
      })
    );

    series.slices.template.setAll({
      templateField: "sliceSettings",
      strokeOpacity: 0,
    });

    setSeriesColors(series, am5);
    series.states.create("hidden", {
      endAngle: -90,
    });

    //dataset

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(data || []);
    // Disabling labels and ticks
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);
    series.ticks.template.set("forceHidden", true);
    series.labels.template.set("forceHidden", true);
    var sliceTemplate0 = series.slices.template;
    // Adding gradients
    // sliceTemplate0.setAll({
    //   draggable: true,
    //   templateField: "settings",
    //   cornerRadius: 5,
    // });

    series.labels.template.events.on("active", function (active, slice) {
      console.log("@@@@@@@de nindsa", active);
    });

    // series.slicesContainer.template.events.on(
    //   "click",
    //   function (active, slice) {
    //     console.log("@@@@@@@de active", active);
    //   }
    // );
    // series.slicesContainer.template.events.on(
    //   "focus",
    //   function (active, slice) {
    //     console.log("@@@@@@@de focus", active);
    //   }
    // );
    sliceTemplate0.events.on("datavalidated", function (e) {
      console.log("@@@@@@@de up", e);

      // if (previousDownSlice) {
      //   //  previousDownSlice.set("layer", 0);
      // }
      // e.target.set("layer", 1);
      // previousDownSlice = e.target;
    });

    series.slices.template.set("strokeOpacity", 0);
    series.slices.template.set(
      "fillGradient",
      am5.RadialGradient.new(root, {
        stops: [
          {
            brighten: -0.8,
          },
          {
            brighten: -0.8,
          },
          {
            brighten: -0.5,
          },
          {
            brighten: 0,
          },
          {
            brighten: -0.5,
          },
        ],
      })
    );
    series.slices.template.events.on("click", function (e) {
      // handleHover(e.target.dataItem);
      console.log("@@@@@@@de", e);
    });
    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.percent(50),
        y: am5.percent(50),
        // marginTop: 15,
        // marginBottom: 15,
        // layout: root.verticalLayout,
        width: 120,
        wheelable: true,

        // scale: 0.9,
      })
    );
    legend.labels.template.setAll({
      fontSize: 10,
      fontWeight: "300",
      maxWidth: 90,

      oversizedBehavior: "truncate",
    });

    legend.valueLabels.template.setAll({
      fontSize: 12,
      fontWeight: "400",
      visible: false,
      forceHidden: true,
    });
    legend.data.setAll(series.dataItems);
    series.appear(1000, 100);
    return () => {
      root.dispose();
    };
  }, [chartID]);

  const width = "49%";
  return (
    <CardWrapper
      // width={width}
      title={props.title}
      titleAlign={"center"}
      height={"350px"}
      className={"donut-width"}
    >
      <div
        // className={"col-md-12"}
        id={chartID}
        style={{
          width: "100%",
          height: 300,
        }}
      ></div>
    </CardWrapper>
  );
}

export default Donut;
