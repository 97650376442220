import React from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import $ from "jquery";
import * as Appconfig from "../../helpers/config";
import * as common_helper from "../../helpers/lms_common";

import view_icon from "../../assets/img/view-icon.svg";
import delete_icon from "../../assets/img/delete-icon.svg";
import pdf_icon from "../../assets/img/file-pdf.png";
import file_icon from "../../assets/img/file-icon.png";

import axios from "axios";

export default class CampaignView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      campaign: [],
      campaignId: props.match.params.id,
      apiError: "",
      isLoaded: true,
      is_loader: true,
    };

    this.receivedData();
  }

  receivedData() {
    var token = Appconfig.getSessionToken();

    var campaignId = parseInt(this.state.campaignId);
    if (token && campaignId > 0) {
      var token = token;
      fetch(Appconfig.api_base_url + "campaign/view/" + campaignId, {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
      })
        .then((res) => res.json())
        .then(
          async (result) => {
            if (result.status === 1) {
              this.setState({
                campaign: result.data,
              });

              console.log("result:", result.data);
            } else if (result.status === 0) {
              this.setState({ apiError: result.message, isLoaded: false });
            } else if (result.status === 401) {
              this.setState({ apiError: result.message, isLoaded: false });
              window.location.href = Appconfig.site_url;
            }
            this.setState({ is_loader: false });
          },
          (error) => {
            this.setState({
              isLoaded: false,
            });
          }
        );
      //console.log('roles:',this.state.roles);
    } else {
      window.location.href = Appconfig.site_url;
    }
  }

  componentDidMount() {}

  render() {
    return (
      <div
        className={this.state.is_loader ? "container is-loader" : "container"}
      >
        <div className="loader">&nbsp;</div>
        <div className="dashboard-content">
          <header className="head-title mb-3 d-flex align-items-center">
            <Link to="/campaigns" className="btn-back">
              <i className="fa fa-caret-left"></i>
            </Link>
            <h1 className="h3 mb-0">Campaign Detail</h1>
          </header>
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-4">
                <header className="card-header pt-4 pb-4 bg-white">
                  <h4 className="m-0 bold">Detail</h4>
                </header>
                <div className="card-body">
                  <div className="row">
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Campaign Name</label>
                      <h5 className="m-0">
                        {this.state.campaign.campaign_name
                          ? this.state.campaign.campaign_name
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Campaign Slug</label>
                      <h5 className="m-0">
                        {this.state.campaign.campaign_slug
                          ? this.state.campaign.campaign_slug
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Budget</label>
                      <h5 className="m-0">
                        {this.state.campaign.budget
                          ? this.state.campaign.budget
                          : ""}
                      </h5>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Expected Lead</label>
                      <h5 className="m-0">
                        {this.state.campaign.expected_lead
                          ? this.state.campaign.expected_lead
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Start Date</label>
                      <h5 className="m-0">
                        {this.state.campaign.campaign_start_date
                          ? this.state.campaign.campaign_start_date
                          : ""}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">End date</label>
                      <h5 className="m-0">
                        {this.state.campaign.campaign_end_date
                          ? this.state.campaign.campaign_end_date
                          : ""}
                      </h5>
                    </div>

                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Supplier</label>
                      <h5 className="m-0">
                        {this.state.campaign?.supplier?.supplier_name}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Status</label>
                      <h5 className="m-0">
                        {Appconfig.statusKeyList[this.state.campaign.status]}
                      </h5>
                    </div>
                    <div className="mb-4 col-md-4">
                      <label className="text-muted">Created date</label>
                      <h5 className="m-0">
                        {this.state.campaign.createdAt
                          ? this.state.campaign.createdAt
                          : ""}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {typeof this.state.campaign["campaign_agents"] === "object" ? (
            <div className="card">
              <header className="card-header py-3 bg-white">
                <h4 className="m-0 bold">Agent Percentage</h4>
              </header>
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-striped table-hover dashboard-table v-middle">
                    <thead className="bg-white">
                      <tr>
                        <th>Agent Name</th>
                        <th>Lead Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {typeof this.state.campaign["campaign_agents"] ===
                      "object"
                        ? this.state.campaign.campaign_agents.map(
                            (camp_agent, index) => {
                              return (
                                <tr>
                                  <td>
                                    {camp_agent.user != null
                                      ? camp_agent.user.full_name
                                      : ""}
                                  </td>
                                  <td>{camp_agent.lead_percentage + " %"}</td>
                                </tr>
                              );
                            }
                          )
                        : ""}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
