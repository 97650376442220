import React, { Component } from "react";
import { Link } from "react-router-dom";
import PerformerListItem from "../../dashboard/PerformerListItem";
import { Pie } from "react-chartjs-2";
import axios from "axios";
import * as Appconfig from "../../../helpers/config";
import $ from "jquery";
//http://jsfiddle.net/izothep/myork5sa/

import Highcharts from "./Highcharts";
import { TimeScale } from "chart.js";

class ProvinceWiseLeadStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productWiseStatusBar: this.props.productWiseStatusBar,
      apiError: false,
      error: null,
    };
  }

  componentDidMount() {
    // Create the chart
    Highcharts.setOptions({
      lang: {
        drillUpText: "◁",
      },
    });
    var drillupVal = 0;
    if (this.state.productWiseStatusBar) {
      console.log("drilldowns 1", this.state.productWiseStatusBar.drilldowns1);
      console.log("drilldowns 2", this.state.productWiseStatusBar.drilldowns2);
      console.log("drilldowns 3", this.state.productWiseStatusBar.drilldowns3);
      let self = this;
      Highcharts.chart("container_2", {
        chart: {
          type: "column",
          events: {
            drilldown: function (e) {
              if (!e.seriesOptions) {
                this.setTitle({
                  text: e.target.title.textStr + " : " + e.point.name,
                });
                var chart = this,
                  drilldowns = self.state.productWiseStatusBar.drilldowns1,
                  drilldowns2 = self.state.productWiseStatusBar.drilldowns2,
                  drilldowns3 = self.state.productWiseStatusBar.drilldowns3,
                  series = drilldowns[e.point.name],
                  series2 = drilldowns2[e.point.name],
                  series3 = drilldowns3[e.point.name];
                chart.addSingleSeriesAsDrilldown(e.point, series);
                chart.addSingleSeriesAsDrilldown(e.point, series2);
                chart.addSingleSeriesAsDrilldown(e.point, series3);
                chart.applyDrilldown();
              }
            },
            drillup: function (e) {
              if (drillupVal === 0) {
                drillupVal = 1;
                console.log("eventText", e.target);
                var titleArr = e.target.title.textStr.split(":");
                titleArr.splice(-1, 1);
                var newtitle = titleArr.join(":");
                console.log("eventText2", newtitle);
                this.setTitle({ text: newtitle });
              }
              setTimeout(function () {
                drillupVal = 0;
                console.log("tests", "k");
              }, 300);
            },
          },
        },
        title: {
          text: "Product wise Lead Status",
          align: "left",
          margin: 30,
          style: {
            fontWeight: "bold",
            fontSize: "18px",
            fontFamily: "Poppins, sans-serif",
          },
        },
        xAxis: {
          type: "category",
          labels: {
            style: {
              color: "#333333",
              fontFamily: "Poppins, sans-serif",
            },
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          gridLineDashStyle: "longdash",
          labels: {
            style: {
              color: "#5774aa",
              fontFamily: "Poppins, sans-serif",
            },
          },
        },
        legend: {
          enabled: true,
          itemStyle: {
            fontFamily: "Poppins, sans-serif",
            fontWeight: "regular",
          },
        },
        credits: { enabled: false },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name} : </td>' +
            '<td style="padding:0"> <b>{point.y}</b></td></tr>',
          footerFormat:
            "<tr><th>Total : </th>" +
            '<td style="text-align:right"><b>{point.total}</b></td></tr>' +
            "</tbody></table>",
          shared: true,
          useHTML: true,
          backgroundColor: "rgba(255,255,255,0.9)",
          borderRadius: 6,
          borderColor: "#dddddd",
          style: { fontWeight: "regular", fontFamily: "Poppins, sans-serif" },
        },

        plotOptions: {
          column: { stacking: "normal" },
          series: {
            borderWidth: 0,
            borderRadius: 2,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: "regular",
                fontFamily: "Poppins, sans-serif",
              },
            },
          },
        },

        series: self.state.productWiseStatusBar.series,

        drilldown: {
          drillUpButton: {
            relativeTo: "spacingBox",
            position: {
              y: 0,
              x: -5,
            },
            theme: {
              fill: "#eeeeee",
              "stroke-width": 1,
              stroke: "#eeeeee",
              width: 20,
              height: 20,
              r: 20,
              style: {
                color: "black",
              },
              states: {
                hover: {
                  fill: "#eeeeee",
                },

                select: {
                  stroke: "#eeeeee",
                  fill: "#eeeeee",
                },
              },
            },
          },
          series: [],
        },
      });
    }
  }
  render() {
    return (
      <div className="col-xl-6 col-md-6 mb-2">
        <div className="card h-100 hover-shadow p-4">
          {/*<h2 className="mb-4 h4">Product wise Lead Status</h2>*/}
          <div id="container_2"></div>
        </div>
      </div>
    );
  }
}
export default ProvinceWiseLeadStatus;
